import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BiPlusMedical, BiTrash } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import {
  getSourcesList,
  resetComponentStore,
  deleteSource,
} from "actions/sourceActions";

import SourceFilters from "./SourceFilters";

const SourcesList = ({
  sourceList: { data, count },
  getSourcesList,
  loadingSourceList,
  resetComponentStore,
  sortingParams,
  deleteSource,
}) => {
  const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const actions = (
    <div className="page-actions">
      <Link to="/admin/sources/create" title="create">
        <Button color="primary" size="sm">
          <BiPlusMedical /> Create New Source
        </Button>
      </Link>
    </div>
  );

  const columns = [
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: false,
      width: "10%",
    },
    {
      name: "Credential",
      cell: (row) => row.credential,
      sortable: false,
      width: "15%",
    },
    {
      name: "Description",
      cell: (row) => row.description,
      sortable: false,
      width: "20%",
    },
    {
      name: "National",
      cell: (row) => (row.national === true ? "Yes" : "No"),
      sortable: false,
      width: "10%",
    },
    {
      name: "State",
      cell: (row) => row.state,
      sortable: false,
      width: "5%",
    },
    {
      name: "URL",
      cell: (row) => row.url,
      sortable: false,
      width: "10%",
    },
    {
      name: "Phone",
      cell: (row) => row.phone,
      sortable: false,
      width: "10%",
    },
    {
      name: "Actions",
      width: "calc(20% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Link to={`/admin/sources/${row._id}/edit`} title="View Source">
            <Button variant="primary" size="sm">
              <FaRegEye />
            </Button>
          </Link>

          <Button
            className="ml-1"
            size="sm"
            type="button"
            variant="danger"
            onClick={(e) => {
              if (
                window.confirm(`Are you sure you want to delete: ${row.code}?`)
              ) {
                deleteSource(row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getSourcesList(clientParams);
  }, [getSourcesList, clientParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              code: {
                value: searchText,
                type: "String",
              },
              credential: {
                value: searchText,
                type: "id",
              },
              description: {
                value: searchText,
                type: "String",
              },
              url: {
                value: searchText,
                type: "String",
              },
              phone: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClientParams(params);
  };

  const onFilterChange = (newParams) => {
    setClientParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Sources" crumbs={[{ name: "Sources" }]} />

      <Card>
        <Card.Body>
          {actions}

          <div className="table-filter-section">
            <Row>
              <Col md="6">
                <SourceFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingSourceList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

SourcesList.propTypes = {
  getSourcesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sourceList: state.source.sourceList,
  loadingSourceList: state.source.loadingSourceList,
  sortingParams: state.source.sortingParams,
});

export default connect(mapStateToProps, {
  getSourcesList,
  resetComponentStore,
  deleteSource,
})(SourcesList);
