import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import { Row, Col, Button } from "react-bootstrap";

import {
  initiateOneDriveToken,
  generateOneDriveToken,
  initiatePsvOrderOneDriveOutputProcessing,
} from "actions/initiateOneDriveTokenActions";

import { initiatePsvOrderSFTPOutputProcessing } from "actions/orderProcessingActions";

const CreateOneDriveToken = ({
  initiateOneDriveToken,
  generateOneDriveToken,
  initiatePsvOrderOneDriveOutputProcessing,
  initiatePsvOrderSFTPOutputProcessing,
}) => {
  const [authUrl, setAuthUrl] = React.useState("");

  const handleOneDriveTokenGenerate = async (e) => {
    e.preventDefault();
    const oneDriveAuthUrl = await initiateOneDriveToken();
    setAuthUrl(oneDriveAuthUrl);
  };

  React.useEffect(() => {
    if (authUrl && !window.location.search.includes("code=")) {
      window.location.href = authUrl;
    }
  }, [authUrl]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (!code) return;
    const sucess = generateOneDriveToken(code);
  }, [generateOneDriveToken]);

  const handleOneDriveOutputProcess = async (e) => {
    e.preventDefault();
    const response = await initiatePsvOrderOneDriveOutputProcessing();
  };

  const handleSFTPOutputProcess = async (e) => {
    e.preventDefault();
    const response = await initiatePsvOrderSFTPOutputProcessing();
  };

  return (
    <React.Fragment>
      <div>
        <Row className="d-flex">
          <Col>
            <div className="text-center mt-5 pt-5">
              <h4>
                Generate OneDrive Token & Processing PSV Order Output Through
                OneDrive
              </h4>
              <div className="text-center mt-3">
                {" "}
                <Button
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure you want to initiate OneDrive token?`
                      )
                    ) {
                      handleOneDriveTokenGenerate(e);
                    }
                  }}
                >
                  Initiate Token
                </Button>
              </div>

              <div className="text-center mt-3">
                {" "}
                <Button
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure you want to initiate process through OneDrive?`
                      )
                    ) {
                      handleOneDriveOutputProcess(e);
                    }
                  }}
                >
                  Initiate Process Through OneDrive
                </Button>
              </div>
            </div>

            <div className="text-center mt-5 pt-5">
              <h4>Initiate PSV Order Output Processing Through SFTP</h4>

              <div className="text-center mt-3">
                {" "}
                <Button
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure you want to initiate process through SFTP?`
                      )
                    ) {
                      handleSFTPOutputProcess(e);
                    }
                  }}
                >
                  Initiate Process Through SFTP
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

CreateOneDriveToken.propTypes = {
  initiateOneDriveToken: PropTypes.func.isRequired,
  generateOneDriveToken: PropTypes.func.isRequired,
  initiatePsvOrderOneDriveOutputProcessing: PropTypes.func.isRequired,
  initiatePsvOrderSFTPOutputProcessing: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {
  initiateOneDriveToken,
  generateOneDriveToken,
  initiatePsvOrderOneDriveOutputProcessing,
  initiatePsvOrderSFTPOutputProcessing,
})(CreateOneDriveToken);
