import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  schoolsList: {
    page: 1,
    data: [],
    count: 0,
  },
  statesList: [],
  currentSchool: null,
  loadingSchoolsList: true,
  loadingSchool: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const schoolSlice = createSlice({
  name: "schools",
  initialState: initialState,
  reducers: {
    loadingSchoolsList(state) {
      return {
        ...state,
        loadingSchoolsList: true,
      };
    },
    schoolSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingAppTokenList: false,
      };
    },
    schoolListUpdated(state, action) {
      return {
        ...state,
        schoolsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingSchoolsList: false,
      };
    },
    resetAppToken(state) {
      return {
        ...initialState,
      };
    },

    schoolError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingSchool: false,
        loadingSchoolsList: false,
      };
    },
    loadingOnSchoolSubmit(state) {
      return {
        ...state,
        loadingSchool: true,
      };
    },
    schoolCreated(state) {
      return {
        ...state,
        loadingSchool: false,
      };
    },
    schoolUpdated(state, action) {
      return {
        ...state,
        schoolsList: {
          ...state.schoolsList,
          data: state.schoolsList.data.map((school) =>
            school._id === action.payload._id ? action.payload : school
          ),
        },
      };
    },
    loadCurrentSchool(state, action) {
      return {
        ...state,
        currentSchool: action.payload,
        loadingSchool: false,
      };
    },
    schoolDeleted(state, action) {
      const currentCount = state.schoolsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.schoolsList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        schoolsList: {
          data: state.schoolsList.data.filter(
            (record) => record._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingSchoolsList: false,
      };
    },
    schoolStateList(state, action) {
      return {
        ...state,
        statesList: action.payload,
        loadingSchool: false,
      };
    },
  },
});

export const {
  loadingSchoolsList,
  schoolSearchParametersUpdate,
  schoolListUpdated,
  schoolError,
  schoolCreated,
  resetAppToken,
  loadingOnSchoolSubmit,
  loadCurrentSchool,
  schoolUpdated,
  schoolDeleted,
  schoolStateList,
} = schoolSlice.actions;
export default schoolSlice.reducer;
