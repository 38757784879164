import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { validateForm } from "utils/validation";

import Errors from "Notifications/Errors";

const EditProviderDeaLicenseModal = ({
  provider_id,
  modal,
  setModal,
  isDisabled,
  setDisabled,
  formData,
  setFormData,
  errorList,
  setErrors,
  currentProviderDeaLicense,
  loadProviderDeaLicenseFormData,
  getProviderDeaLicenseById,
  saveProviderDeaLicense,
  removeProviderDeaLicenseErrors,
}) => {
  const [submitting, setSubmitting] = React.useState(false);

  const {
    DEA_LicenseStatus,
    DEA_LicenseStatusDate,
    DEA_LicenseSchedules,
    DEA_Verified,
    DEA_DateVerified,
  } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }
    const isVerified = e.target.checked;

    switch (e.target.name) {
      case "DEA_Verified":
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          DEA_DateVerified: isVerified ? DEA_DateVerified : "",
        });
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeProviderDeaLicenseErrors();

    const validationRules = [];

    const addValidationRule = (param, msg) => {
      validationRules.push({ param, msg });
    };

    if (DEA_Verified)
      addValidationRule(
        "DEA_DateVerified",
        "The DEA Verified Date is required"
      );

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );
    setSubmitting(true);

    saveProviderDeaLicense(submitData, provider_id).then((res) => {
      if (!res.status) return;
      reset();
      getProviderDeaLicenseById(provider_id);
    });
  };

  const reset = () => {
    setModal(false);
    setDisabled(!isDisabled);
    setSubmitting(false);
    removeProviderDeaLicenseErrors();
    if (!currentProviderDeaLicense) return;
    loadProviderDeaLicenseFormData(currentProviderDeaLicense);
  };

  return (
    <Modal show={modal} onHide={reset}>
      <Modal.Header closeButton>
        <h4>DEA License</h4>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => onSubmit(e)}>
          <Form.Group className="form-group">
            <Form.Label htmlFor="DEA_LicenseStatus">
              DEA License Status
            </Form.Label>
            <Form.Control
              className={errorList.DEA_LicenseStatus ? "invalid" : ""}
              type="text"
              id="DEA_LicenseStatus"
              name="DEA_LicenseStatus"
              value={DEA_LicenseStatus}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />
            <Errors current_key="DEA_LicenseStatus" key="DEA_LicenseStatus" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="DEA_LicenseStatusDate">
              DEA Status Date
            </Form.Label>
            <Form.Control
              className={errorList.DEA_LicenseStatusDate ? "invalid" : ""}
              type="date"
              id="DEA_LicenseStatusDate"
              name="DEA_LicenseStatusDate"
              value={DEA_LicenseStatusDate}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />
            <Errors
              current_key="DEA_LicenseStatusDate"
              key="DEA_LicenseStatusDate"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="DEA_LicenseSchedules">
              DEA License Schedules
            </Form.Label>
            <Form.Control
              className={errorList.DEA_LicenseSchedules ? "invalid" : ""}
              type="text"
              id="DEA_LicenseSchedules"
              name="DEA_LicenseSchedules"
              value={DEA_LicenseSchedules}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />
            <Errors
              current_key="DEA_LicenseSchedules"
              key="DEA_LicenseSchedules"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="DEA_Verified">Verified</Form.Label>
            <Form.Check
              className={errorList.DEA_Verified ? "invalid" : ""}
              type="switch"
              id="DEA_Verified"
              name="DEA_Verified"
              checked={DEA_Verified}
              onChange={(e) => onChange(e)}
              label={DEA_Verified === true ? "Yes" : "No"}
              disabled={isDisabled}
            />
            <Errors current_key="DEA_Verified" key="DEA_Verified" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="DEA_DateVerified">Date Verified</Form.Label>
            <Form.Control
              className={errorList.DEA_DateVerified ? "invalid" : ""}
              type="date"
              id="DEA_DateVerified"
              name="DEA_DateVerified"
              value={DEA_DateVerified}
              onChange={(e) => onChange(e)}
              disabled={!DEA_Verified || isDisabled}
            />
            <Errors current_key="DEA_DateVerified" key="DEA_DateVerified" />
          </Form.Group>
          {isDisabled ? null : (
            <div className="float-end">
              <Button
                className="m-2"
                type="button"
                variant="primary"
                disabled={submitting}
                onClick={(e) => onSubmit(e)}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting}
              >
                Cancel
              </Button>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProviderDeaLicenseModal;
