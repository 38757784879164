import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import Errors from "Notifications/Errors";
import { validateForm } from "utils/validation";
import { useNavigate, useParams } from "react-router-dom";
import { isAdmin } from "utils/helper";
import Spinner from "views/Spinner";

import {
  createProviderSpecialty,
  getProviderSpecialtiesList,
  getProviderSpecialtyById,
  updateProviderSpecialty,
  removeProviderSpecialtyErrors,
  setErrors,
  resetProviderSpecialtyComponentStore,
  getSpecialtiesList,
  getSubSpecialtiesList,
} from "actions/providerSpecialtyActions";

const CreateProviderSpecialtyModal = ({
  modal,
  setModal,
  setErrors,
  removeProviderSpecialtyErrors,
  errorList,
  loadingProviderSpecialty,
  createProviderSpecialty,
  getProviderSpecialtiesList,
  currentProviderSpecialty,
  getProviderSpecialtyById,
  updateProviderSpecialty,
  specialtyID,
  setSpecialtyID,
  user,
  resetProviderSpecialtyComponentStore,
  getSpecialtiesList,
  getSubSpecialtiesList,
}) => {
  const navigate = useNavigate();
  const { provider_id } = useParams();

  const initialFormData = {
    specialty: "",
    sub_specialties: [],
    date_originally_issued: "",
    date_last_issued: "",
    date_expires: "",
    verified: false,
    date_verified: "",
  };

  const initialSortingParams = {
    limit: 10,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [clientParams, setClientParams] = React.useState(initialSortingParams);

  const [specialtiesListData, setSpecialtiesListData] = React.useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = React.useState(null);

  const [subSpecialtiesListData, setSubSpecialtiesListData] = React.useState(
    []
  );
  const [selectedSubSpecialties, setSelectedSubSpecialties] = React.useState(
    []
  );
  const [loadingSubSpecialties, setLoadingSubSpecialties] =
    React.useState(false);

  const [submitting, setSubmitting] = React.useState(false);

  const {
    specialty,
    sub_specialties,
    date_originally_issued,
    date_last_issued,
    date_expires,
    verified,
    date_verified,
  } = formData;

  const loadProviderSpecialtyFormData = (currentProviderSpecialty) => {
    const {
      specialty,
      sub_specialties,
      date_originally_issued,
      date_last_issued,
      date_expires,
      verified,
      date_verified,
    } = currentProviderSpecialty;

    const data = {
      specialty,
      sub_specialties,
      date_originally_issued,
      date_last_issued,
      date_expires,
      verified,
      date_verified: date_verified ? date_verified : "",
    };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!currentProviderSpecialty) return;
    if (!specialtyID) return;

    loadProviderSpecialtyFormData(currentProviderSpecialty);
  }, [currentProviderSpecialty, specialtyID]);

  React.useEffect(() => {
    if (!specialtyID) return;
    const specialtyData = getProviderSpecialtyById(provider_id, specialtyID);
  }, [specialtyID, getProviderSpecialtyById]);

  React.useMemo(async () => {
    const specialtiesListData = await getSpecialtiesList();

    setSpecialtiesListData(specialtiesListData);
  }, [getSpecialtiesList]);

  React.useEffect(() => {
    const fetchData = async () => {
      setLoadingSubSpecialties(true);
      const fetchedSubSpecialtiesListData = await getSubSpecialtiesList();

      const filteredSubSpecialtyArray = fetchedSubSpecialtiesListData.filter(
        (each) => each.specialty === specialty
      );

      setSubSpecialtiesListData([...filteredSubSpecialtyArray]);
      setLoadingSubSpecialties(false);
    };

    fetchData();
  }, [specialty, specialtyID]);

  React.useEffect(() => {
    if (!specialtyID || !specialty || !sub_specialties) return;

    const filteredSpecialty = specialtiesListData.find(
      (each) => each._id === specialty
    );
    const filteredSubSpecialties = subSpecialtiesListData.filter((each) =>
      sub_specialties.includes(each._id)
    );

    if (filteredSpecialty) {
      setSelectedSpecialty({
        value: filteredSpecialty._id,
        label: `${filteredSpecialty.name}`,
      });
    }

    const selectedSubSpecialtiesArray = filteredSubSpecialties.map(
      (sub_specialty) => ({
        value: sub_specialty._id,
        label: sub_specialty.name,
      })
    );

    setSelectedSubSpecialties(selectedSubSpecialtiesArray);
  }, [
    specialty,
    sub_specialties,
    specialtyID,
    specialtiesListData,
    subSpecialtiesListData,
  ]);

  const handleSpecialtySelect = (selectedRole) => {
    setSelectedSpecialty({
      value: selectedRole.value,
      label: selectedRole.label,
    });

    setFormData({
      ...formData,
      specialty: selectedRole ? selectedRole.value : null,
    });
    setSelectedSubSpecialties([]);
  };

  const handleSubSpecialtySelect = (selectedRoles) => {
    const selectedSubSpecialtiesArr = selectedRoles.map((role) => ({
      value: role.value,
      label: role.label,
    }));

    setSelectedSubSpecialties(selectedSubSpecialtiesArr);

    setFormData({
      ...formData,
      sub_specialties: selectedRoles
        ? selectedRoles.map((role) => role.value)
        : [],
    });
  };

  const onChange = (e) => {
    if (!e.target) {
      return;
    }
    switch (e.target.name) {
      case "verified":
        const isVerified = e.target.checked;
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          date_verified: isVerified ? date_verified : "",
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const reset = () => {
    setModal(false);
    setSpecialtyID(null);
    setFormData(initialFormData);
    removeProviderSpecialtyErrors();
    setSelectedSpecialty(null);
    setSelectedSubSpecialties(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeProviderSpecialtyErrors();

    let validationRules = [
      {
        param: "specialty",
        msg: "The Specialty is required",
      },
    ];

    if (verified) {
      validationRules.push({
        param: "date_verified",
        msg: "The Date Verified is required",
      });
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (formData[i] === null || formData[i] === undefined) continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    if (specialtyID) {
      updateProviderSpecialty(
        submitData,
        navigate,
        provider_id,
        specialtyID
      ).then((res) => {
        if (!res.status) return;
        reset();
        setSubmitting(false);
        resetProviderSpecialtyComponentStore();
        getProviderSpecialtiesList(clientParams, provider_id);
      });
    } else {
      setSubmitting(false);
      createProviderSpecialty(submitData, navigate, provider_id).then((res) => {
        if (!res.status) return;
        reset();
        resetProviderSpecialtyComponentStore();
        getProviderSpecialtiesList(clientParams, provider_id);
      });
    }
  };

  return (
    <Modal show={modal} onHide={reset}>
      <Modal.Header closeButton>
        {specialtyID ? <h4> Edit Specialty </h4> : <h4> Add Specialty </h4>}
      </Modal.Header>
      <Modal.Body>
        {loadingProviderSpecialty ? (
          <Spinner />
        ) : (
          <Form onSubmit={(e) => onSubmit(e)}>
            <Form.Group className="form-group">
              <Form.Label htmlFor="specialty">
                Specialty <span>*</span>
              </Form.Label>

              <Select
                id="specialty"
                name="specialty"
                options={specialtiesListData.map((e) => ({
                  value: e._id,
                  label: e.name,
                }))}
                value={selectedSpecialty}
                onChange={handleSpecialtySelect}
                invalid={errorList.specialty ? true : false}
              />

              <Errors current_key="specialty" key="specialty" />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label htmlFor="sub_specialties">Sub Specialty</Form.Label>

              <Select
                id="sub_specialties"
                name="sub_specialties"
                className="basic-multi-select"
                classNamePrefix="select"
                isMulti
                options={subSpecialtiesListData.map((e) => ({
                  value: e._id,
                  label: e.name,
                }))}
                value={selectedSubSpecialties}
                onChange={handleSubSpecialtySelect}
                invalid={errorList.sub_specialties ? true : false}
                isLoading={loadingSubSpecialties}
              />

              <Errors current_key="sub_specialties" key="sub_specialties" />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label htmlFor="date_originally_issued">
                Date Originally Issued
              </Form.Label>
              <Form.Control
                className={errorList.date_originally_issued ? "invalid" : ""}
                type="date"
                id="date_originally_issued"
                name="date_originally_issued"
                value={date_originally_issued}
                onChange={(e) => onChange(e)}
              />
              <Errors
                current_key="date_originally_issued"
                key="date_originally_issued"
              />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label htmlFor="date_last_issued">
                Date Last Issued
              </Form.Label>
              <Form.Control
                className={errorList.date_last_issued ? "invalid" : ""}
                type="date"
                id="date_last_issued"
                name="date_last_issued"
                value={date_last_issued}
                onChange={(e) => onChange(e)}
              />
              <Errors current_key="date_last_issued" key="date_last_issued" />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label htmlFor="date_expires">Date Expires</Form.Label>
              <Form.Control
                className={errorList.date_expires ? "invalid" : ""}
                type="date"
                id="date_expires"
                name="date_expires"
                value={date_expires}
                onChange={(e) => onChange(e)}
              />
              <Errors current_key="date_expires" key="date_expires" />
            </Form.Group>

            {isAdmin(user) ? (
              <>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="verified">Verified</Form.Label>
                  <Form.Check
                    className={errorList.verified ? "invalid" : ""}
                    type="switch"
                    id="verified"
                    name="verified"
                    checked={verified}
                    onChange={(e) => onChange(e)}
                    label={verified === true ? "Yes" : "No"}
                  />
                  <Errors current_key="verified" key="verified" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="date_verified">Date Verified</Form.Label>
                  <Form.Control
                    className={errorList.date_verified ? "invalid" : ""}
                    type="date"
                    id="date_verified"
                    name="date_verified"
                    value={date_verified}
                    onChange={(e) => onChange(e)}
                    disabled={!verified}
                  />
                  <Errors current_key="date_verified" key="date_verified" />
                </Form.Group>
              </>
            ) : null}

            <div className="float-end">
              <Button
                className="m-2"
                type="submit"
                variant="primary"
                disabled={submitting}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  loadingProviderSpecialty: state.providerSpecialty.loadingProviderSpecialty,
  currentProviderSpecialty: state.providerSpecialty.currentProviderSpecialty,
});

export default connect(mapStateToProps, {
  setErrors,
  removeProviderSpecialtyErrors,
  createProviderSpecialty,
  getProviderSpecialtiesList,
  getProviderSpecialtyById,
  updateProviderSpecialty,
  resetProviderSpecialtyComponentStore,
  getSpecialtiesList,
  getSubSpecialtiesList,
})(CreateProviderSpecialtyModal);
