import React from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Errors from "Notifications/Errors";
import { validateForm } from "utils/validation";
import { openBase64File } from "utils/downloadHelper";

const EditProviderMedicareModal = ({
  provider_id,
  modal,
  setModal,
  isDisabled,
  setDisabled,
  formData,
  setFormData,
  errorList,
  setErrors,
  currentProviderMedicare,
  loadProviderMedicareFormData,
  getProviderMedicareById,
  saveProviderMedicare,
  removeProviderMedicareErrors,
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [submittingPsv, setSubmittingPsv] = React.useState(false);
  const [isAdminVerified, setIsAdminVerified] = React.useState(false);

  const {
    Medicare_OptOut,
    Medicare_OptOutDate,
    Medicare_OptOutEndDate,
    Medicare_OptOutVerified,
    Medicare_OptOutDateVerified,
  } = formData;

  const saveFormData = (formData) => {
    // Save formData
    saveProviderMedicare(formData, provider_id).then((res) => {
      if (!res.status) return;
      reset();
      getProviderMedicareById(provider_id);
    });
  };

  const onChange = (e) => {
    if (!e.target) {
      return;
    }
    const isVerified = e.target.checked;

    switch (e.target.name) {
      case "Medicare_OptOut":
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          Medicare_OptOutDate: isVerified ? Medicare_OptOutDate : "",
          Medicare_OptOutEndDate: isVerified ? Medicare_OptOutEndDate : "",
          Medicare_OptOutVerified: isVerified ? Medicare_OptOutVerified : false,
          Medicare_OptOutDateVerified: isVerified
            ? Medicare_OptOutDateVerified
            : "",
        });
        break;

      case "Medicare_OptOutVerified":
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          Medicare_OptOutDateVerified: isVerified
            ? Medicare_OptOutDateVerified
            : "",
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeProviderMedicareErrors();

    const validationRules = [];

    const addValidationRule = (param, msg) => {
      validationRules.push({ param, msg });
    };

    if (Medicare_OptOut) {
      if (!Medicare_OptOutDate)
        addValidationRule(
          "Medicare_OptOutDate",
          "The Medicare Opt Out Date is required"
        );
      if (!Medicare_OptOutEndDate)
        addValidationRule(
          "Medicare_OptOutEndDate",
          "The Medicare Opt Out End Date is required"
        );
      if (Medicare_OptOutVerified)
        addValidationRule(
          "Medicare_OptOutDateVerified",
          "The Medicare Opt Out Verified Date is required"
        );
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );
    setSubmitting(true);
    saveFormData(submitData);
  };

  const reset = () => {
    setModal(false);
    setDisabled(!isDisabled);
    setSubmitting(false);
    setSubmittingPsv(false);
    removeProviderMedicareErrors();
    if (!currentProviderMedicare) return;
    loadProviderMedicareFormData(currentProviderMedicare);
  };

  React.useEffect(() => {
    if (!currentProviderMedicare) return;

    const admin_verify = currentProviderMedicare.admin_verify
      ? currentProviderMedicare.admin_verify
      : false;
    setIsAdminVerified(admin_verify);

    const { psv_result = {} } = currentProviderMedicare;
    if (!psv_result["full_result"]) {
      setPSV_Data(initialPSV_Data);
      return;
    }

    const psv_full_result = psv_result["full_result"]
      ? psv_result["full_result"]
      : {};
    const psv_report = psv_result["reports"] ? psv_result["reports"][0] : null;
    const psv_verified_date = psv_result["createdAt"]
      ? moment(psv_result["createdAt"]).format("MM/DD/YYYY hh:mm a")
      : null;

    let OptOut_psv = "";
    if (
      psv_full_result["OptOutFlag"] &&
      psv_full_result["OptOutFlag"] === "Y"
    ) {
      OptOut_psv = "Yes";
    }
    if (
      psv_full_result["OptOutFlag"] &&
      psv_full_result["OptOutFlag"] === "N"
    ) {
      OptOut_psv = "No";
    }

    const OptOutDate_psv = psv_full_result["OptOutDate"]
      ? psv_full_result["OptOutDate"]
      : "";

    const OptOutEndDate_psv = psv_full_result["OptOutEndDate"]
      ? psv_full_result["OptOutEndDate"]
      : "";

    const PSV_Data = {
      OptOut_psv: OptOut_psv,
      OptOutDate_psv: OptOutDate_psv
        ? moment(OptOutDate_psv).format("MM/DD/YYYY").toString()
        : "",
      OptOutEndDate_psv: OptOutEndDate_psv
        ? moment(OptOutEndDate_psv).format("MM/DD/YYYY").toString()
        : "",
    };

    if (psv_report) {
      PSV_Data["psv_report"] = psv_report;
    }

    if (psv_verified_date) {
      PSV_Data["psv_verified_date"] = psv_verified_date;
    }

    setPSV_Data((prev) => ({ ...prev, ...PSV_Data }));
  }, [currentProviderMedicare]);

  const initialPSV_Data = {
    OptOut_psv: "",
    OptOutDate_psv: "",
    OptOutEndDate_psv: "",
    psv_report: null,
    psv_verified_date: "",
  };

  const [PSV_Data, setPSV_Data] = React.useState(initialPSV_Data);
  const {
    OptOut_psv,
    OptOutDate_psv,
    OptOutEndDate_psv,
    psv_report,
    psv_verified_date,
  } = PSV_Data;

  const onDownloadClickHandler = () => {
    if (!psv_report) return;

    const base64 = psv_report["base64"];
    const file_title = psv_report["title"] ? psv_report["title"] : "Report.pdf";

    openBase64File(base64, file_title, "application/pdf");
  };

  const onAcceptPsvResults = (e) => {
    e.preventDefault();

    formData.Medicare_OptOut = OptOut_psv === "Yes" ? true : false;
    formData.Medicare_OptOutDate = moment(OptOutDate_psv).format("MM/DD/YYYY");
    formData.Medicare_OptOutEndDate =
      moment(OptOutEndDate_psv).format("MM/DD/YYYY");
    formData.Medicare_OptOutVerified = true;
    formData.Medicare_OptOutDateVerified = moment().format("MM/DD/YYYY");
    formData.admin_verify = true;

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );

    setSubmittingPsv(true);
    saveFormData(submitData);
  };

  const handleDateChange = (date, fieldName) => {
    if (date && moment(date).isValid()) {
      const formattedDate = moment(date).format("MM/DD/YYYY");
      setFormData({
        ...formData,
        [fieldName]: formattedDate,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: "",
      });
    }
  };

  return (
    <Modal show={modal} onHide={reset} size="lg">
      <Modal.Header closeButton>
        <h4>Opt Out of Medicare?</h4>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="Medicare_OptOut">
                  Opt Out of Medicare?
                </Form.Label>
                <Form.Check
                  className={errorList.Medicare_OptOut ? "invalid" : ""}
                  type="switch"
                  id="Medicare_OptOut"
                  name="Medicare_OptOut"
                  checked={Medicare_OptOut}
                  onChange={(e) => onChange(e)}
                  label={Medicare_OptOut === true ? "Yes" : "No"}
                  disabled={isDisabled}
                />
                <Errors current_key="Medicare_OptOut" key="Medicare_OptOut" />
              </Form.Group>
            </Col>

            {OptOut_psv && !isAdminVerified ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="OptOut_psv">Opt Out PSV</Form.Label>
                  <Form.Control
                    type="input"
                    id="OptOut_psv"
                    defaultValue={OptOut_psv}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="Medicare_OptOutDate">
                  Opt Out Date
                </Form.Label>

                <DatePicker
                  showIcon
                  selected={
                    Medicare_OptOutDate &&
                    moment(Medicare_OptOutDate, "MM/DD/YYYY").isValid()
                      ? moment(Medicare_OptOutDate, "MM/DD/YYYY").toDate()
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange(date, "Medicare_OptOutDate")
                  }
                  className={`form-control ${
                    formData.errorList?.date_verified ? "invalid" : ""
                  }`}
                  dateFormat="MM/dd/yyyy"
                  id="Medicare_OptOutDate"
                  name="Medicare_OptOutDate"
                  placeholderText="mm/dd/yyyy"
                  disabled={isDisabled}
                />

                <Errors
                  current_key="Medicare_OptOutDate"
                  key="Medicare_OptOutDate"
                />
              </Form.Group>
            </Col>

            {OptOutDate_psv && !isAdminVerified ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="OptOutDate_psv">
                    Opt Out Date PSV (mm/dd/yyyy)
                  </Form.Label>
                  <Form.Control
                    type="input"
                    id="OptOutDate_psv"
                    defaultValue={OptOutDate_psv}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="Medicare_OptOutEndDate">
                  Opt Out End Date
                </Form.Label>
                <DatePicker
                  showIcon
                  selected={
                    Medicare_OptOutEndDate &&
                    moment(Medicare_OptOutEndDate, "MM/DD/YYYY").isValid()
                      ? moment(Medicare_OptOutEndDate, "MM/DD/YYYY").toDate()
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange(date, "Medicare_OptOutEndDate")
                  }
                  className={`form-control ${
                    formData.errorList?.date_verified ? "invalid" : ""
                  }`}
                  dateFormat="MM/dd/yyyy"
                  id="Medicare_OptOutEndDate"
                  name="Medicare_OptOutEndDate"
                  placeholderText="mm/dd/yyyy"
                  disabled={isDisabled}
                />
                <Errors
                  current_key="Medicare_OptOutEndDate"
                  key="Medicare_OptOutEndDate"
                />
              </Form.Group>
            </Col>

            {OptOutEndDate_psv && !isAdminVerified ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="OptOutEndDate_psv">
                    Opt Out End Date PSV (mm/dd/yyyy)
                  </Form.Label>
                  <Form.Control
                    type="input"
                    id="OptOutEndDate_psv"
                    defaultValue={OptOutEndDate_psv}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="Medicare_OptOutVerified">
                  Verified
                </Form.Label>
                <Form.Check
                  className={errorList.Medicare_OptOutVerified ? "invalid" : ""}
                  type="switch"
                  id="Medicare_OptOutVerified"
                  name="Medicare_OptOutVerified"
                  checked={Medicare_OptOutVerified}
                  onChange={(e) => onChange(e)}
                  label={Medicare_OptOutVerified === true ? "Yes" : "No"}
                  disabled={isDisabled || !Medicare_OptOut}
                />
                <Errors
                  current_key="Medicare_OptOutVerified"
                  key="Medicare_OptOutVerified"
                />
              </Form.Group>
            </Col>

            {psv_report ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="psv_report">PSV Report</Form.Label>

                  <div>
                    <Button
                      type="button"
                      variant="link"
                      size="sm"
                      onClick={() => onDownloadClickHandler()}
                    >
                      <FaEye /> View
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="Medicare_OptOutDateVerified">
                  Date Verified
                </Form.Label>

                <DatePicker
                  showIcon
                  selected={
                    Medicare_OptOutDateVerified &&
                    moment(Medicare_OptOutDateVerified, "MM/DD/YYYY").isValid()
                      ? moment(
                          Medicare_OptOutDateVerified,
                          "MM/DD/YYYY"
                        ).toDate()
                      : null
                  }
                  onChange={(date) =>
                    handleDateChange(date, "Medicare_OptOutDateVerified")
                  }
                  className={`form-control ${
                    formData.errorList?.date_verified ? "invalid" : ""
                  }`}
                  dateFormat="MM/dd/yyyy"
                  id="Medicare_OptOutDateVerified"
                  name="Medicare_OptOutDateVerified"
                  placeholderText="mm/dd/yyyy"
                  disabled={
                    isDisabled || !Medicare_OptOutVerified || !Medicare_OptOut
                  }
                />

                <Errors
                  current_key="Medicare_OptOutDateVerified"
                  key="Medicare_OptOutDateVerified"
                />
              </Form.Group>
            </Col>
            {psv_verified_date ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="psv_verified_date">
                    Date Verified PSV
                  </Form.Label>
                  <Form.Control
                    type="input"
                    id="psv_verified_date"
                    defaultValue={psv_verified_date}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          {!isDisabled ? (
            <div className="float-end">
              {OptOut_psv && !isAdminVerified ? (
                <Button
                  className="m-2"
                  type="button"
                  variant="primary"
                  disabled={submitting || submittingPsv}
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure you want to accept Psv Results?`
                      )
                    ) {
                      onAcceptPsvResults(e);
                    }
                  }}
                >
                  {submittingPsv ? (
                    <>
                      <span className="spinner-border spinner-border-sm"></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Accept PSV Results</>
                  )}
                </Button>
              ) : null}
              <Button
                className="m-2"
                type="button"
                variant="primary"
                disabled={submitting || submittingPsv}
                onClick={(e) => onSubmit(e)}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting || submittingPsv}
              >
                Cancel
              </Button>
            </div>
          ) : null}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProviderMedicareModal;
