import React from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { isAdmin } from "utils/helper";
import { validateForm } from "utils/validation";
import { openBase64File } from "utils/downloadHelper";

import {
  createProviderCredential,
  getProviderCredentialList,
  getProviderCredentialById,
  updateProviderCredential,
  removeProviderCredentialErrors,
  setErrors,
  getCredentialsList,
  getStatesList,
  resetProviderCredentialComponentStore,
} from "actions/providerCredentialActions";

const CreateProviderCredentialModal = ({
  modal,
  setModal,
  setErrors,
  removeProviderCredentialErrors,
  getCredentialsList,
  errorList,
  loadingProviderCredential,
  createProviderCredential,
  getProviderCredentialList,
  getStatesList,
  currentProviderCredential,
  getProviderCredentialById,
  updateProviderCredential,
  credentialID,
  setCredentialID,
  user,
  resetProviderCredentialComponentStore,
}) => {
  const navigate = useNavigate();
  const { provider_id } = useParams();

  const initialFormData = {
    credential: "",
    state: "",
    license_number: "",
    date_issued: "",
    date_effective: "",
    date_expires: "",
    verified: false,
    date_verified: "",
  };

  const initialSortingParams = {
    limit: 10,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [clientParams, setClientParams] = React.useState(initialSortingParams);

  const [credentialsListData, setCredentialsListData] = React.useState([]);
  const [selectedCredential, setSelectedCredential] = React.useState(null);

  const [statesList, setStatesList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);

  const [submitting, setSubmitting] = React.useState(false);
  const [submittingPsv, setSubmittingPsv] = React.useState(false);
  const [isAdminVerified, setIsAdminVerified] = React.useState(false);

  const {
    credential,
    state,
    license_number,
    date_issued,
    date_effective,
    date_expires,
    verified,
    date_verified,
  } = formData;

  const loadProviderCredentialFormData = (currentProviderCredential) => {
    const {
      credential,
      state,
      license_number,
      date_issued,
      date_effective,
      date_expires,
      verified,
      date_verified,
    } = currentProviderCredential;

    const data = {
      credential,
      state,
      license_number,
      date_issued: date_issued ? moment(date_issued).format("MM/DD/YYYY") : "",
      date_effective: date_effective
        ? moment(date_effective).format("MM/DD/YYYY")
        : "",
      date_expires: date_expires
        ? moment(date_expires).format("MM/DD/YYYY")
        : "",
      verified,
      date_verified: date_verified
        ? moment(date_verified).format("MM/DD/YYYY")
        : "",
    };

    setFormData((formData) => ({ ...formData, ...data }));

    const { psv_result = {} } = currentProviderCredential;
    if (psv_result["full_result"]) {
      const psv_full_result = psv_result["full_result"]
        ? psv_result["full_result"]
        : {};
      const psv_report = psv_result["reports"]
        ? psv_result["reports"][0]
        : null;
      const psv_verified_date = psv_result["createdAt"]
        ? moment(psv_result["createdAt"]).format("MM/DD/YYYY hh:mm a")
        : null;

      const date_issued_psv = psv_full_result["OriginalIssueDate"]
        ? psv_full_result["OriginalIssueDate"]
        : psv_full_result["IssuanceDate"];

      const date_effective_psv = psv_full_result["IssuanceDate"];
      const date_expires_psv = psv_full_result["ExpirationDate"];

      const PSV_Data = {
        date_issued_psv: date_issued_psv
          ? moment(date_issued_psv).format("MM/DD/YYYY").toString()
          : "",
        date_effective_psv: date_effective_psv
          ? moment(date_effective_psv).format("MM/DD/YYYY").toString()
          : "",
        date_expires_psv: date_expires_psv
          ? moment(date_expires_psv).format("MM/DD/YYYY").toString()
          : "",
      };

      if (psv_report) {
        PSV_Data["psv_report"] = psv_report;
      }

      if (psv_verified_date) {
        PSV_Data["psv_verified_date"] = psv_verified_date;
      }

      setPSV_Data((prev) => ({ ...prev, ...PSV_Data }));
    } else {
      setPSV_Data(initialPSV_Data);
    }
  };

  React.useEffect(() => {
    if (!currentProviderCredential) return;
    if (!credentialID) return;

    const admin_verify = currentProviderCredential.admin_verify
      ? currentProviderCredential.admin_verify
      : false;
    setIsAdminVerified(admin_verify);

    loadProviderCredentialFormData(currentProviderCredential);
  }, [currentProviderCredential, credentialID]);

  React.useEffect(() => {
    if (!credentialID) return;
    if (!credential) return;

    const filteredCredential = credentialsListData.find(
      (each) => each._id === credential
    );

    if (filteredCredential) {
      setSelectedCredential({
        value: filteredCredential._id,
        label: filteredCredential.code,
      });
    }
  }, [credential, credentialID, credentialsListData]);

  React.useEffect(() => {
    if (!credentialID) return;
    if (!state) return;

    const filteredState = statesList.find(
      (each) => each.abbreviation === state
    );

    if (filteredState) {
      setSelectedState({
        value: filteredState.abbreviation,
        label: `${filteredState.name} (${filteredState.abbreviation})`,
      });
    }
  }, [state, credentialID, statesList]);

  React.useEffect(() => {
    if (!credentialID) return;

    getProviderCredentialById(provider_id, credentialID);
  }, [credentialID, getProviderCredentialById]);

  const onChange = (e) => {
    if (!e.target) {
      return;
    }
    switch (e.target.name) {
      case "verified":
        const isVerified = e.target.checked;
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          date_verified: isVerified ? date_verified : "",
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleCredentialsSelect = (selectedRole) => {
    setSelectedCredential({
      value: selectedRole.value,
      label: selectedRole.label,
    });
    setFormData({
      ...formData,
      credential: selectedRole ? selectedRole.value : null,
    });
  };

  const handleStateSelect = (selectedRole) => {
    setSelectedState({
      value: selectedRole.value,
      label: selectedRole.label,
    });

    setFormData({
      ...formData,
      state: selectedRole ? selectedRole.value : null,
    });
  };

  const reset = () => {
    setModal(false);
    setCredentialID(null);
    setSelectedCredential(null);
    setSelectedState(null);
    setSubmitting(false);
    setSubmittingPsv(false);
    setFormData(initialFormData);
    setPSV_Data(initialPSV_Data);
    removeProviderCredentialErrors();
  };

  const saveFormData = (submitData) => {
    // Save formData
    if (credentialID) {
      updateProviderCredential(
        submitData,
        navigate,
        provider_id,
        credentialID
      ).then((res) => {
        if (!res.status) return;
        if (res.status) reset();
        resetProviderCredentialComponentStore();
        getProviderCredentialList(clientParams, provider_id);
      });
    } else {
      createProviderCredential(submitData, navigate, provider_id).then(
        (res) => {
          if (!res.status) return;
          if (res.status) reset();
          resetProviderCredentialComponentStore();
          getProviderCredentialList(clientParams, provider_id);
        }
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeProviderCredentialErrors();

    let validationRules = [
      {
        param: "credential",
        msg: "The credential is required",
      },
      {
        param: "state",
        msg: "The State is required",
      },
      {
        param: "license_number",
        msg: "The License Number is required",
      },
    ];

    if (verified) {
      validationRules.push({
        param: "date_verified",
        msg: "The Date Verified is required",
      });
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (formData[i] === null || formData[i] === undefined) continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    saveFormData(submitData);
  };

  React.useMemo(async () => {
    const stateListData = await getStatesList();

    setStatesList(stateListData);
  }, [getStatesList]);

  React.useMemo(async () => {
    try {
      const credentialsList = await getCredentialsList();

      setCredentialsListData(credentialsList);
    } catch (error) {
      console.error(error);
    }
  }, [getCredentialsList]);

  const initialPSV_Data = {
    date_issued_psv: "",
    date_effective_psv: "",
    date_expires_psv: "",
    psv_report: null,
    psv_verified_date: "",
  };

  const [PSV_Data, setPSV_Data] = React.useState(initialPSV_Data);
  const {
    date_issued_psv,
    date_effective_psv,
    date_expires_psv,
    psv_report,
    psv_verified_date,
  } = PSV_Data;

  const onDownloadClickHandler = () => {
    if (!psv_report) return;

    const base64 = psv_report["base64"];
    const file_title = psv_report["title"] ? psv_report["title"] : "Report.pdf";

    openBase64File(base64, file_title, "application/pdf");
  };

  const onAcceptPsvResults = (e) => {
    e.preventDefault();
    if (!credentialID) return;

    formData.date_issued = moment(date_issued_psv).format("MM/DD/YYYY");
    formData.date_effective = moment(date_effective_psv).format("MM/DD/YYYY");
    formData.date_expires = moment(date_expires_psv).format("MM/DD/YYYY");
    formData.verified = true;
    formData.date_verified = moment().format("MM/DD/YYYY");
    formData.admin_verify = true;

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );

    setSubmittingPsv(true);
    saveFormData(submitData);
  };

  const handleDateChange = (date, fieldName) => {
    if (date && moment(date).isValid()) {
      const formattedDate = moment(date).format("MM/DD/YYYY");
      setFormData({
        ...formData,
        [fieldName]: formattedDate,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: "",
      });
    }
  };

  return (
    <Modal show={modal} onHide={reset} size="lg">
      <Modal.Header closeButton>
        {credentialID ? <h4> Edit Credential </h4> : <h4> Add Credential </h4>}
      </Modal.Header>
      <Modal.Body>
        {loadingProviderCredential ? (
          <Spinner />
        ) : (
          <Form onSubmit={(e) => onSubmit(e)}>
            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="credential">
                    Credential <span>*</span>
                  </Form.Label>

                  <Select
                    id="credential"
                    name="credential"
                    options={credentialsListData.map((cred) => ({
                      value: cred._id,
                      label: cred.code,
                    }))}
                    value={selectedCredential}
                    onChange={handleCredentialsSelect}
                    invalid={errorList.credential ? true : false}
                  />

                  <Errors current_key="credential" key="credential" />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="state">
                    State <span>*</span>
                  </Form.Label>

                  <Select
                    id="state"
                    name="state"
                    options={statesList.map((state) => ({
                      value: state.abbreviation,
                      label: `${state.name} (${state.abbreviation})`,
                    }))}
                    value={selectedState}
                    onChange={handleStateSelect}
                    invalid={errorList.state ? true : false}
                  />

                  <Errors current_key="state" key="state" />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="license_number">
                    License Number <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.license_number ? "invalid" : ""}
                    type="text"
                    id="license_number"
                    name="license_number"
                    maxLength="40"
                    value={license_number}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="license_number" key="license_number" />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="date_issued">Date Issued</Form.Label>
                  {console.log({ date_issued })}
                  <DatePicker
                    showIcon
                    selected={
                      date_issued && moment(date_issued, "MM/DD/YYYY").isValid()
                        ? moment(date_issued, "MM/DD/YYYY").toDate()
                        : null
                    }
                    onChange={(date) => handleDateChange(date, "date_issued")}
                    className={`form-control ${
                      formData.errorList?.date_issued ? "invalid" : ""
                    }`}
                    dateFormat="MM/dd/yyyy"
                    id="date_issued"
                    name="date_issued"
                    placeholderText="mm/dd/yyyy"
                  />
                  <Errors current_key="date_issued" key="date_issued" />
                </Form.Group>
              </Col>

              {date_issued_psv && !isAdminVerified ? (
                <Col md={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="date_issued_psv">
                      Date Issued from PSV (mm/dd/yyyy)
                    </Form.Label>
                    <Form.Control
                      type="input"
                      id="date_issued_psv"
                      defaultValue={date_issued_psv}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              ) : null}
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="date_effective">
                    Date Effective
                  </Form.Label>
                  <DatePicker
                    showIcon
                    selected={
                      date_effective &&
                      moment(date_effective, "MM/DD/YYYY").isValid()
                        ? moment(date_effective, "MM/DD/YYYY").toDate()
                        : null
                    }
                    onChange={(date) =>
                      handleDateChange(date, "date_effective")
                    }
                    className={`form-control ${
                      formData.errorList?.date_effective ? "invalid" : ""
                    }`}
                    dateFormat="MM/dd/yyyy"
                    id="date_effective"
                    name="date_effective"
                    placeholderText="mm/dd/yyyy"
                  />

                  <Errors current_key="date_effective" key="date_effective" />
                </Form.Group>
              </Col>

              {date_effective_psv && !isAdminVerified ? (
                <Col md={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="date_effective_psv">
                      Date Effective from PSV (mm/dd/yyyy)
                    </Form.Label>
                    <Form.Control
                      type="input"
                      id="date_effective_psv"
                      defaultValue={date_effective_psv}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              ) : null}
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="date_expires">Date Expires</Form.Label>
                  <DatePicker
                    showIcon
                    selected={
                      date_expires &&
                      moment(date_expires, "MM/DD/YYYY").isValid()
                        ? moment(date_expires, "MM/DD/YYYY").toDate()
                        : null
                    }
                    onChange={(date) => handleDateChange(date, "date_expires")}
                    className={`form-control ${
                      formData.errorList?.date_expires ? "invalid" : ""
                    }`}
                    dateFormat="MM/dd/yyyy"
                    id="date_expires"
                    name="date_expires"
                    placeholderText="mm/dd/yyyy"
                  />
                  <Errors current_key="date_expires" key="date_expires" />
                </Form.Group>
              </Col>

              {date_expires_psv && !isAdminVerified ? (
                <Col md={6}>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="date_expires_psv">
                      Date Expires from PSV (mm/dd/yyyy)
                    </Form.Label>
                    <Form.Control
                      type="input"
                      id="date_expires_psv"
                      defaultValue={date_expires_psv}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              ) : null}
            </Row>

            {isAdmin(user) ? (
              <>
                <Row>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="verified">Verified</Form.Label>
                      <Form.Check
                        className={errorList.verified ? "invalid" : ""}
                        type="switch"
                        id="verified"
                        name="verified"
                        checked={verified}
                        onChange={(e) => onChange(e)}
                        label={verified === true ? "Yes" : "No"}
                      />
                      <Errors current_key="verified" key="verified" />
                    </Form.Group>
                  </Col>

                  {psv_report ? (
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="psv_report">PSV Report</Form.Label>

                        <div>
                          <Button
                            type="button"
                            variant="link"
                            size="sm"
                            onClick={() => onDownloadClickHandler()}
                          >
                            <FaEye /> View
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="date_verified">
                        Date Verified
                      </Form.Label>
                      <DatePicker
                        showIcon
                        selected={
                          date_verified &&
                          moment(date_verified, "MM/DD/YYYY").isValid()
                            ? moment(date_verified, "MM/DD/YYYY").toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange(date, "date_verified")
                        }
                        className={`form-control ${
                          formData.errorList?.date_verified ? "invalid" : ""
                        }`}
                        dateFormat="MM/dd/yyyy"
                        id="date_verified"
                        name="date_verified"
                        placeholderText="mm/dd/yyyy"
                        disabled={!verified}
                      />
                      <Errors current_key="date_verified" key="date_verified" />
                    </Form.Group>
                  </Col>

                  {psv_verified_date ? (
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="psv_verified_date">
                          Date Verified PSV
                        </Form.Label>
                        <Form.Control
                          type="input"
                          id="psv_verified_date"
                          defaultValue={psv_verified_date}
                          readOnly
                        />
                      </Form.Group>
                    </Col>
                  ) : null}
                </Row>
              </>
            ) : null}

            <div className="float-end">
              {date_issued_psv && !isAdminVerified ? (
                <Button
                  className="m-2"
                  type="button"
                  variant="primary"
                  disabled={submitting || submittingPsv}
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure you want to accept Psv Results?`
                      )
                    ) {
                      onAcceptPsvResults(e);
                    }
                  }}
                >
                  {submittingPsv ? (
                    <>
                      <span className="spinner-border spinner-border-sm"></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Accept PSV Results</>
                  )}
                </Button>
              ) : null}
              <Button
                className="m-2"
                type="submit"
                variant="primary"
                disabled={submitting || submittingPsv}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting || submittingPsv}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  loadingProviderCredential: state.providerCredential.loadingProviderCredential,
  currentProviderCredential: state.providerCredential.currentProviderCredential,
});

export default connect(mapStateToProps, {
  setErrors,
  removeProviderCredentialErrors,
  createProviderCredential,
  getProviderCredentialList,
  getProviderCredentialById,
  updateProviderCredential,
  getCredentialsList,
  getStatesList,
  resetProviderCredentialComponentStore,
})(CreateProviderCredentialModal);
