import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  degreesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentDegree: null,
  loadingDegreesList: true,
  loadingDegree: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const degreeSlice = createSlice({
  name: "degrees",
  initialState: initialState,
  reducers: {
    loadingDegreesList(state) {
      return {
        ...state,
        loadingDegreesList: true,
      };
    },
    degreeSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingAppTokenList: false,
      };
    },
    degreeListUpdated(state, action) {
      return {
        ...state,
        degreesList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingDegreesList: false,
      };
    },
    resetAppToken(state) {
      return {
        ...initialState,
      };
    },

    degreeError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingDegree: false,
        loadingDegreesList: false,
      };
    },
    loadingOnDegreeSubmit(state) {
      return {
        ...state,
        loadingDegree: true,
      };
    },
    degreeCreated(state) {
      return {
        ...state,
        loadingDegree: false,
      };
    },
    degreeUpdated(state, action) {
      return {
        ...state,
        degreesList: {
          ...state.degreesList,
          data: state.degreesList.data.map((degree) =>
            degree._id === action.payload._id ? action.payload : degree
          ),
        },
      };
    },
    loadCurrentDegree(state, action) {
      return {
        ...state,
        currentDegree: action.payload,
        loadingDegree: false,
      };
    },
    degreeDeleted(state, action) {
      const currentCount = state.degreesList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.degreesList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        degreesList: {
          data: state.degreesList.data.filter(
            (record) => record._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingDegreesList: false,
      };
    },
  },
});

export const {
  loadingDegreesList,
  degreeSearchParametersUpdate,
  degreeListUpdated,
  degreeError,
  degreeCreated,
  resetAppToken,
  loadingOnDegreeSubmit,
  loadCurrentDegree,
  degreeUpdated,
  degreeDeleted,
} = degreeSlice.actions;
export default degreeSlice.reducer;
