import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingDegreesList,
  degreeListUpdated,
  degreeSearchParametersUpdate,
  degreeCreated,
  loadCurrentDegree,
  resetAppToken,
  degreeError,
  loadingOnDegreeSubmit,
  degreeUpdated,
  degreeDeleted,
} from "reducers/degreeReducer";

export const getDegreesList = (params) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = params.query ? params.query : "";
    params.query = query;
    config.params = params;

    dispatch(loadingDegreesList());
    const res = await axios.get("/api/admin/degrees/list", config);

    dispatch(degreeSearchParametersUpdate(params));

    dispatch(degreeListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          degreeError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const createDegree = (formData) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingDegreesList());

    const res = await axios.post("/api/admin/degrees", formData, config);
    if (res.data.status === true) {
      dispatch(degreeCreated(res.data.response));

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(degreeError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          degreeError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getDegreeById = (id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnDegreeSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/degrees/${id}`, config);

    dispatch(loadCurrentDegree(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          degreeError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const updateDegree =
  (formData, degree_id, navigate) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/admin/degrees/${degree_id}`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(degreeUpdated(res.data.response));
        await dispatch(loadCurrentDegree(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(degreeError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            degreeError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const deleteDegree = (degree_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`/api/admin/degrees/${degree_id}`, config);

    dispatch(degreeDeleted(degree_id));
    dispatch(setAlert("Degree deleted", "success"));
  } catch (err) {
    // console.log(err);
    err.response &&
      dispatch(
        degreeError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeDegreeErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetAppToken());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(degreeError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/degrees");
};
