import { combineReducers } from "redux";

import errors from "./errors";
import signup from "./signupReducer";
import alert from "./alert";
import auth from "./auth";
import user from "./user";
import profile from "./profileReducer";
import dashboard from "./dashboardReducer";
import school from "./schoolReducer";
import degree from "./degreeReducer";
import credential from "./credentialReducer";
import taxonomy from "./taxonomyReducer";
import source from "./sourceReducer";
import specialty from "./specialtyReducer";
import subSpecialty from "./subSpecialtyReducer";
import provider from "./providerReducer";
import providerNpi from "./providerNpiReducer";
import providerMedicare from "./providerMedicareReducer";
import providerOigExclusion from "./providerOigExclusionReducer";
import providerDeaLicense from "./providerDeaLicenseReducer";
import providerSamExclusion from "./providerSamExclusionReducer";
import providerEducation from "./providerEducationReducer";
import providerCredential from "./providerCredentialReducer";
import providerSpecialty from "./providerSpecialtyReducer";
import psvOrder from "./psvOrderReducer";
import psvOrderDetail from "./psvOrderDetailReducer";
import psvReady from "./psvReadyReducer";

const rootReducer = combineReducers({
  errors,
  signup,
  alert,
  auth,
  user,
  profile,
  dashboard,
  school,
  degree,
  credential,
  taxonomy,
  source,
  specialty,
  subSpecialty,
  provider,
  providerNpi,
  providerMedicare,
  providerOigExclusion,
  providerDeaLicense,
  providerSamExclusion,
  providerEducation,
  providerCredential,
  providerSpecialty,
  psvOrder,
  psvOrderDetail,
  psvReady,
});

export default rootReducer;
