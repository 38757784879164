import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  taxonomyList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentTaxonomy: null,
  loadingTaxonomyList: true,
  loadingTaxonomy: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const taxonomySlice = createSlice({
  name: "taxonomy",
  initialState: initialState,
  reducers: {
    loadingTaxonomiesList(state) {
      return {
        ...state,
        loadingTaxonomyList: true,
      };
    },
    taxonomySearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingTaxonomyList: false,
      };
    },
    taxonomyListUpdated(state, action) {
      return {
        ...state,
        taxonomyList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingTaxonomyList: false,
      };
    },
    taxonomyDetailsById(state, action) {
      return {
        ...state,
        currentTaxonomy: action.payload,
        loadingTaxonomy: false,
      };
    },
    resetTaxonomy(state) {
      return {
        ...initialState,
      };
    },

    taxonomyError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingTaxonomy: false,
        loadingTaxonomyList: false,
      };
    },
    loadingOnTaxonomySubmit(state) {
      return {
        ...state,
        loadingTaxonomy: true,
      };
    },
    taxonomyCreated(state) {
      return {
        ...state,
        loadingTaxonomy: false,
      };
    },
    taxonomyUpdated(state, action) {
      return {
        ...state,
        taxonomyList: {
          ...state.taxonomyList,
          data: state.taxonomyList.data.map((taxonomy) =>
            taxonomy._id === action.payload._id ? action.payload : taxonomy
          ),
        },
      };
    },
    taxonomyDeleted(state, action) {
      const currentCount = state.taxonomyList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.taxonomyList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        taxonomyList: {
          data: state.taxonomyList.data.filter(
            (taxonomy) => taxonomy._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingTaxonomyList: false,
      };
    },
  },
});
export const {
  loadingTaxonomiesList,
  taxonomySearchParametersUpdate,
  taxonomyListUpdated,
  resetTaxonomy,
  taxonomyError,
  loadingOnTaxonomySubmit,
  taxonomyCreated,
  taxonomyUpdated,
  taxonomyDetailsById,
  taxonomyDeleted,
} = taxonomySlice.actions;
export default taxonomySlice.reducer;
