import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import Spinner from "views/Spinner";
import Select from "react-select";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import {
  getCredentialsList,
  updateSource,
  cancelSave,
  setErrors,
  removeSourceErrors,
  resetComponentStore,
  getSourceById,
  getStatesList,
} from "actions/sourceActions";

import AppBreadcrumb from "../Layout/AppBreadCrumb";

const EditSources = ({
  getCredentialsList,
  updateSource,
  setErrors,
  removeSourceErrors,
  loadingSource,
  currentSource,
  errorList,
  getSourceById,
  resetComponentStore,
  getStatesList,
}) => {
  const navigate = useNavigate();
  const { source_id } = useParams();

  const [credentialsListData, setCredentialsListData] = React.useState([]);
  const [selectedCredential, setSelectedCredential] = React.useState(null);

  const [statesList, setStatesList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);

  const initialFormData = {
    code: "",
    description: "",
    national: "",
    state: "",
    credential: "",
    url: "",
    phone: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const { code, description, national, state, credential, url, phone } =
    formData;

  const loadSourceFormData = (currentSource) => {
    const { code, description, national, state, credential, url, phone } =
      currentSource;

    const data = { code, description, national, state, credential, url, phone };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!credential) return;

    const filtered = credentialsListData.find(
      (each) => each._id === credential
    );

    if (!filtered) return;

    setSelectedCredential({
      value: filtered._id,
      label: filtered.code,
    });
  }, [currentSource, credentialsListData]);

  React.useEffect(() => {
    if (!state) return;

    const filtered = statesList.find((each) => each.abbreviation === state);

    if (!filtered) return;

    setSelectedState({
      value: filtered.abbreviation,
      label: filtered.name,
    });
  }, [currentSource, statesList]);

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentSource) return;

    loadSourceFormData(currentSource);
  }, [currentSource]);

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      case "national":
        if (e.target.value === "true") {
          setSelectedState(null);
        }
        setFormData({
          ...formData,
          [e.target.name]: e.target.value === "true" ? true : false,
          state: e.target.value === "true" ? "" : state,
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleCredentialsSelect = (selectedRole) => {
    setSelectedCredential(selectedRole);
    setFormData({
      ...formData,
      credential: selectedRole ? selectedRole.value : null,
    });
  };

  const handleStateSelect = (selectedRole) => {
    setSelectedState(selectedRole);
    setFormData({
      ...formData,
      state: selectedRole ? selectedRole.value : null,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeSourceErrors();

    let validationRules = [
      {
        param: "code",
        msg: "The Code is required",
      },
      {
        param: "credential",
        msg: "The Credential ID is required",
      },
    ];

    if (!national) {
      validationRules.push({
        param: "state",
        msg: "The State is required",
      });
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (formData[i] === null || formData[i] === undefined) continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    updateSource(submitData, navigate, source_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useMemo(() => {
    if (!source_id) return;

    resetComponentStore();

    getSourceById(source_id);
  }, []);

  React.useMemo(async () => {
    const stateListData = await getStatesList();
    setStatesList(stateListData);
  }, [getStatesList]);

  React.useMemo(async () => {
    try {
      const credentialsList = await getCredentialsList();

      setCredentialsListData(credentialsList);
    } catch (error) {
      console.error(error);
    }
  }, [getCredentialsList, resetComponentStore]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Edit Source"
        crumbs={[
          { name: "Sources", path: "/admin/sources" },
          {
            name: code ? code : "Edit Source",
          },
        ]}
      />

      {loadingSource ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <h4 className="header-title mb-3">Source Information</h4>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="code">
                    Code <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.code ? "invalid" : ""}
                    type="text"
                    id="code"
                    name="code"
                    maxLength="50"
                    value={code}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="code" key="code" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="credential">
                    Credential <span>*</span>
                  </Form.Label>

                  <Select
                    id="credential"
                    name="credential"
                    value={selectedCredential}
                    options={credentialsListData.map((cred) => ({
                      value: cred._id,
                      label: cred.code,
                    }))}
                    onChange={handleCredentialsSelect}
                    invalid={errorList.credential ? true : false}
                    isDisabled={isDisabled}
                  />

                  <Errors current_key="credential" key="credential" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="description">Description</Form.Label>

                  <Form.Control
                    className={errorList.description ? "invalid" : ""}
                    as="textarea"
                    rows={3}
                    id="description"
                    name="description"
                    maxLength="255"
                    value={description}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  <Errors current_key="description" key="description" />
                </Form.Group>

                <Row>
                  <Form.Label>National</Form.Label>
                  <Col sm="3" md="1">
                    <Form.Group
                      controlId="national-true"
                      className="form-group"
                    >
                      <Form.Check
                        className={errorList.national ? "invalid" : ""}
                        type="radio"
                        label="Yes"
                        id="national-true"
                        name="national"
                        value="true"
                        checked={national}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="3" md="1">
                    <Form.Group
                      controlId="national-false"
                      className="form-group"
                    >
                      <Form.Check
                        className={errorList.national ? "invalid" : ""}
                        type="radio"
                        label="No"
                        id="national-false"
                        name="national"
                        value="false"
                        checked={!national}
                        onChange={(e) => onChange(e)}
                        disabled={isDisabled}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="state">State</Form.Label>

                  <Select
                    id="state"
                    name="state"
                    options={statesList.map((state) => ({
                      value: state.abbreviation,
                      label: state.name,
                    }))}
                    onChange={handleStateSelect}
                    invalid={errorList.state ? true : false}
                    value={selectedState}
                    isDisabled={national || isDisabled}
                  />

                  <Errors current_key="state" key="state" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="url">URL</Form.Label>
                  <Form.Control
                    className={errorList.url ? "invalid" : ""}
                    type="text"
                    id="url"
                    name="url"
                    maxLength="120"
                    value={url}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="url" key="url" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="phone">Phone</Form.Label>

                  <Form.Control
                    className={errorList.phone ? "invalid" : ""}
                    type="text"
                    id="phone"
                    name="phone"
                    maxLength="20"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={phone}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  <Errors current_key="phone" key="phone" />
                </Form.Group>

                <br></br>

                <div className="float-end">
                  <Button
                    className="m-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditSources.propTypes = {
  getCredentialsList: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingSource: state.source.loadingSource,
  currentSource: state.source.currentSource,
  statesList: state.source.statesList,
});

export default connect(mapStateToProps, {
  getCredentialsList,
  updateSource,
  cancelSave,
  setErrors,
  removeSourceErrors,
  resetComponentStore,
  getSourceById,
  resetComponentStore,
  getStatesList,
})(EditSources);
