export const ADMIN_ROLE = 1;
export const LAB_ROLE = 3;
export const EMPLOYER_ROLE = 2;
export const EMPLOYEE_ROLE = 4;
export const DEFAULT_PAGE_SIZE = 20;

export const PAGE_SIZE_OPTIONS = [
  {
    text: "20",
    page: 20,
  },
  {
    text: "50",
    page: 50,
  },
  {
    text: "100",
    page: 100,
  },
];

export const clientStatus = [
  {
    label: "Ready To Post",
    value: "READY",
  },
  {
    label: "On Hold",
    value: "ONHOLD",
  },
  {
    label: "Posted",
    value: "DONE",
  },
];

export const orgTypesGlobal = [
  {
    label: "General Acute Care Hospital",
    value: 1,
  },
  {
    label: "Single Specialty Group",
    value: 2,
  },
  {
    label: "Multiple Specialty Group",
    value: 3,
  },
  {
    label: "Clinic/Center",
    value: 4,
  },
];
