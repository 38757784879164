import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BiPlusMedical, BiTrash } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";

import PiDataTable from "views/DataTable/PiDataTable";
import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";

import { getUsersList, deleteUser, resetComponentStore } from "actions/user";
import UserFilters from "./UserFilters";
import { isAdmin } from "utils/helper";

const UsersList = ({
  loggedInUser,
  usersList: { data, count },
  getUsersList,
  deleteUser,
  loadingUserList,
  resetComponentStore,
  sortingParams,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [userParams, setUserParams] = React.useState(initialSortingParams);

  const actions = (
    <div className="page-actions">
      <Link to="/admin/users/create" title="create">
        <Button color="primary" size="sm">
          <BiPlusMedical /> Create New User
        </Button>
      </Link>
    </div>
  );

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
      width: "25%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      sortField: "email",
      width: "35%",
    },
    {
      name: "Role",
      selector: (row) =>
        row.role && row.role === 2
          ? "Admin"
          : row.role && row.role === 3
          ? "Provider"
          : "User",
      sortable: false,
      sortField: "role",
      width: "20%",
    },
    {
      name: "Actions",
      width: "calc(20% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Link to={`/admin/users/${row._id}/profile`} title="View User">
            <Button variant="primary" size="sm">
              <FaRegEye />
            </Button>
          </Link>
          <Button
            className="ml-1"
            size="sm"
            title={
              row._id === loggedInUser._id
                ? "Logged in user can't be deleted."
                : "Delete"
            }
            type="button"
            variant="danger"
            disabled={row._id === loggedInUser._id}
            onClick={(e) => {
              if (row._id === loggedInUser._id) return;

              if (
                window.confirm(`Are you sure you want to delete: ${row.name}?`)
              ) {
                deleteUser(row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    if (!loggedInUser) return;

    // To Prevent API call when user is not a admin
    if (!isAdmin(loggedInUser)) {
      navigate("/admin/dashboard");
      return;
    }

    getUsersList(userParams);
  }, [getUsersList, userParams, resetComponentStore, loggedInUser]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              name: {
                value: searchText,
                type: "String",
              },
              email: {
                value: searchText,
                type: "String",
              },
              role: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setUserParams(params);
  };

  const onFilterChange = (newParams) => {
    setUserParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Users" crumbs={[{ name: "Users" }]} />

      <Card>
        <Card.Body>
          {actions}
          <div className="table-filter-section">
            <Row>
              <Col md="2">
                <UserFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterName="Search"
                  filterParams={userParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={userParams}
            setParams={setUserParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingUserList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

UsersList.propTypes = {
  getUsersList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  usersList: state.user.usersList,
  loadingUserList: state.user.loadingUserList,
  sortingParams: state.user.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getUsersList,
  deleteUser,
  resetComponentStore,
})(UsersList);
