import axios from "axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "./errors";

import { logout } from "./auth";

export const initiateOneDriveToken = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `/api/admin/psv-output-processing/onedrive/init-token`,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert("OneDrive access token initiated", "success"));
      return res.data.response ? res.data.response : "";
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const generateOneDriveToken = (code) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `/api/admin/psv-output-processing/onedrive/generate-access-token/${code}`,
      config
    );

    if (res.data.status === true) {
      dispatch(setAlert("OneDrive access token created", "success"));
      return res.data.response ? res.data.response : "";
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const initiatePsvOrderOneDriveOutputProcessing =
  () => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const res = await axios.get(
        `/api/admin/psv-output-processing/onedrive/initiate-psv-output-process`,
        config
      );

      if (res.data.status === true) {
        dispatch(
          setAlert(
            "PSV Order Output Processing Initiated through OneDrive",
            "success"
          )
        );
        return res.data.response ? res.data.response : "";
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };
