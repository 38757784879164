import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingTaxonomiesList,
  taxonomySearchParametersUpdate,
  taxonomyListUpdated,
  resetTaxonomy,
  taxonomyError,
  loadingOnTaxonomySubmit,
  taxonomyCreated,
  taxonomyUpdated,
  taxonomyDetailsById,
  taxonomyDeleted,
} from "reducers/taxonomyReducer";

export const getTaxonomiesList = (taxonomyParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = taxonomyParams.query ? taxonomyParams.query : "";
    taxonomyParams.query = query;
    config.params = taxonomyParams;

    dispatch(loadingTaxonomiesList());
    const res = await axios.get("/api/admin/taxonomies/list", config);

    dispatch(taxonomySearchParametersUpdate(taxonomyParams));

    dispatch(taxonomyListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          taxonomyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getTaxonomyById = (taxonomy_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnTaxonomySubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/taxonomies/${taxonomy_id}`, config);

    dispatch(taxonomyDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          taxonomyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const createTaxonomy = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnTaxonomySubmit());

    const res = await axios.post("/api/admin/taxonomies", formData, config);

    if (res.data.status === true) {
      dispatch(taxonomyCreated(res.data.response));
      dispatch(setAlert("Taxonomy Created.", "success"));
      navigate(`/admin/taxonomies`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(taxonomyError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          taxonomyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const updateTaxonomy =
  (formData, navigate, taxonomy_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/admin/taxonomies/${taxonomy_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(taxonomyUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        dispatch(setAlert(res.data?.message, "danger"));

        if (errors.length) {
          dispatch(taxonomyError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            taxonomyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const deleteTaxonomy = (taxonomy_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`/api/admin/taxonomies/${taxonomy_id}`, config);

    dispatch(taxonomyDeleted(taxonomy_id));
    dispatch(setAlert("Taxonomy deleted", "success"));
  } catch (err) {
    console.log(err);
    err.response &&
      dispatch(
        taxonomyError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeTaxonomyErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetTaxonomy());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(taxonomyError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/taxonomies");
};
