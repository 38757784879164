import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProviderDeaLicense: null,
  loadingProviderDeaLicense: false,
  error: {},
};

const providerDeaLicenseSlice = createSlice({
  name: "providerDeaLicense",
  initialState: initialState,
  reducers: {
    resetProviderDeaLicense(state) {
      return {
        ...initialState,
      };
    },
    providerDeaLicenseError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProviderDeaLicense: false,
      };
    },
    loadingOnProviderDeaLicenseSubmit(state) {
      return {
        ...state,
        loadingProviderDeaLicense: true,
      };
    },
    providerDeaLicenseUpdated(state, action) {
      return {
        ...state,

        currentProviderDeaLicense: action.payload,
        loadingProviderDeaLicense: false,
      };
    },
  },
});
export const {
  resetProviderDeaLicense,
  providerDeaLicenseError,
  loadingOnProviderDeaLicenseSubmit,
  providerDeaLicenseUpdated,
} = providerDeaLicenseSlice.actions;
export default providerDeaLicenseSlice.reducer;
