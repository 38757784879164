import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BiPlusMedical, BiTrash } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import {
  getTaxonomiesList,
  resetComponentStore,
  deleteTaxonomy,
} from "actions/taxonomyActions";

import TaxonomyFilters from "./TaxonomyFilters";

const TaxonomiesList = ({
  taxonomyList: { data, count },
  getTaxonomiesList,
  loadingTaxonomyList,
  resetComponentStore,
  sortingParams,
  deleteTaxonomy,
}) => {
  const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const actions = (
    <div className="page-actions">
      <Link to="/admin/taxonomies/create" title="create">
        <Button color="primary" size="sm">
          <BiPlusMedical /> Create New Taxonomy
        </Button>
      </Link>
    </div>
  );

  const columns = [
    {
      name: "Provider Taxonomy Code",
      selector: (row) => row.providerTaxonomyCode,
      sortable: false,
      width: "25%",
    },
    {
      name: "Medicare Specialty Code",
      selector: (row) => row.medicareSpecialtyCode,
      sortable: false,
      width: "25%",
    },
    {
      name: "Medicare Provider Supplier Type",
      cell: (row) => row.medicareProviderSupplierType,
      sortable: false,
      width: "30%",
      style: {
        lineHeight: 1.3,
      },
    },
    {
      name: "Actions",
      width: "calc(20% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Link to={`/admin/taxonomies/${row._id}/edit`} title="View Taxonomy">
            <Button variant="primary" size="sm">
              <FaRegEye />
            </Button>
          </Link>

          <Button
            className="ml-1"
            size="sm"
            type="button"
            variant="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete: ${row.providerTaxonomyCode}?`
                )
              ) {
                deleteTaxonomy(row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getTaxonomiesList(clientParams);
  }, [getTaxonomiesList, clientParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              providerTaxonomyCode: {
                value: searchText,
                type: "String",
              },
              medicareSpecialtyCode: {
                value: searchText,
                type: "String",
              },
              medicareProviderSupplierType: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClientParams(params);
  };

  const onFilterChange = (newParams) => {
    setClientParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Taxonomies" crumbs={[{ name: "Taxonomies" }]} />

      <Card>
        <Card.Body>
          {actions}

          <div className="table-filter-section">
            <Row>
              <Col md="6">
                <TaxonomyFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingTaxonomyList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

TaxonomiesList.propTypes = {
  getTaxonomiesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  taxonomyList: state.taxonomy.taxonomyList,
  loadingTaxonomyList: state.taxonomy.loadingTaxonomyList,
  sortingParams: state.taxonomy.sortingParams,
});

export default connect(mapStateToProps, {
  getTaxonomiesList,
  resetComponentStore,
  deleteTaxonomy,
})(TaxonomiesList);
