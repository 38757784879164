import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BiPlusMedical, BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import {
  getDegreesList,
  resetComponentStore,
  deleteDegree,
} from "actions/degreeActions";

import DegreeFilters from "./DegreeFilters";

const DegreesList = ({
  degreesList: { data, count },
  getDegreesList,
  loadingDegreesList,
  resetComponentStore,
  sortingParams,
  loggedInUser,
  deleteDegree,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [params, setParams] = React.useState(initialSortingParams);

  const [onlyOnce, setOnce] = React.useState(true);
  const actions = (
    <div className="page-actions">
      <Link to="/admin/degrees/create" title="create">
        <Button color="primary" size="sm">
          <BiPlusMedical /> Create New Degree
        </Button>
      </Link>
    </div>
  );

  const columns = [
    {
      name: "Degree",
      selector: (row) => (
        <React.Fragment>
          {row.degree}
          {row.status === 3 ? <span className="sticker-new">New</span> : null}
        </React.Fragment>
      ),
      sortable: false,
      width: "40%",
    },
    {
      name: "Abbreviation",
      selector: (row) => row.abbreviation,
      sortable: false,
      width: "30%",
    },
    {
      name: "Actions",
      width: "calc(30% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Link to={`/admin/degrees/${row._id}`} title="View Edit Degree">
            <Button variant="primary" size="sm">
              <FaEdit />
            </Button>
          </Link>

          <Button
            className="ml-1"
            size="sm"
            title="Delete"
            type="button"
            variant="danger"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete: ${row.degree}?`
                )
              ) {
                deleteDegree(row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    if (!loggedInUser) return;

    getDegreesList(params);
  }, [getDegreesList, params, resetComponentStore, loggedInUser]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              degree: {
                value: searchText,
                type: "String",
              },
              abbreviation: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setParams(params);
  };

  const onFilterChange = (newParams) => {
    setParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Degrees" crumbs={[{ name: "Degrees" }]} />

      <Card>
        <Card.Body>
          {actions}

          <div className="table-filter-section">
            <Row>
              <Col md="6">
                <DegreeFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterParams={params}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={params}
            setParams={setParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingDegreesList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

DegreesList.propTypes = {
  getDegreesList: PropTypes.func.isRequired,
  deleteDegree: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  degreesList: state.degree.degreesList,
  loadingDegreesList: state.degree.loadingDegreesList,
  sortingParams: state.degree.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getDegreesList,
  resetComponentStore,
  deleteDegree,
})(DegreesList);
