import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { isAdmin } from "utils/helper";
import { MdEdit } from "react-icons/md";
import Spinner from "views/Spinner";

import {
  setErrors,
  removeProviderSamExclusionErrors,
  saveProviderSamExclusion,
  getProviderSamExclusionById,
  resetProviderSamExclusionComponentStore,
} from "actions/providerSamExclusionActions";
import EditProviderSamExclusionModal from "./EditProviderSamExclusionModal";
import NoData from "./NoData";

const ProviderSamExclusion = ({
  user,
  setErrors,
  errorList,
  removeProviderSamExclusionErrors,
  getProviderSamExclusionById,
  saveProviderSamExclusion,
  currentProviderSamExclusion,
  loadingProviderSamExclusion,
  resetProviderSamExclusionComponentStore,
}) => {
  const { provider_id } = useParams();
  const [modal, setModal] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);

  const initialFormData = {
    SAM_Exclusion: false,
    SAM_ExclusionDate: "",
    SAM_ExclusionEndDate: "",
    SAM_ExclusionType: "",
    SAM_ExclusionAgency: "",
    SAM_Verified: false,
    SAM_DateVerified: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const {
    SAM_Exclusion,
    SAM_ExclusionDate,
    SAM_ExclusionEndDate,
    SAM_ExclusionType,
    SAM_ExclusionAgency,
    SAM_Verified,
    SAM_DateVerified,
  } = formData;

  const loadProviderSamExclusionFormData = (currentProviderSamExclusion) => {
    const {
      SAM_Exclusion,
      SAM_ExclusionDate,
      SAM_ExclusionEndDate,
      SAM_ExclusionType,
      SAM_ExclusionAgency,
      SAM_Verified,
      SAM_DateVerified,
    } = currentProviderSamExclusion;

    const data = {
      SAM_Exclusion,
      SAM_ExclusionDate: SAM_ExclusionDate
        ? moment(SAM_ExclusionDate).format("YYYY-MM-DD")
        : "",
      SAM_ExclusionEndDate: SAM_ExclusionEndDate
        ? moment(SAM_ExclusionEndDate).format("YYYY-MM-DD")
        : "",
      SAM_ExclusionType,
      SAM_ExclusionAgency,
      SAM_Verified,
      SAM_DateVerified: SAM_DateVerified
        ? moment(SAM_DateVerified).format("YYYY-MM-DD")
        : "",
    };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!provider_id) return;
    resetProviderSamExclusionComponentStore();
    getProviderSamExclusionById(provider_id);
  }, [provider_id, getProviderSamExclusionById]);

  React.useEffect(() => {
    if (!currentProviderSamExclusion) {
      setFormData(initialFormData);
      return;
    }

    loadProviderSamExclusionFormData(currentProviderSamExclusion);
  }, [currentProviderSamExclusion]);

  const onEditClickHandler = () => {
    setModal(true);
    setDisabled(!isDisabled);
  };

  return (
    <React.Fragment>
      <EditProviderSamExclusionModal
        provider_id={provider_id}
        modal={modal}
        setModal={setModal}
        isDisabled={isDisabled}
        setDisabled={setDisabled}
        formData={formData}
        setFormData={setFormData}
        errorList={errorList}
        setErrors={setErrors}
        currentProviderSamExclusion={currentProviderSamExclusion}
        loadProviderSamExclusionFormData={loadProviderSamExclusionFormData}
        getProviderSamExclusionById={getProviderSamExclusionById}
        saveProviderSamExclusion={saveProviderSamExclusion}
        removeProviderSamExclusionErrors={removeProviderSamExclusionErrors}
      />
      <Col xs="12" sm="6" lg="6">
        <Card className="mt-5">
          <Card.Body className="misc-info-card">
            {" "}
            <div className="card-heading mb-3">
              {" "}
              <h4 className="header-title">SAM Exclusion</h4>
              {isAdmin(user) ? (
                <Button
                  variant="link"
                  size="sm"
                  className="float-end"
                  onClick={() => onEditClickHandler()}
                >
                  <MdEdit title="Click to Edit" size={20} />
                </Button>
              ) : null}
            </div>
            {loadingProviderSamExclusion ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {SAM_Exclusion ? (
                  <Row>
                    <Col className="mb-4" lg={3} md={4} sm={6} xs={6}>
                      <div>SAM Exclusion?</div>
                      <div className="fw-bold">
                        {SAM_Exclusion ? "Yes" : "No"}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={3} md={4} sm={6} xs={6}>
                      <div>SAM Exclusion Date</div>
                      <div className="fw-bold">
                        {moment(SAM_ExclusionDate).format("MM/DD/YYYY")}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={3} md={4} sm={6} xs={6}>
                      <div>SAM Exclusion End Date</div>
                      <div className="fw-bold">
                        {moment(SAM_ExclusionEndDate).format("MM/DD/YYYY")}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={3} md={4} sm={6} xs={6}>
                      <div>Exclusion Type</div>
                      <div className="fw-bold">{SAM_ExclusionType}</div>
                    </Col>

                    <Col className="mb-4" lg={3} md={4} sm={6} xs={6}>
                      <div>Exclusion Agency</div>
                      <div className="fw-bold">{SAM_ExclusionAgency}</div>
                    </Col>

                    <Col className="mb-4" lg={3} md={4} sm={6} xs={6}>
                      <div>Status</div>
                      <div className="fw-bold">
                        {SAM_Verified ? "Verified" : "Not Verified"}
                      </div>
                    </Col>

                    {SAM_DateVerified ? (
                      <Col className="mb-4" lg={3} md={4} sm={6} xs={6}>
                        <div>Date Verified</div>
                        <div className="fw-bold">
                          {moment(SAM_DateVerified).format("MM/DD/YYYY")}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                ) : (
                  <NoData loggedUser={user} />
                )}
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

ProviderSamExclusion.propTypes = {
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  currentProviderSamExclusion:
    state.providerSamExclusion.currentProviderSamExclusion,
  loadingProviderSamExclusion:
    state.providerSamExclusion.loadingProviderSamExclusion,
});

export default connect(mapStateToProps, {
  setErrors,
  removeProviderSamExclusionErrors,
  saveProviderSamExclusion,
  getProviderSamExclusionById,
  resetProviderSamExclusionComponentStore,
})(ProviderSamExclusion);
