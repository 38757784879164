import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BiPlusMedical, BiTrash } from "react-icons/bi";
import { FaRegEye } from "react-icons/fa";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import SpecialtyFilters from "./SpecialtyFilters";
import SubSpecialtiesList from "./SubSpecialtiesList";

import {
  getSpecialtiesList,
  resetComponentStore,
  deleteSpecialty,
} from "actions/specialtyActions";

const SpecialtiesList = ({
  specialtiesList: { data, count },
  getSpecialtiesList,
  loadingSpecialtiesList,
  resetComponentStore,
  sortingParams,
  deleteSpecialty,
}) => {
  const navigate = useNavigate();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [expandedRow, setExpandedRow] = React.useState(null);
  const [onlyOnce, setOnce] = React.useState(true);

  const actions = (
    <div className="page-actions">
      <Link to="/admin/specialties/create" title="create">
        <Button color="primary" size="sm">
          <BiPlusMedical /> Create New Specialty
        </Button>
      </Link>
    </div>
  );

  const handleArrowClick = (row) => {
    if (expandedRow === row) {
      setExpandedRow(null);
    } else {
      setExpandedRow(row);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => (
        <>
          {row.name}{" "}
          <span onClick={() => handleArrowClick(row)}>
            {expandedRow === row ? (
              <IoMdArrowDropdown size={20} />
            ) : (
              <IoMdArrowDropright size={20} />
            )}
          </span>
        </>
      ),

      sortable: false,
      width: "60%",
    },
    {
      name: "Actions",
      width: "calc(40% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Link
            to={`/admin/specialties/${row._id}/edit`}
            title="View Specialty"
          >
            <Button variant="primary" size="sm">
              <FaRegEye />
            </Button>
          </Link>

          <Button
            className="ml-1"
            size="sm"
            type="button"
            variant="danger"
            onClick={(e) => {
              if (
                window.confirm(`Are you sure you want to delete: ${row.name}?`)
              ) {
                deleteSpecialty(row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getSpecialtiesList(clientParams);
  }, [getSpecialtiesList, clientParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              name: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClientParams(params);
  };

  const onFilterChange = (newParams) => {
    setClientParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Specialties"
        crumbs={[{ name: "Specialties" }]}
      />

      <Card>
        <Card.Body>
          {actions}

          <div className="table-filter-section">
            <Row>
              <Col md="2">
                <SpecialtyFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingSpecialtiesList}
            highlightOnHover
            persistTableHead={true}
            expandableRows
            expandableRowsComponent={SubSpecialtiesList}
            expandableRowsHideExpander={true}
            expandableRowExpanded={(row) => expandedRow === row}
            onRowExpandToggled={(bool, row) => {
              handleArrowClick(row);
            }}
            expandOnRowClicked
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

SpecialtiesList.propTypes = {
  getSpecialtiesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  specialtiesList: state.specialty.specialtiesList,
  loadingSpecialtiesList: state.specialty.loadingSpecialtiesList,
  sortingParams: state.specialty.sortingParams,
});

export default connect(mapStateToProps, {
  getSpecialtiesList,
  resetComponentStore,
  deleteSpecialty,
})(SpecialtiesList);
