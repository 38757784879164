import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientsList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingClientList: true,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    loadingDashboardClientsList(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    dashboardClientListUpdated(state, action) {
      return {
        ...state,
        clientsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingClientList: false,
      };
    },
    dashboardError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingClientList: false,
      };
    },
  },
});

export const {
  loadingDashboardClientsList,
  dashboardClientListUpdated,
  dashboardError,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
