import React from "react";
import { Row } from "react-bootstrap";
import ProviderDashboard from "./ProviderDashboard";
import ProviderNpi from "./ProviderNpi";
import ProviderMedicare from "./ProviderMedicare";
import ProviderOigExclusion from "./ProviderOigExclusion";
import ProviderDeaLicense from "./ProviderDeaLicense";
import ProviderSamExclusion from "./ProviderSamExclusion";
import ProviderEducationsList from "./ProviderEducationsList";
import ProviderCredentialsList from "./ProviderCredentialsList";
import ProviderSpecialtiesList from "./ProviderSpecialtiesList";

const ProviderInfo = () => {
  return (
    <div>
      <ProviderDashboard />
      <Row>
        <ProviderNpi />
        <ProviderMedicare />
        <ProviderOigExclusion />
        <ProviderDeaLicense />
        <ProviderSamExclusion />
      </Row>
      <ProviderEducationsList />
      <ProviderCredentialsList />
      <ProviderSpecialtiesList />
    </div>
  );
};

export default ProviderInfo;
