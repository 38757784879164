import React from "react";
import { Row, Col } from "react-bootstrap";

const OigExclusionItems = ({ oigItems, onChangeHandler, selectedItems }) => {
  return (
    <React.Fragment>
      {oigItems.length ? (
        <div className="dt-each mb-2">
          {/* <div className="mb-1"><strong>OigExclusions</strong></div> */}
          {oigItems.map((item, i) => {
            let unverifiedlabel = "";
            let unverifiedBtnClass = "";
            let label = "";
            let btnClass = "";
            if (!item.OIG_Verified) {
              unverifiedlabel = "Unverified";
              unverifiedBtnClass = "bg-info";
            }

            return (
              <Row key={i}>
                <Col>
                  <input
                    type="checkbox"
                    id={`psv_item_oig_${item.provider.toString()}_${i}`}
                    checked={
                      selectedItems.some(
                        (selectedItem) =>
                          selectedItem.ProviderID === item.provider &&
                          selectedItem.OigExclusions &&
                          selectedItem.OigExclusions.includes(item._id)
                      ) ||
                      (item.submitted_to_verify
                        ? item.submitted_to_verify
                        : false)
                    }
                    onChange={(e) => onChangeHandler(e, "OigExclusions", item)}
                    disabled={
                      item.submitted_to_verify
                        ? item.submitted_to_verify
                        : false
                    }
                  />
                  <label
                    htmlFor={`psv_item_oig_${item.provider.toString()}_${i}`}
                  >
                    <span className="ml-1">OIG Exclusions</span>{" "}
                    {unverifiedlabel ? (
                      <span className={`badge ${unverifiedBtnClass}`}>
                        {unverifiedlabel}
                      </span>
                    ) : null}{" "}
                    {label ? (
                      <span className={`badge ${btnClass}`}>{label}</span>
                    ) : null}{" "}
                    {item.submitted_to_verify ? (
                      <span className="badge bg-secondary">In Process</span>
                    ) : null}
                  </label>
                </Col>
              </Row>
            );
          })}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default OigExclusionItems;
