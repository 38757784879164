import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Form, Row, Card, Alert } from "react-bootstrap";
import validator from "validator";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";

import {
  createSchool,
  cancelSave,
  setErrors,
  removeSchoolErrors,
  resetComponentStore,
  getStatesList,
} from "actions/schoolActions";
import { isAdmin } from "utils/helper";

const CreateSchool = ({
  createSchool,
  errorList,
  cancelSave,
  loadingAppToken,
  setErrors,
  removeSchoolErrors,
  resetComponentStore,
  loggedInUser,
  getStatesList,
}) => {
  const navigate = useNavigate();

  const [isCreated, setIsCreated] = React.useState(false);

  const initialFormData = {
    name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zipcode: "",
    url: "",
    phone: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const [statesList, setStatesList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);

  const {
    name,
    address_line1,
    address_line2,
    city,
    state,
    zipcode,
    url,
    phone,
  } = formData;

  const handleStateSelect = (selectedRole) => {
    setSelectedState({
      value: selectedRole.value,
      label: selectedRole.label,
    });

    setFormData({
      ...formData,
      state: selectedRole ? selectedRole.value : null,
    });
  };

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeSchoolErrors();

    let validationRules = [
      {
        param: "name",
        msg: "The Name is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (!validator.isURL(formData.url, { require_protocol: true })) {
      errors.push({
        param: "url",
        msg: "The URL is not valid",
      });
    }

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    // console.log("Add School Submit data", submitData);
    createSchool(submitData, navigate).then((res) => {
      if (!res.status) return;
      setIsCreated(true);
    });
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();

    if (!loggedInUser) return;

    if (!isAdmin(loggedInUser)) {
      navigate("/admin/dashboard");
    }
  }, [loggedInUser]);

  React.useMemo(async () => {
    const stateListData = await getStatesList();

    setStatesList(stateListData);
  }, [getStatesList]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Create New School"
        crumbs={[
          { name: "Schools", path: "/admin/schools" },
          { name: "Create New School" },
        ]}
      />

      {loadingAppToken ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title mb-3">School Information</h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="name">
                    Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.name ? "invalid" : ""}
                    type="text"
                    id="name"
                    name="name"
                    maxLength="120"
                    value={name}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="name" key="name" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="address_line1">
                    Address Line 1
                  </Form.Label>
                  <Form.Control
                    className={errorList.address_line1 ? "invalid" : ""}
                    type="text"
                    id="address_line1"
                    name="address_line1"
                    maxLength="80"
                    value={address_line1}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="address_line1" key="address_line1" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="address_line2">
                    Address Line 2
                  </Form.Label>
                  <Form.Control
                    className={errorList.address_line2 ? "invalid" : ""}
                    type="text"
                    id="address_line2"
                    name="address_line2"
                    maxLength="80"
                    value={address_line2}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="address_line2" key="address_line2" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="city">City</Form.Label>
                  <Form.Control
                    className={errorList.city ? "invalid" : ""}
                    type="text"
                    id="city"
                    name="city"
                    maxLength="40"
                    value={city}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="city" key="city" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="state">State</Form.Label>
                  <Select
                    id="state"
                    name="state"
                    options={statesList.map((state) => ({
                      value: state.abbreviation,
                      label: `${state.name} (${state.abbreviation})`,
                    }))}
                    value={selectedState}
                    onChange={handleStateSelect}
                    invalid={errorList.state ? true : false}
                  />
                  <Errors current_key="state" key="state" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="zipcode">Zip Code</Form.Label>
                  <Form.Control
                    className={errorList.zipcode ? "invalid" : ""}
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    maxLength="10"
                    value={zipcode}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="zipcode" key="zipcode" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="url">URL</Form.Label>
                  <Form.Control
                    className={errorList.url ? "invalid" : ""}
                    type="text"
                    id="url"
                    name="url"
                    value={url}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="url" key="url" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="phone">Phone</Form.Label>
                  <Form.Control
                    className={errorList.phone ? "invalid" : ""}
                    type="text"
                    id="phone"
                    name="phone"
                    maxLength="20"
                    value={phone}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="phone" key="phone" />
                </Form.Group>

                {isCreated && name ? (
                  <Form.Group className="form-group mt-5 mb-3">
                    <Alert show={true} variant="success">
                      School "{name}" created.
                    </Alert>
                  </Form.Group>
                ) : undefined}

                <br></br>

                <div className="float-end">
                  {!isCreated ? (
                    <>
                      <Button
                        className="m-2"
                        type="submit"
                        size="sm"
                        variant="primary"
                      >
                        Create
                      </Button>
                      <Button
                        className="ml-2"
                        type="reset"
                        size="sm"
                        variant="danger"
                        onClick={onClickHandel}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Link to="/admin/schools">
                      <Button color="primary" size="sm">
                        View All Schools
                      </Button>
                    </Link>
                  )}
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

CreateSchool.propTypes = {
  createSchool: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingSchool: state.school.loadingSchool,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  createSchool,
  cancelSave,
  setErrors,
  removeSchoolErrors,
  resetComponentStore,
  getStatesList,
})(CreateSchool);
