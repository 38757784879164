import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  subSpecialtiesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentSubSpecialty: null,
  loadingSubSpecialtiesList: true,
  loadingSubSpecialty: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const subSpecialtySlice = createSlice({
  name: "subSpecialty",
  initialState: initialState,
  reducers: {
    loadingSubSpecialtiesList(state) {
      return {
        ...state,
        loadingSubSpecialtiesList: true,
      };
    },
    subSpecialtySearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingSubSpecialtiesList: false,
      };
    },
    subSpecialtiesListUpdated(state, action) {
      return {
        ...state,
        subSpecialtiesList: {
          data:
            state.subSpecialtiesList && state.subSpecialtiesList.data
              ? [...state.subSpecialtiesList.data, ...action.payload.data]
              : action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingSubSpecialtiesList: false,
      };
    },
    subSpecialtyDetailsById(state, action) {
      return {
        ...state,
        currentSubSpecialty: action.payload,
        loadingSubSpecialty: false,
      };
    },
    resetSubSpecialty(state) {
      return {
        ...initialState,
      };
    },

    subSpecialtyError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingSubSpecialty: false,
        loadingSubSpecialtiesList: false,
      };
    },
    loadingOnSubSpecialtySubmit(state) {
      return {
        ...state,
        loadingSubSpecialty: true,
      };
    },
    subSpecialtyCreated(state) {
      return {
        ...state,
        loadingSubSpecialty: false,
      };
    },
    subSpecialtyUpdated(state, action) {
      return {
        ...state,
        subSpecialtiesList: {
          ...state.subSpecialtiesList,
          data: state.subSpecialtiesList.data.map((subSpecialty) =>
            subSpecialty._id === action.payload._id
              ? action.payload
              : subSpecialty
          ),
        },
      };
    },
    subSpecialtyDeleted(state, action) {
      const currentCount = state.subSpecialtiesList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.subSpecialtiesList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        subSpecialtiesList: {
          data: state.subSpecialtiesList.data.filter(
            (subSpecialty) => subSpecialty._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingSubSpecialtiesList: false,
      };
    },
  },
});
export const {
  loadingSubSpecialtiesList,
  subSpecialtySearchParametersUpdate,
  subSpecialtiesListUpdated,
  resetSubSpecialty,
  subSpecialtyError,
  loadingOnSubSpecialtySubmit,
  subSpecialtyCreated,
  subSpecialtyUpdated,
  subSpecialtyDetailsById,
  subSpecialtyDeleted,
} = subSpecialtySlice.actions;
export default subSpecialtySlice.reducer;
