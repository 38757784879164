import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { validateForm } from "utils/validation";

import Errors from "Notifications/Errors";

const EditProviderSamExclusionModal = ({
  provider_id,
  modal,
  setModal,
  isDisabled,
  setDisabled,
  formData,
  setFormData,
  errorList,
  setErrors,
  currentProviderSamExclusion,
  loadProviderSamExclusionFormData,
  getProviderSamExclusionById,
  saveProviderSamExclusion,
  removeProviderSamExclusionErrors,
}) => {
  const [submitting, setSubmitting] = React.useState(false);

  const {
    SAM_Exclusion,
    SAM_ExclusionDate,
    SAM_ExclusionEndDate,
    SAM_ExclusionType,
    SAM_ExclusionAgency,
    SAM_Verified,
    SAM_DateVerified,
  } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }
    const isVerified = e.target.checked;

    switch (e.target.name) {
      case "SAM_Exclusion":
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          SAM_ExclusionDate: isVerified ? SAM_ExclusionDate : "",
          SAM_ExclusionEndDate: isVerified ? SAM_ExclusionEndDate : "",
          SAM_ExclusionType: isVerified ? SAM_ExclusionType : "",
          SAM_ExclusionAgency: isVerified ? SAM_ExclusionAgency : "",
          SAM_Verified: isVerified ? SAM_Verified : false,
          SAM_DateVerified: isVerified ? SAM_DateVerified : "",
        });
        break;

      case "SAM_Verified":
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          SAM_DateVerified: isVerified ? SAM_DateVerified : "",
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeProviderSamExclusionErrors();

    const validationRules = [];

    const addValidationRule = (param, msg) => {
      validationRules.push({ param, msg });
    };

    if (SAM_Exclusion) {
      if (!SAM_ExclusionDate)
        addValidationRule(
          "SAM_ExclusionDate",
          "The SAM Exclusion Date is required"
        );
      if (!SAM_ExclusionEndDate)
        addValidationRule(
          "SAM_ExclusionEndDate",
          "The SAM Exclusion End Date is required"
        );
      if (!SAM_ExclusionType)
        addValidationRule(
          "SAM_ExclusionType",
          "The SAM Exclusion Type is required"
        );
      if (!SAM_ExclusionAgency)
        addValidationRule(
          "SAM_ExclusionAgency",
          "The SAM Exclusion Agency is required"
        );
      if (SAM_Verified)
        addValidationRule(
          "SAM_DateVerified",
          "The SAM Verified Date is required"
        );
    }
    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );
    setSubmitting(true);

    saveProviderSamExclusion(submitData, provider_id).then((res) => {
      if (!res.status) return;
      reset();
      getProviderSamExclusionById(provider_id);
    });
  };

  const reset = () => {
    setModal(false);
    setDisabled(!isDisabled);
    setSubmitting(false);
    removeProviderSamExclusionErrors();
    if (!currentProviderSamExclusion) return;
    loadProviderSamExclusionFormData(currentProviderSamExclusion);
  };

  return (
    <Modal show={modal} onHide={reset}>
      <Modal.Header closeButton>
        <h4>SAM Exclusion</h4>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => onSubmit(e)}>
          <Form.Group className="form-group">
            <Form.Label htmlFor="SAM_Exclusion">SAM Exclusion?</Form.Label>
            <Form.Check
              className={errorList.SAM_Exclusion ? "invalid" : ""}
              type="switch"
              id="SAM_Exclusion"
              name="SAM_Exclusion"
              checked={SAM_Exclusion}
              onChange={(e) => onChange(e)}
              label={SAM_Exclusion === true ? "Yes" : "No"}
              disabled={isDisabled}
            />
            <Errors current_key="SAM_Exclusion" key="SAM_Exclusion" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="SAM_ExclusionDate">
              SAM Exclusion Date
            </Form.Label>
            <Form.Control
              className={errorList.SAM_ExclusionDate ? "invalid" : ""}
              type="date"
              id="SAM_ExclusionDate"
              name="SAM_ExclusionDate"
              value={SAM_ExclusionDate}
              onChange={(e) => onChange(e)}
              disabled={isDisabled || !SAM_Exclusion}
            />
            <Errors current_key="SAM_ExclusionDate" key="SAM_ExclusionDate" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="SAM_ExclusionEndDate">
              SAM Exclusion End Date
            </Form.Label>
            <Form.Control
              className={errorList.SAM_ExclusionEndDate ? "invalid" : ""}
              type="date"
              id="SAM_ExclusionEndDate"
              name="SAM_ExclusionEndDate"
              value={SAM_ExclusionEndDate}
              onChange={(e) => onChange(e)}
              disabled={isDisabled || !SAM_Exclusion}
            />
            <Errors
              current_key="SAM_ExclusionEndDate"
              key="SAM_ExclusionEndDate"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="SAM_ExclusionType">Exclusion Type</Form.Label>
            <Form.Control
              className={errorList.SAM_ExclusionType ? "invalid" : ""}
              type="text"
              id="SAM_ExclusionType"
              name="SAM_ExclusionType"
              value={SAM_ExclusionType}
              onChange={(e) => onChange(e)}
              disabled={isDisabled || !SAM_Exclusion}
            />
            <Errors current_key="SAM_ExclusionType" key="SAM_ExclusionType" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="SAM_ExclusionAgency">
              Exclusion Agency
            </Form.Label>
            <Form.Control
              className={errorList.SAM_ExclusionAgency ? "invalid" : ""}
              type="text"
              id="SAM_ExclusionAgency"
              name="SAM_ExclusionAgency"
              value={SAM_ExclusionAgency}
              onChange={(e) => onChange(e)}
              disabled={isDisabled || !SAM_Exclusion}
            />
            <Errors
              current_key="SAM_ExclusionAgency"
              key="SAM_ExclusionAgency"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="SAM_Verified">Verified</Form.Label>
            <Form.Check
              className={errorList.SAM_Verified ? "invalid" : ""}
              type="switch"
              id="SAM_Verified"
              name="SAM_Verified"
              checked={SAM_Verified}
              onChange={(e) => onChange(e)}
              label={SAM_Verified === true ? "Yes" : "No"}
              disabled={isDisabled || !SAM_Exclusion}
            />
            <Errors current_key="SAM_Verified" key="SAM_Verified" />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label htmlFor="SAM_DateVerified">Date Verified</Form.Label>
            <Form.Control
              className={errorList.SAM_DateVerified ? "invalid" : ""}
              type="date"
              id="SAM_DateVerified"
              name="SAM_DateVerified"
              value={SAM_DateVerified}
              onChange={(e) => onChange(e)}
              disabled={isDisabled || !SAM_Verified || !SAM_Exclusion}
            />
            <Errors current_key="SAM_DateVerified" key="SAM_DateVerified" />
          </Form.Group>
          {isDisabled ? null : (
            <div className="float-end">
              <Button
                className="m-2"
                type="button"
                variant="primary"
                disabled={submitting}
                onClick={(e) => onSubmit(e)}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting}
              >
                Cancel
              </Button>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProviderSamExclusionModal;
