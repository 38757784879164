import React from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Errors from "Notifications/Errors";
import { validateForm } from "utils/validation";
import { openBase64File } from "utils/downloadHelper";

const EditProviderNpiModal = ({
  provider_id,
  modal,
  setModal,
  isDisabled,
  setDisabled,
  formData,
  setFormData,
  errorList,
  setErrors,
  currentProviderNpi,
  loadProviderNpiFormData,
  getProviderNpiById,
  saveProviderNpi,
  removeProviderNpiErrors,
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const [submittingPsv, setSubmittingPsv] = React.useState(false);
  const [isAdminVerified, setIsAdminVerified] = React.useState(false);

  const { NPI, EnumerationDate, Verified, DateVerified } = formData;

  const saveFormData = (formData) => {
    // Save formData
    saveProviderNpi(formData, provider_id).then((res) => {
      if (!res.status) return;
      reset();
      getProviderNpiById(provider_id);
    });
  };

  const onChange = (e) => {
    if (!e.target) {
      return;
    }
    const isVerified = e.target.checked;

    switch (e.target.name) {
      case "Verified":
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          DateVerified: isVerified ? DateVerified : "",
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeProviderNpiErrors();

    const validationRules = [];

    const addValidationRule = (param, msg) => {
      validationRules.push({ param, msg });
    };

    if (!NPI) addValidationRule("NPI", "The NPI Number is required");

    if (NPI) {
      if (!EnumerationDate)
        addValidationRule(
          "EnumerationDate",
          "The NPI Enumeration Date is required"
        );
      if (Verified)
        addValidationRule("DateVerified", "The NPI Verified Date is required");
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );
    setSubmitting(true);
    saveFormData(submitData);
  };

  const reset = () => {
    setModal(false);
    setDisabled(!isDisabled);
    setSubmitting(false);
    setSubmittingPsv(false);
    removeProviderNpiErrors();
    if (!currentProviderNpi) return;
    loadProviderNpiFormData(currentProviderNpi);
  };

  const initialPSV_Data = {
    npi_psv: "",
    enumerationDate_psv: "",
    psv_report: null,
    psv_verified_date: "",
  };

  const [PSV_Data, setPSV_Data] = React.useState(initialPSV_Data);
  const { npi_psv, enumerationDate_psv, psv_report, psv_verified_date } =
    PSV_Data;

  React.useEffect(() => {
    if (!currentProviderNpi) return;

    const admin_verify = currentProviderNpi.admin_verify
      ? currentProviderNpi.admin_verify
      : false;
    setIsAdminVerified(admin_verify);

    const { psv_result = {} } = currentProviderNpi;
    if (!psv_result || !psv_result["full_result"]) {
      setPSV_Data(initialPSV_Data);
      return;
    }

    const psv_full_result = psv_result["full_result"]
      ? psv_result["full_result"]
      : {};
    const psv_report = psv_result["reports"] ? psv_result["reports"][0] : null;
    const psv_verified_date = psv_result["createdAt"]
      ? moment(psv_result["createdAt"]).format("MM/DD/YYYY hh:mm a")
      : null;

    const npi_psv = psv_full_result["NPI_ID"] ? psv_full_result["NPI_ID"] : "";

    const enumerationDate_psv = psv_full_result["EnumerationDate"]
      ? psv_full_result["EnumerationDate"]
      : "";

    const PSV_Data = {
      npi_psv: npi_psv,
      enumerationDate_psv: enumerationDate_psv
        ? moment(enumerationDate_psv).format("MM/DD/YYYY").toString()
        : "",
    };

    if (psv_report) {
      PSV_Data["psv_report"] = psv_report;
    }

    if (psv_verified_date) {
      PSV_Data["psv_verified_date"] = psv_verified_date;
    }

    setPSV_Data((prev) => ({ ...prev, ...PSV_Data }));
  }, [currentProviderNpi]);

  const onDownloadClickHandler = () => {
    if (!psv_report) return;

    const base64 = psv_report["base64"];
    const file_title = psv_report["title"] ? psv_report["title"] : "Report.pdf";

    openBase64File(base64, file_title, "application/pdf");
  };

  const onAcceptPsvResults = (e) => {
    e.preventDefault();

    formData.NPI = npi_psv;
    formData.EnumerationDate = moment(enumerationDate_psv).format("MM/DD/YYYY");
    formData.Verified = true;
    formData.DateVerified = moment().format("MM/DD/YYYY");
    formData.admin_verify = true;

    const submitData = Object.fromEntries(
      Object.entries(formData).filter(
        ([key, value]) => value !== null && value !== undefined
      )
    );

    setSubmittingPsv(true);
    saveFormData(submitData);
  };

  const handleDateChange = (date, fieldName) => {
    if (date && moment(date).isValid()) {
      const formattedDate = moment(date).format("MM/DD/YYYY");
      setFormData({
        ...formData,
        [fieldName]: formattedDate,
      });
    } else {
      setFormData({
        ...formData,
        [fieldName]: "",
      });
    }
  };

  return (
    <Modal show={modal} onHide={reset} size="lg">
      <Modal.Header closeButton>
        <h4>NPI</h4>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => onSubmit(e)}>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="NPI">
                  NPI Number <span>*</span>
                </Form.Label>
                <Form.Control
                  className={errorList.NPI ? "invalid" : ""}
                  type="text"
                  id="NPI"
                  name="NPI"
                  maxLength="40"
                  value={NPI}
                  onChange={(e) => onChange(e)}
                />
                <Errors current_key="NPI" key="NPI" />
              </Form.Group>
            </Col>

            {psv_report ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="psv_report">PSV Report</Form.Label>

                  <div>
                    <Button
                      type="button"
                      variant="link"
                      size="sm"
                      onClick={() => onDownloadClickHandler()}
                    >
                      <FaEye /> View
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="EnumerationDate">
                  Enumeration Date
                </Form.Label>

                <DatePicker
                  showIcon
                  selected={
                    EnumerationDate &&
                    moment(EnumerationDate, "MM/DD/YYYY").isValid()
                      ? moment(EnumerationDate, "MM/DD/YYYY").toDate()
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "EnumerationDate")}
                  className={`form-control ${
                    formData.errorList?.EnumerationDate ? "invalid" : ""
                  }`}
                  dateFormat="MM/dd/yyyy"
                  id="EnumerationDate"
                  name="EnumerationDate"
                  placeholderText="mm/dd/yyyy"
                />
                <Errors current_key="EnumerationDate" key="EnumerationDate" />
              </Form.Group>
            </Col>

            {enumerationDate_psv && !isAdminVerified ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="enumerationDate_psv">
                    Enumeration Date PSV
                  </Form.Label>
                  <Form.Control
                    type="input"
                    id="enumerationDate_psv"
                    defaultValue={enumerationDate_psv}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="Verified">Verified</Form.Label>
                <Form.Check
                  className={errorList.Verified ? "invalid" : ""}
                  type="switch"
                  id="Verified"
                  name="Verified"
                  checked={Verified}
                  onChange={(e) => onChange(e)}
                  label={Verified === true ? "Yes" : "No"}
                  disabled={isDisabled || !NPI}
                />
                <Errors current_key="Verified" key="Verified" />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label htmlFor="DateVerified">Date Verified</Form.Label>

                <DatePicker
                  showIcon
                  selected={
                    DateVerified && moment(DateVerified, "MM/DD/YYYY").isValid()
                      ? moment(DateVerified, "MM/DD/YYYY").toDate()
                      : null
                  }
                  onChange={(date) => handleDateChange(date, "DateVerified")}
                  className={`form-control ${
                    formData.errorList?.DateVerified ? "invalid" : ""
                  }`}
                  dateFormat="MM/dd/yyyy"
                  id="DateVerified"
                  name="DateVerified"
                  placeholderText="mm/dd/yyyy"
                />
                <Errors current_key="DateVerified" key="DateVerified" />
              </Form.Group>
            </Col>
            {psv_verified_date ? (
              <Col md={6}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="psv_verified_date">
                    Date Verified PSV
                  </Form.Label>
                  <Form.Control
                    type="input"
                    id="psv_verified_date"
                    defaultValue={psv_verified_date}
                    readOnly
                  />
                </Form.Group>
              </Col>
            ) : null}
          </Row>

          {!isDisabled ? (
            <div className="float-end">
              {enumerationDate_psv && !isAdminVerified ? (
                <Button
                  className="m-2"
                  type="button"
                  variant="primary"
                  disabled={submitting || submittingPsv}
                  onClick={(e) => {
                    if (
                      window.confirm(
                        `Are you sure you want to accept Psv Results?`
                      )
                    ) {
                      onAcceptPsvResults(e);
                    }
                  }}
                >
                  {submittingPsv ? (
                    <>
                      <span className="spinner-border spinner-border-sm"></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Accept PSV Results</>
                  )}
                </Button>
              ) : null}
              <Button
                className="m-2"
                type="button"
                variant="primary"
                disabled={submitting || submittingPsv}
                onClick={(e) => onSubmit(e)}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting || submittingPsv}
              >
                Cancel
              </Button>
            </div>
          ) : null}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProviderNpiModal;
