import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  sourceList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentSource: null,
  statesList: [],
  loadingSourceList: true,
  loadingSource: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const sourceSlice = createSlice({
  name: "source",
  initialState: initialState,
  reducers: {
    loadingSourcesList(state) {
      return {
        ...state,
        loadingSourceList: true,
      };
    },
    sourceSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingSourceList: false,
      };
    },
    sourceListUpdated(state, action) {
      return {
        ...state,
        sourceList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingSourceList: false,
      };
    },
    sourceDetailsById(state, action) {
      return {
        ...state,
        currentSource: action.payload,
        loadingSource: false,
      };
    },
    resetSource(state) {
      return {
        ...initialState,
      };
    },

    sourceError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingSource: false,
        loadingSourceList: false,
      };
    },
    loadingOnSourceSubmit(state) {
      return {
        ...state,
        loadingSource: true,
      };
    },
    sourceCreated(state) {
      return {
        ...state,
        loadingSource: false,
      };
    },
    sourceUpdated(state, action) {
      return {
        ...state,
        sourceList: {
          ...state.sourceList,
          data: state.sourceList.data.map((source) =>
            source._id === action.payload._id ? action.payload : source
          ),
        },
      };
    },
    sourceDeleted(state, action) {
      const currentCount = state.sourceList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.sourceList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        sourceList: {
          data: state.sourceList.data.filter(
            (source) => source._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingSourceList: false,
      };
    },

    sourceStateList(state, action) {
      return {
        ...state,
        statesList: action.payload,
        loadingSource: false,
      };
    },
  },
});
export const {
  loadingSourcesList,
  sourceSearchParametersUpdate,
  sourceListUpdated,
  resetSource,
  sourceError,
  loadingOnSourceSubmit,
  sourceCreated,
  sourceUpdated,
  sourceDetailsById,
  sourceDeleted,
  sourceStateList,
} = sourceSlice.actions;
export default sourceSlice.reducer;
