import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingSpecialtiesList,
  specialtySearchParametersUpdate,
  specialtiesListUpdated,
  resetSpecialty,
  specialtyError,
  loadingOnSpecialtySubmit,
  specialtyCreated,
  specialtyUpdated,
  specialtyDetailsById,
  specialtyDeleted,
} from "reducers/specialtyReducer";

export const getSpecialtiesList = (appTokenParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = appTokenParams.query ? appTokenParams.query : "";
    appTokenParams.query = query;
    config.params = appTokenParams;

    dispatch(loadingSpecialtiesList());
    const res = await axios.get("/api/admin/specialties/list", config);

    dispatch(specialtySearchParametersUpdate(appTokenParams));

    dispatch(specialtiesListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          specialtyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getSpecialtyById = (specialty_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnSpecialtySubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/specialties/${specialty_id}`,
      config
    );

    dispatch(specialtyDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          specialtyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const createSpecialty = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.post("/api/admin/specialties", formData, config);

    if (res.data.status === true) {
      dispatch(specialtyCreated(res.data.response));
      dispatch(setAlert("Specialty Created.", "success"));
      navigate(`/admin/specialties`);

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(specialtyError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          specialtyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const updateSpecialty =
  (formData, navigate, specialty_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/admin/specialties/${specialty_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(specialtyUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(specialtyError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            specialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const deleteSpecialty = (specialty_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`/api/admin/specialties/${specialty_id}`, config);

    dispatch(specialtyDeleted(specialty_id));
    dispatch(setAlert("Specialty deleted", "success"));
  } catch (err) {
    err.response &&
      dispatch(
        specialtyError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// reset errors
export const removeSpecialtyErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetSpecialty());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(specialtyError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/specialties");
};
