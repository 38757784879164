import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  psvOrderInfo: "",
  psvOrderitemsList: [],
  loadingPsvOrderDetail: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const psvOrderDetailSlice = createSlice({
  name: "psvOrderDetail",
  initialState: initialState,
  reducers: {
    loadingPsvOrderDetail(state) {
      return {
        ...state,
        loadingPsvOrderDetail: true,
      };
    },
    psvOrderDetailUpdated(state, action) {
      return {
        ...state,
        psvOrderInfo: action.payload.orderInfo,
        psvOrderitemsList: action.payload.orderItems,
        loadingPsvOrderDetail: false,
      };
    },
    resetPsvOrderDetail(state) {
      return {
        ...initialState,
      };
    },

    psvOrderDetailError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingPsvOrderDetail: false,
      };
    },
  },
});
export const {
  loadingPsvOrderDetail,
  psvOrderDetailUpdated,
  resetPsvOrderDetail,
  psvOrderDetailError,
} = psvOrderDetailSlice.actions;
export default psvOrderDetailSlice.reducer;
