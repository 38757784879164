import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProviderSamExclusion: null,
  loadingProviderSamExclusion: false,
  error: {},
};

const providerSamExclusionSlice = createSlice({
  name: "providerSamExclusion",
  initialState: initialState,
  reducers: {
    resetProviderSamExclusion(state) {
      return {
        ...initialState,
      };
    },
    providerSamExclusionError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProviderSamExclusion: false,
      };
    },
    loadingOnProviderSamExclusionSubmit(state) {
      return {
        ...state,
        loadingProviderSamExclusion: true,
      };
    },
    providerSamExclusionUpdated(state, action) {
      return {
        ...state,

        currentProviderSamExclusion: action.payload,
        loadingProviderSamExclusion: false,
      };
    },
  },
});
export const {
  resetProviderSamExclusion,
  providerSamExclusionError,
  loadingOnProviderSamExclusionSubmit,
  providerSamExclusionUpdated,
} = providerSamExclusionSlice.actions;
export default providerSamExclusionSlice.reducer;
