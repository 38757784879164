import React from "react";
import { Row, Col } from "react-bootstrap";

import { daysUntil, expiringInDays } from "utils/helper";

const MedicareItems = ({ mcooItems, onChangeHandler, selectedItems }) => {
  return (
    <React.Fragment>
      {mcooItems.length ? (
        <div className="dt-each mb-2">
          {/* <div className="mb-1"><strong>Medicares</strong></div> */}
          {mcooItems.map((item, i) => {
            let unverifiedlabel = "";
            let unverifiedBtnClass = "";
            let label = "";
            let btnClass = "";
            if (!item.verified) {
              unverifiedlabel = "Unverified";
              unverifiedBtnClass = "bg-info";
            }

            if (item.Medicare_OptOutEndDate) {
              const expirationDate = new Date(item.Medicare_OptOutEndDate);
              const daysRemaining = daysUntil(expirationDate);
              label = expiringInDays(daysRemaining);
              if (daysRemaining < 0) {
                btnClass = "bg-danger";
              } else {
                btnClass = "bg-light-red";
              }
            }

            return (
              <Row key={i}>
                <Col>
                  <input
                    type="checkbox"
                    id={`psv_item_mcoo_${item.provider.toString()}_${i}`}
                    checked={
                      selectedItems.some(
                        (selectedItem) =>
                          selectedItem.ProviderID === item.provider &&
                          selectedItem.Medicares &&
                          selectedItem.Medicares.includes(item._id)
                      ) ||
                      (item.submitted_to_verify
                        ? item.submitted_to_verify
                        : false)
                    }
                    onChange={(e) => onChangeHandler(e, "Medicares", item)}
                    disabled={
                      item.submitted_to_verify
                        ? item.submitted_to_verify
                        : false
                    }
                  />
                  <label
                    htmlFor={`psv_item_mcoo_${item.provider.toString()}_${i}`}
                  >
                    <span className="ml-1">Medicare OptOut</span>{" "}
                    {unverifiedlabel ? (
                      <span className={`badge ${unverifiedBtnClass}`}>
                        {unverifiedlabel}
                      </span>
                    ) : null}{" "}
                    {label ? (
                      <span className={`badge ${btnClass}`}>{label}</span>
                    ) : null}{" "}
                    {item.submitted_to_verify ? (
                      <span className="badge bg-secondary">In Process</span>
                    ) : null}
                  </label>
                </Col>
              </Row>
            );
          })}
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default MedicareItems;
