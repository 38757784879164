//alert constants
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//reset constant
export const RESET = "RESET";

//errors constnats
export const SET_ERRORS_LIST = "SET_ERRORS_LIST";
export const REMOVE_ERRORS = "REMOVE_ERRORS";

//login/logout/register, auth, profile constnats
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOADING_ON_LOGIN_SUBMIT = "LOADING_ON_LOGIN_SUBMIT";

export const LOGOUT = "LOGOUT";

export const USER_PROFILE_LOADED = "USER_PROFILE_LOADED";
export const USER_LOADED = "USER_LOADED";

export const AUTH_ERROR = "AUTH_ERROR";

export const RESET_LINK_SUCCESS = "RESET_LINK_SUCCESS";
export const RESET_LINK_FAIL = "RESET_LINK_FAIL";

export const AUTH_TOKEN_REFRESH = "AUTH_TOKEN_REFRESH";
export const LOADING_ON_PASSWORD_RESET = "LOADING_ON_PASSWORD_RESET";
export const LOAD_AUTH_PAGE = "LOAD_AUTH_PAGE";
export const PENDING_ACTION_LOADED = "PENDING_ACTION_LOADED";

export const USER_CREATED = "USER_CREATED";
export const USER_ERROR = "USER_ERROR";
export const DELETE_USER = "DELETE_USER";
export const USER_UPDATED = "USER_UPDATED";
export const USER_LIST_UPDATED = "USER_LIST_UPDATED";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const USER_SEARCH_PARAMATERS_UPDATE = "USER_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const LOADING_ON_USER_SUBMIT = "LOADING_ON_USER_SUBMIT";
export const LOADING_USERS_LIST = "LOADING_USERS_LIST";
export const LOAD_PAGE = "LOAD_PAGE";

export const CLIENT_CREATED = "CLIENT_CREATED";
export const CLIENT_ERROR = "CLIENT_ERROR";
export const DELETE_CLIENT = "DELETE_USER";
export const CLIENT_UPDATED = "CLIENT_UPDATED";
export const CLIENT_LIST_UPDATED = "CLIENT_LIST_UPDATED";
export const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";
export const CLIENT_SEARCH_PARAMATERS_UPDATE =
  "CLIENT_SEARCH_PARAMATERS_UPDATE";
export const CHANGE_CLIENT_STATUS = "CHANGE_CLIENT_STATUS";
export const LOADING_ON_CLIENT_SUBMIT = "LOADING_ON_CLIENT_SUBMIT";
export const LOADING_CLIENT_LIST = "LOADING_CLIENT_LIST";
