import React from "react";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import CRMOverReview from "./CRMOverReview";
import CampaignRevenue from "./CampaignRevenue";
import TopUsers from "./TopUsers";
import WelcomeText from "./WelcomeText";

const AdminDashboard = () => {
  return (
    <React.Fragment>
      {/* <AppBreadcrumb pageTitle="Dashboard" /> */}

      <WelcomeText />

      {/* <CRMOverReview />
      <CampaignRevenue />
      <TopUsers /> */}
    </React.Fragment>
  );
};

export default AdminDashboard;
