import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  providerSpecialtiesList: {
    page: 1,
    data: [],
    count: 0,
  },
  specialtiesList: [],
  subSpecialtiesList: [],
  currentProviderSpecialty: null,
  loadingProviderSpecialtiesList: true,
  loadingProviderSpecialty: false,
  error: {},
  sortingParams: {
    limit: 10,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const providerSpecialtySlice = createSlice({
  name: "providerSpecialty",
  initialState: initialState,
  reducers: {
    loadingProviderSpecialtiesList(state) {
      return {
        ...state,
        loadingProviderSpecialtiesList: true,
      };
    },
    resetProviderSpecialty(state) {
      return {
        ...initialState,
      };
    },
    providerSpecialtySearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingProviderSpecialtiesList: false,
      };
    },
    loadingOnProviderSpecialtySubmit(state) {
      return {
        ...state,
        loadingProviderSpecialty: true,
      };
    },

    providerSpecialtyError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProviderSpecialty: false,
        loadingProviderSpecialtiesList: false,
      };
    },

    providerSpecialtiesListUpdated(state, action) {
      return {
        ...state,
        providerSpecialtiesList: {
          data:
            state.providerSpecialtiesList && state.providerSpecialtiesList.data
              ? [...state.providerSpecialtiesList.data, ...action.payload.data]
              : action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingProviderSpecialtiesList: false,
      };
    },

    providerSpecialtyDetailsById(state, action) {
      return {
        ...state,
        currentProviderSpecialty: action.payload,
        loadingProviderSpecialty: false,
      };
    },

    providerSpecialtyCreated(state) {
      return {
        ...state,
        loadingProviderSpecialty: false,
      };
    },

    providerSpecialtyUpdated(state, action) {
      return {
        ...state,
        providerSpecialtiesList: {
          ...state.providerSpecialtiesList,
          data: state.providerSpecialtiesList.data.map((providerSpecialty) =>
            providerSpecialty._id === action.payload._id
              ? action.payload
              : providerSpecialty
          ),
        },
      };
    },

    providerSpecialtyDeleted(state, action) {
      const currentCount = state.providerSpecialtiesList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.providerSpecialtiesList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        providerSpecialtiesList: {
          data: state.providerSpecialtiesList.data.filter(
            (providerSpecialty) => providerSpecialty._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingProviderSpecialtiesList: false,
      };
    },

    specialtiesList(state, action) {
      return {
        ...state,
        specialtiesList: action.payload,
        loadingProviderSpecialty: false,
      };
    },
    subSpecialtiesList(state, action) {
      return {
        ...state,
        subSpecialtiesList: action.payload,
        loadingProviderSpecialty: false,
      };
    },
  },
});
export const {
  loadingProviderSpecialtiesList,
  providerSpecialtySearchParametersUpdate,
  resetProviderSpecialty,
  providerSpecialtiesListUpdated,
  loadingOnProviderSpecialtySubmit,
  providerSpecialtyCreated,
  providerSpecialtyDetailsById,
  providerSpecialtyDeleted,
  providerSpecialtyError,
  providerSpecialtyUpdated,
  specialtiesList,
  subSpecialtiesList,
} = providerSpecialtySlice.actions;
export default providerSpecialtySlice.reducer;
