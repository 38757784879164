import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import Spinner from "views/Spinner";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import {
  updateCredential,
  cancelSave,
  setErrors,
  removeCredentialErrors,
  resetComponentStore,
  getCredentialById,
} from "actions/credentialActions";
import AppBreadcrumb from "../Layout/AppBreadCrumb";

const EditCredential = ({
  updateCredential,
  setErrors,
  removeCredentialErrors,
  loadingCredential,
  currentCredential,
  errorList,
  getCredentialById,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { credential_id } = useParams();

  const initialFormData = {
    code: "",
    description: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadCredentialFormData = (currentCredential) => {
    const { code, description } = currentCredential;

    const data = {
      code,
      description,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentCredential) return;

    loadCredentialFormData(currentCredential);
  }, [currentCredential]);

  const { code, description } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeCredentialErrors();

    let validationRules = [
      {
        param: "code",
        msg: "Code is required.",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    // console.log("Edit User Submit data", submitData);
    setSubmitting(true);
    updateCredential(submitData, navigate, credential_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useMemo(() => {
    if (!credential_id) return;

    resetComponentStore();

    getCredentialById(credential_id);
  }, []);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Edit Credential"
        crumbs={[
          { name: "Credentials", path: "/admin/credentials" },
          { name: "Edit Credential" },
        ]}
      />

      {loadingCredential ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <h4 className="header-title mb-3">Credential Information</h4>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="code">
                    Code<span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.code ? "invalid" : ""}
                    type="text"
                    id="code"
                    name="code"
                    maxLength="12"
                    value={code}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="code" key="code" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="description">Description</Form.Label>

                  <Form.Control
                    className={errorList.description ? "invalid" : ""}
                    as="textarea"
                    rows={5}
                    id="description"
                    name="description"
                    maxLength="160"
                    value={description}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  <Errors current_key="description" key="description" />
                </Form.Group>

                <br></br>

                <div className="float-end">
                  <Button
                    className="m-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditCredential.propTypes = {
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingCredential: state.credential.loadingCredential,
  currentCredential: state.credential.currentCredential,
});

export default connect(mapStateToProps, {
  updateCredential,
  cancelSave,
  setErrors,
  removeCredentialErrors,
  resetComponentStore,
  getCredentialById,
  resetComponentStore,
})(EditCredential);
