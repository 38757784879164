import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingOnProviderNpiSubmit,
  providerNpiError,
  providerNpiUpdated,
  resetProviderNpi,
} from "reducers/providerNpiReducer";

export const getProviderNpiById = (provider_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnProviderNpiSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/providers/${provider_id}/npi`,
      config
    );

    dispatch(providerNpiUpdated(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          providerNpiError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const saveProviderNpi = (formData, provider_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post(
      `/api/admin/providers/${provider_id}/npi/save`,
      formData,
      config
    );

    if (res.data.status === true) {
      await dispatch(providerNpiUpdated(res.data.response));
      dispatch(setAlert(res.data.message, "success"));
      return res.data ? res.data : { status: false };
    } else {
      const errors = res.data.errors;
      dispatch(setAlert(res.data?.message, "danger"));

      if (errors.length) {
        dispatch(providerNpiError());
        dispatch(setErrors(errors));
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        dispatch(
          providerNpiError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
        dispatch(
          setAlert(
            err.response.data.message || err.response.statusText,
            "danger"
          )
        );
      }
    }
  }
};

export const resetProviderNpiComponentStore = () => async (dispatch) => {
  await dispatch(resetProviderNpi());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(providerNpiError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const removeProviderNpiErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};
