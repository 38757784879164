import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProviderNpi: null,
  loadingProviderNpi: false,
  error: {},
};

const providerNpiSlice = createSlice({
  name: "providerNpi",
  initialState: initialState,
  reducers: {
    resetProviderNpi(state) {
      return {
        ...initialState,
      };
    },
    providerNpiError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProviderNpi: false,
      };
    },
    loadingOnProviderNpiSubmit(state) {
      return {
        ...state,
        loadingProviderNpi: true,
      };
    },
    providerNpiUpdated(state, action) {
      return {
        ...state,

        currentProviderNpi: action.payload,
        loadingProviderNpi: false,
      };
    },
  },
});
export const {
  resetProviderNpi,
  providerNpiError,
  loadingOnProviderNpiSubmit,
  providerNpiUpdated,
} = providerNpiSlice.actions;
export default providerNpiSlice.reducer;
