import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import "react-phone-number-input/style.css";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";
import extractNumber from "utils/extractNumber";

import {
  editUser,
  cancelSave,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
} from "actions/user";

const EditUser = ({
  editUser,
  errorList,
  currentUser,
  setErrors,
  removeUserErrors,
}) => {
  const navigate = useNavigate();
  const { user_id } = useParams();

  const initialFormData = {
    name: "",
    title: "",
    email: "",
    phone: "",
    ccode_phone: "+1",
    role: 1,
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadUserFormData = (currentUser) => {
    const { name, title, email, ccode, phone, role } = currentUser;

    const ccode_phone = ccode + phone;
    const data = {
      name,
      title,
      email,
      ccode,
      ccode_phone,
      role,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!currentUser) return;

    loadUserFormData(currentUser);
  }, [currentUser]);

  const { name, title, email, ccode, ccode_phone, role } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onPhoneChange = (val) => {
    const phone = extractNumber(val, ccode);
    setFormData((form) => ({
      ...form,
      phone: phone,
      ccode_phone: val || ccode,
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      iso: code || "US",
      ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeUserErrors();

    let validationRules = [
      {
        param: "name",
        msg: "Name is required.",
      },
      {
        param: "email",
        msg: "Email is required.",
      },
      {
        param: "email",
        msg: "Email is requuired.",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    // console.log("Edit User Submit data", submitData);
    setSubmitting(true);
    editUser(submitData, navigate, user_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    loadUserFormData(currentUser);
    toggleEdit();
  };

  const onRoleChangeCheck = (e, value) => {
    if (!e.target) return;

    setFormData({
      ...formData,
      role: value,
    });
  };

  return (
    <Card className="card-body">
      <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
        <div className="card-heading mb-3">
          <h4 className="header-title">User Information</h4>
          <Button
            variant="link"
            size="sm"
            className="float-end"
            onClick={toggleEdit}
          >
            {isDisabled ? (
              <span>
                <MdEdit title="Click to Edit" size={20} />
              </span>
            ) : (
              <span>
                <FaRegEye title="View mode" size={20} />
              </span>
            )}
          </Button>
        </div>

        <Form.Group className="form-group">
          <Form.Label htmlFor="name">
            Name <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.name ? "invalid" : ""}
            type="text"
            id="name"
            name="name"
            maxLength="100"
            value={name}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />
          <Errors current_key="name" key="name" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="title">
            Title <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.title ? "invalid" : ""}
            type="text"
            id="title"
            name="title"
            maxLength="100"
            value={title}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />
          <Errors current_key="title" key="title" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="email">
            Email <span>*</span>
          </Form.Label>

          <Form.Control
            className={errorList.email ? "invalid" : ""}
            type="mail"
            id="email"
            name="email"
            maxLength="100"
            value={email}
            onChange={(e) => onChange(e)}
            disabled={isDisabled}
          />

          <Errors current_key="email" key="email" />
        </Form.Group>

        <Form.Group className="form-group">
          <Form.Label htmlFor="phone">Phone</Form.Label>

          <PhoneInput
            className={errorList.phone ? "invalid" : ""}
            id="phone"
            name="phone"
            international={true}
            defaultCountry="US"
            countryOptionsOrder={["US", "|", "..."]}
            value={ccode_phone}
            onChange={onPhoneChange}
            addInternationalOption={false}
            onCountryChange={onCountryChange}
            disabled={isDisabled}
          />
          <Errors current_key="phone" key="phone" />
        </Form.Group>

        <Row>
          <Col sm="6" md="3">
            <Form.Group controlId="isAdmin">
              <Form.Check
                type="checkbox"
                id="isAdminCheckbox"
                label="Is Administrator?"
                onChange={(e) => onRoleChangeCheck(e, 2)}
                checked={role === 2}
                disabled={isDisabled}
              />
            </Form.Group>
          </Col>

          <Col sm="6" md="3">
            <Form.Group controlId="isUser">
              <Form.Check
                type="checkbox"
                id="isUser"
                label="Is User?"
                onChange={(e) => onRoleChangeCheck(e, 1)}
                checked={role === 1}
                disabled={isDisabled}
              />
            </Form.Group>
          </Col>

          <Errors current_key="role" key="role" />
          {role === 2 ? (
            <small className="c-red">
              Note: The Administrator can access all the client workspaces.
            </small>
          ) : (
            <div className="v-hidden">i</div>
          )}
        </Row>

        <div className="float-end">
          <Button
            className="m-2"
            type="submit"
            variant="primary"
            disabled={submitting || isDisabled}
          >
            {submitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {` Loading... `}
              </>
            ) : (
              <>Save</>
            )}
          </Button>
          <Button
            className="ml-2"
            type="reset"
            variant="danger"
            onClick={onClickCancel}
            disabled={submitting || isDisabled}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Card>
  );
};

EditUser.propTypes = {
  editUser: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingUser: state.user.loadingUser,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, {
  editUser,
  cancelSave,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
})(EditUser);
