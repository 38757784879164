import React from "react";
import { Link } from "react-router-dom";

const PsvNav = ({ current }) => {
  const links = [
    {
      link: "psv-ready",
      label: "PSV Ready",
    },
    {
      link: "psv-orders",
      label: "PSV Orders",
    },
  ];
  return (
    <div className="psv-internal-menu">
      <ul className="psv-nav">
        {links.map((l, i) => (
          <li
            className={`psv-nav-item ${current === l.link ? "active" : ""}`}
            key={i}
          >
            {current === l.link ? (
              <>{l.label}</>
            ) : (
              <Link to={`/admin/${l.link}`}>{l.label}</Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PsvNav;
