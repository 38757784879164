import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import { BiPlusMedical, BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";

import PiDataTable from "views/DataTable/PiDataTable";
import CreateProviderEducationModal from "./CreateProviderEducationModal";

import {
  deleteProviderEducation,
  getProviderEducationList,
  resetProviderEducationComponentStore,
  getProviderEducationById,
  updateProviderEducation,
} from "actions/providerEducationActions";

const ProviderEducationsList = ({
  providerEducationList: { data },
  getProviderEducationList,
  resetProviderEducationComponentStore,
  loadingProviderEducationList,
  deleteProviderEducation,
}) => {
  const navigate = useNavigate();
  const { provider_id } = useParams();

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [modal, setModal] = React.useState(false);
  const [educationID, setEducationID] = React.useState(null);

  const columns = [
    {
      name: "School",
      selector: (row) => row.school,
      sortable: false,
      width: "20%",
    },

    {
      name: "Degree",
      selector: (row) => row.degree,
      sortable: false,
      width: "10%",
    },

    {
      name: "Specialization",
      cell: (row) => row.specialization,
      sortable: false,
      width: "20%",
    },

    {
      name: "Year Awarded",
      cell: (row) => row.year_awarded,
      sortable: false,
      width: "15%",
    },
    {
      name: "Status",
      cell: (row) => (
        <React.Fragment>
          {row.verified ? "Verified" : "Not Verified"}
        </React.Fragment>
      ),
      sortable: false,
      width: "10%",
    },
    {
      name: "Date Verified",
      cell: (row) => (
        <React.Fragment>
          {row.date_verified
            ? moment(row.date_verified).format("MM/DD/YYYY")
            : "-"}
        </React.Fragment>
      ),
      sortable: false,
      width: "10%",
    },
    {
      name: "Actions",
      width: "15%",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="link"
            size="sm"
            onClick={() => onEditClickHandle(row._id)}
          >
            <FaEdit />
          </Button>

          <Button
            className="ml-1 c-red"
            size="sm"
            type="button"
            variant="link"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete: ${row.school}?`
                )
              ) {
                deleteProviderEducation(provider_id, row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  const onClickHandle = () => {
    setModal(true);
  };

  const onEditClickHandle = (provider_education_id) => {
    setModal(true);
    setEducationID(provider_education_id);
  };

  React.useEffect(() => {
    if (onlyOnce) {
      resetProviderEducationComponentStore();
      setOnce(false);
    }

    getProviderEducationList(clientParams, provider_id);
  }, [
    getProviderEducationList,
    clientParams,
    resetProviderEducationComponentStore,
  ]);

  return (
    <React.Fragment>
      <CreateProviderEducationModal
        modal={modal}
        setModal={setModal}
        educationID={educationID}
        setEducationID={setEducationID}
      />

      <Card className="mt-5">
        <Card.Body>
          <div className="card-heading">
            <h4 className="header-title">Education</h4>

            <Button
              variant="link"
              size="sm"
              className="float-end"
              onClick={() => onClickHandle()}
            >
              <BiPlusMedical title="Click to Add" size={20} />
            </Button>
          </div>

          {data.length || loadingProviderEducationList ? (
            <PiDataTable
              columns={columns}
              data={data}
              responsive
              striped={true}
              progressPending={loadingProviderEducationList}
              highlightOnHover
              persistTableHead={true}
            />
          ) : (
            <p align="center">
              There are no records to display. Click + icon to add education.
            </p>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ProviderEducationsList.propTypes = {
  getProviderEducationList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  providerEducationList: state.providerEducation.providerEducationList,
  loadingProviderEducationList:
    state.providerEducation.loadingProviderEducationList,
});

export default connect(mapStateToProps, {
  getProviderEducationList,
  resetProviderEducationComponentStore,
  deleteProviderEducation,
  getProviderEducationById,
  updateProviderEducation,
})(ProviderEducationsList);
