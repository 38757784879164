import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingPsvReadyList,
  psvReadySearchParametersUpdate,
  psvReadyListUpdated,
  resetPsvReady,
  psvReadyError,
  loadingOnPSVReadyOrdersSubmit,
  psvReadyOrdersCreated,
} from "reducers/psvReadyReducer";

export const getPsvReadyList = (searchParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = searchParams.query ? searchParams.query : "";
    searchParams.query = query;
    config.params = searchParams;

    dispatch(loadingPsvReadyList());
    const res = await axios.get("/api/admin/psv-ready/list", config);

    dispatch(psvReadySearchParametersUpdate(searchParams));

    dispatch(psvReadyListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          psvReadyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const createPSVReadyOrders = (formData) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnPSVReadyOrdersSubmit());

    const res = await axios.post(
      `/api/admin/psv-ready/orders/create`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(psvReadyOrdersCreated(res.data.response));
      dispatch(setAlert("PSV Orders Created.", "success"));

      return res.data;
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(psvReadyError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          psvReadyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// reset errors
export const removeProviderErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetPsvReady());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(psvReadyError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/psv-ready");
};
