import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProviderOigExclusion: null,
  loadingProviderOigExclusion: false,
  error: {},
};

const providerOigExclusionSlice = createSlice({
  name: "providerOigExclusion",
  initialState: initialState,
  reducers: {
    resetProviderOigExclusion(state) {
      return {
        ...initialState,
      };
    },
    providerOigExclusionError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProviderOigExclusion: false,
      };
    },
    loadingOnProviderOigExclusionSubmit(state) {
      return {
        ...state,
        loadingProviderOigExclusion: true,
      };
    },
    providerOigExclusionUpdated(state, action) {
      return {
        ...state,

        currentProviderOigExclusion: action.payload,
        loadingProviderOigExclusion: false,
      };
    },
  },
});
export const {
  resetProviderOigExclusion,
  providerOigExclusionError,
  loadingOnProviderOigExclusionSubmit,
  providerOigExclusionUpdated,
} = providerOigExclusionSlice.actions;
export default providerOigExclusionSlice.reducer;
