import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingProviderEducationsList,
  loadingOnProviderEducationSubmit,
  providerEducationCreated,
  providerEducationSearchParametersUpdate,
  providerEducationListUpdated,
  providerEducationDeleted,
  providerEducationDetailsById,
  providerEducationError,
  providerEducationUpdated,
  providerSchoolsList,
  providerDegreesList,
  resetProviderEducation,
} from "reducers/providerEducationReducer";

export const getDegreesList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    var res = await axios.get(`/api/admin/providers/degrees/list`, config);

    if (res.data.status === true) {
      await dispatch(providerDegreesList(res.data.response));
    }
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.log(err);
  }
};

export const getSchoolsList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };

    const res = await axios.get("/api/admin/providers/schools/list", config);

    if (res.data.status === true) {
      await dispatch(
        providerSchoolsList(
          res.data && res.data.response ? res.data.response : []
        )
      );
    }
    return res.data && res.data.response
      ? res.data.response
      : { status: false };
  } catch (err) {
    console.log(err);
  }
};

export const getProviderEducationList =
  (providerParams, provider_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const query = providerParams.query ? providerParams.query : "";
      providerParams.query = query;
      config.params = providerParams;

      dispatch(loadingProviderEducationsList());
      const res = await axios.get(
        `/api/admin/providers/${provider_id}/educations`,
        config
      );

      dispatch(providerEducationSearchParametersUpdate(providerParams));

      dispatch(providerEducationListUpdated(res.data.response[0]));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerEducationError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getProviderEducationById =
  (provider_id, provider_education_id) => async (dispatch) => {
    dispatch(removeErrors());
    dispatch(loadingOnProviderEducationSubmit());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `/api/admin/providers/${provider_id}/educations/${provider_education_id}`,
        config
      );

      dispatch(providerEducationDetailsById(res.data.response));
      return res.data ? res.data.response : { status: false };
    } catch (err) {
      console.log(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerEducationError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const createProviderEducation =
  (formData, navigate, provider_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnProviderEducationSubmit());

      const res = await axios.post(
        `/api/admin/providers/${provider_id}/educations/create`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(providerEducationCreated(res.data.response));
        dispatch(setAlert("Provider Education Created.", "success"));
        navigate(`/admin/providers/${provider_id}/provider-dashboard`);

        return res.data;
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(providerEducationError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerEducationError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateProviderEducation =
  (formData, navigate, provider_id, provider_education_id) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/admin/providers/${provider_id}/educations/${provider_education_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(providerEducationUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        dispatch(setAlert(res.data?.message, "danger"));

        if (errors.length) {
          dispatch(providerEducationError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            providerEducationError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const deleteProviderEducation =
  (provider_id, provider_education_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.delete(
        `/api/admin/providers/${provider_id}/educations/${provider_education_id}`,
        config
      );

      dispatch(providerEducationDeleted(provider_education_id));
      dispatch(setAlert("Provider Education deleted", "success"));
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch(
          providerEducationError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
    }
  };

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(providerEducationError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// reset errors
export const removeProviderEducationErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetProviderEducationComponentStore = () => async (dispatch) => {
  await dispatch(resetProviderEducation());
};
