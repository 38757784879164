import axios from "axios";
import { setAlert } from "./alert";
import { logout } from "./auth";

import {
  loadingDashboardClientsList,
  dashboardClientListUpdated,
  dashboardError,
} from "reducers/dashboardReducer";

export const getDashboardClientsList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingDashboardClientsList());
    const res = await axios.get("/api/admin/dashboard/clients-list", config);

    dispatch(dashboardClientListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};
