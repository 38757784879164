import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentProviderMedicare: null,
  loadingProviderMedicare: false,
  error: {},
};

const providerMedicareSlice = createSlice({
  name: "providerMedicare",
  initialState: initialState,
  reducers: {
    resetProviderMedicare(state) {
      return {
        ...initialState,
      };
    },
    providerMedicareError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProviderMedicare: false,
      };
    },
    loadingOnProviderMedicareSubmit(state) {
      return {
        ...state,
        loadingProviderMedicare: true,
      };
    },
    providerMedicareUpdated(state, action) {
      return {
        ...state,

        currentProviderMedicare: action.payload,
        loadingProviderMedicare: false,
      };
    },
  },
});
export const {
  resetProviderMedicare,
  providerMedicareError,
  loadingOnProviderMedicareSubmit,
  providerMedicareUpdated,
} = providerMedicareSlice.actions;
export default providerMedicareSlice.reducer;
