import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingOnProviderCredentialSubmit,
  loadingProviderCredentialsList,
  providerCredentialCreated,
  providerCredentialDeleted,
  providerCredentialDetailsById,
  providerCredentialError,
  providerCredentialListUpdated,
  providerCredentialSearchParametersUpdate,
  providerCredentialStateList,
  providerCredentialUpdated,
  resetProviderCredential,
} from "reducers/providerCredentialReducer";

export const getCredentialsList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get("/api/admin/sources/credentials-list", config);

    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          providerCredentialError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getStatesList = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    var res = await axios.get(`/api/common/state/list`, config);

    if (res.data.status === true) {
      await dispatch(providerCredentialStateList(res.data.response));
    }
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.log(err);
  }
};

export const getProviderCredentialList =
  (providerParams, provider_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const query = providerParams.query ? providerParams.query : "";
      providerParams.query = query;
      config.params = providerParams;

      dispatch(loadingProviderCredentialsList());
      const res = await axios.get(
        `/api/admin/providers/${provider_id}/credentials`,
        config
      );

      dispatch(providerCredentialSearchParametersUpdate(providerParams));

      dispatch(providerCredentialListUpdated(res.data.response[0]));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerCredentialError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getProviderCredentialById =
  (provider_id, provider_credential_id) => async (dispatch) => {
    dispatch(removeErrors());
    dispatch(loadingOnProviderCredentialSubmit());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.get(
        `/api/admin/providers/${provider_id}/credentials/${provider_credential_id}`,
        config
      );

      dispatch(providerCredentialDetailsById(res.data.response));
      return res.data ? res.data.response : { status: false };
    } catch (err) {
      console.log(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerCredentialError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const createProviderCredential =
  (formData, navigate, provider_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnProviderCredentialSubmit());

      const res = await axios.post(
        `/api/admin/providers/${provider_id}/credentials/create`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(providerCredentialCreated(res.data.response));
        dispatch(setAlert("Provider Credential Created.", "success"));
        navigate(`/admin/providers/${provider_id}/provider-dashboard`);

        return res.data;
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(providerCredentialError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            providerCredentialError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateProviderCredential =
  (formData, navigate, provider_id, provider_credential_id) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.put(
        `/api/admin/providers/${provider_id}/credentials/${provider_credential_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(providerCredentialUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        dispatch(setAlert(res.data?.message, "danger"));

        if (errors.length) {
          dispatch(providerCredentialError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            providerCredentialError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const deleteProviderCredential =
  (provider_id, provider_credential_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.delete(
        `/api/admin/providers/${provider_id}/credentials/${provider_credential_id}`,
        config
      );

      dispatch(providerCredentialDeleted(provider_credential_id));
      dispatch(setAlert("Provider Credential deleted", "success"));
    } catch (err) {
      console.log(err);
      err.response &&
        dispatch(
          providerCredentialError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
    }
  };

export const resetProviderCredentialComponentStore = () => async (dispatch) => {
  await dispatch(resetProviderCredential());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(providerCredentialError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// reset errors
export const removeProviderCredentialErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};
