import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { isAdmin } from "utils/helper";
import { MdEdit } from "react-icons/md";
import Spinner from "views/Spinner";

import {
  setErrors,
  removeProviderMedicareErrors,
  saveProviderMedicare,
  getProviderMedicareById,
  resetProviderMedicareComponentStore,
} from "actions/providerMedicareActions";
import EditProviderMedicareModal from "./EditProviderMedicareModal";
import NoData from "./NoData";

const ProviderMedicare = ({
  user,
  setErrors,
  errorList,
  removeProviderMedicareErrors,
  saveProviderMedicare,
  getProviderMedicareById,
  currentProviderMedicare,
  loadingProviderMedicare,
  resetProviderMedicareComponentStore,
}) => {
  const { provider_id } = useParams();
  const [modal, setModal] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);

  const initialFormData = {
    Medicare_OptOut: false,
    Medicare_OptOutDate: "",
    Medicare_OptOutEndDate: "",
    Medicare_OptOutVerified: false,
    Medicare_OptOutDateVerified: "",
  };

  const [formData, setFormData] = React.useState(initialFormData);

  const {
    Medicare_OptOut,
    Medicare_OptOutDate,
    Medicare_OptOutEndDate,
    Medicare_OptOutVerified,
    Medicare_OptOutDateVerified,
  } = formData;

  const loadProviderMedicareFormData = (currentProviderMedicare) => {
    const {
      Medicare_OptOut,
      Medicare_OptOutDate,
      Medicare_OptOutEndDate,
      Medicare_OptOutVerified,
      Medicare_OptOutDateVerified,
    } = currentProviderMedicare;

    const data = {
      Medicare_OptOut,
      Medicare_OptOutDate: Medicare_OptOutDate
        ? moment(Medicare_OptOutDate).format("MM/DD/YYYY")
        : "",
      Medicare_OptOutEndDate: Medicare_OptOutEndDate
        ? moment(Medicare_OptOutEndDate).format("MM/DD/YYYY")
        : "",
      Medicare_OptOutVerified,
      Medicare_OptOutDateVerified: Medicare_OptOutDateVerified
        ? moment(Medicare_OptOutDateVerified).format("MM/DD/YYYY")
        : "",
    };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!provider_id) return;
    resetProviderMedicareComponentStore();
    getProviderMedicareById(provider_id);
  }, [provider_id, getProviderMedicareById]);

  React.useEffect(() => {
    if (!currentProviderMedicare) {
      setFormData(initialFormData);
      return;
    }
    // console.log({ currentProviderMedicare });
    loadProviderMedicareFormData(currentProviderMedicare);
  }, [currentProviderMedicare]);

  const onEditClickHandler = () => {
    setModal(true);
    setDisabled(!isDisabled);
  };

  return (
    <React.Fragment>
      <EditProviderMedicareModal
        provider_id={provider_id}
        modal={modal}
        setModal={setModal}
        isDisabled={isDisabled}
        setDisabled={setDisabled}
        formData={formData}
        setFormData={setFormData}
        errorList={errorList}
        setErrors={setErrors}
        currentProviderMedicare={currentProviderMedicare}
        loadProviderMedicareFormData={loadProviderMedicareFormData}
        getProviderMedicareById={getProviderMedicareById}
        saveProviderMedicare={saveProviderMedicare}
        removeProviderMedicareErrors={removeProviderMedicareErrors}
      />
      <Col xs="12" sm="6" lg="6">
        <Card className="mt-5">
          <Card.Body className="misc-info-card">
            {" "}
            <div className="card-heading mb-3">
              {" "}
              <h4 className="header-title">Opt Out of Medicare</h4>
              {isAdmin(user) ? (
                <Button
                  variant="link"
                  size="sm"
                  className="float-end"
                  onClick={() => onEditClickHandler()}
                >
                  <MdEdit title="Click to Edit" size={20} />
                </Button>
              ) : null}
            </div>
            {loadingProviderMedicare ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {Medicare_OptOut ? (
                  <Row>
                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>Opt Out of Medicare?</div>
                      <div className="fw-bold">
                        {Medicare_OptOut ? "Yes" : "No"}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>Opt Out Date</div>
                      <div className="fw-bold">
                        {moment(Medicare_OptOutDate).format("MM/DD/YYYY")}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>Opt Out End Date</div>
                      <div className="fw-bold">
                        {moment(Medicare_OptOutEndDate).format("MM/DD/YYYY")}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>Status</div>
                      <div className="fw-bold">
                        {Medicare_OptOutVerified ? "Verified" : "Not Verified"}
                      </div>
                    </Col>

                    {Medicare_OptOutVerified ? (
                      <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                        <div>Date Verified</div>
                        <div className="fw-bold">
                          {moment(Medicare_OptOutDateVerified).format(
                            "MM/DD/YYYY"
                          )}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                ) : (
                  <NoData loggedUser={user} />
                )}
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

ProviderMedicare.propTypes = {
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  currentProviderMedicare: state.providerMedicare.currentProviderMedicare,
  loadingProviderMedicare: state.providerMedicare.loadingProviderMedicare,
});

export default connect(mapStateToProps, {
  setErrors,
  removeProviderMedicareErrors,
  getProviderMedicareById,
  saveProviderMedicare,
  resetProviderMedicareComponentStore,
})(ProviderMedicare);
