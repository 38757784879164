import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import Spinner from "views/Spinner";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

import {
  updateTaxonomy,
  cancelSave,
  setErrors,
  removeTaxonomyErrors,
  resetComponentStore,
  getTaxonomyById,
} from "actions/taxonomyActions";

import AppBreadcrumb from "../Layout/AppBreadCrumb";

const EditTaxonomies = ({
  updateTaxonomy,
  setErrors,
  removeTaxonomyErrors,
  loadingTaxonomy,
  currentTaxonomy,
  errorList,
  getTaxonomyById,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { taxonomy_id } = useParams();

  const initialFormData = {
    providerTaxonomyCode: "",
    providerTaxonomyDesc: "",
    medicareSpecialtyCode: "",
    medicareProviderSupplierType: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadTaxonomyFormData = (currentTaxonomy) => {
    const {
      providerTaxonomyCode,
      providerTaxonomyDesc,
      medicareSpecialtyCode,
      medicareProviderSupplierType,
    } = currentTaxonomy;

    const data = {
      providerTaxonomyCode,
      providerTaxonomyDesc,
      medicareSpecialtyCode,
      medicareProviderSupplierType,
    };
    setFormData((formData) => ({ ...formData, ...data }));
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    toggleEdit();
  };

  React.useEffect(() => {
    if (!currentTaxonomy) return;

    loadTaxonomyFormData(currentTaxonomy);
  }, [currentTaxonomy]);

  const {
    providerTaxonomyCode,
    providerTaxonomyDesc,
    medicareSpecialtyCode,
    medicareProviderSupplierType,
  } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeTaxonomyErrors();

    let validationRules = [
      {
        param: "providerTaxonomyCode",
        msg: "The Provider Taxonomy Code is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    updateTaxonomy(submitData, navigate, taxonomy_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  React.useMemo(() => {
    if (!taxonomy_id) return;

    resetComponentStore();

    getTaxonomyById(taxonomy_id);
  }, []);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Edit Taxonomy"
        crumbs={[
          { name: "Taxonomies", path: "/admin/taxonomies" },
          {
            name: providerTaxonomyCode ? providerTaxonomyCode : "Edit Taxonomy",
          },
        ]}
      />

      {loadingTaxonomy ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading mb-3">
                  <h4 className="header-title mb-3">Taxonomy Information</h4>

                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </div>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="providerTaxonomyCode">
                    Provider Taxonomy Code <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.providerTaxonomyCode ? "invalid" : ""}
                    type="text"
                    id="providerTaxonomyCode"
                    name="providerTaxonomyCode"
                    maxLength="50"
                    value={providerTaxonomyCode}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    required
                  />
                  <Errors
                    current_key="providerTaxonomyCode"
                    key="providerTaxonomyCode"
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="providerTaxonomyDesc">
                    Description
                  </Form.Label>

                  <Form.Control
                    className={errorList.providerTaxonomyDesc ? "invalid" : ""}
                    as="textarea"
                    rows={5}
                    id="providerTaxonomyDesc"
                    name="providerTaxonomyDesc"
                    maxLength="4000"
                    value={providerTaxonomyDesc}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  <Errors
                    current_key="providerTaxonomyDesc"
                    key="providerTaxonomyDesc"
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="medicareSpecialtyCode">
                    Medicare Specialty Code
                  </Form.Label>

                  <Form.Control
                    className={errorList.medicareSpecialtyCode ? "invalid" : ""}
                    as="textarea"
                    rows={2}
                    id="medicareSpecialtyCode"
                    name="medicareSpecialtyCode"
                    maxLength="50"
                    value={medicareSpecialtyCode}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  <Errors
                    current_key="medicareSpecialtyCode"
                    key="medicareSpecialtyCode"
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="medicareProviderSupplierType">
                    Medicare Provider Supplier Type
                  </Form.Label>

                  <Form.Control
                    className={
                      errorList.medicareProviderSupplierType ? "invalid" : ""
                    }
                    as="textarea"
                    rows={4}
                    id="medicareProviderSupplierType"
                    name="medicareProviderSupplierType"
                    maxLength="255"
                    value={medicareProviderSupplierType}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />

                  <Errors
                    current_key="medicareProviderSupplierType"
                    key="medicareProviderSupplierType"
                  />
                </Form.Group>

                <br></br>

                <div className="float-end">
                  <Button
                    className="m-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickHandel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditTaxonomies.propTypes = {
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingTaxonomy: state.taxonomy.loadingTaxonomy,
  currentTaxonomy: state.taxonomy.currentTaxonomy,
});

export default connect(mapStateToProps, {
  updateTaxonomy,
  cancelSave,
  setErrors,
  removeTaxonomyErrors,
  resetComponentStore,
  getTaxonomyById,
  resetComponentStore,
})(EditTaxonomies);
