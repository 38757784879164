import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import validator from "validator";
import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";

import {
  getSchoolById,
  updateSchool,
  setErrors,
  removeSchoolErrors,
  resetComponentStore,
  getStatesList,
} from "actions/schoolActions";

const EditSchool = ({
  getSchoolById,
  currentSchool,
  updateSchool,
  errorList,
  loadingSchool,
  setErrors,
  removeSchoolErrors,
  resetComponentStore,
  getStatesList,
}) => {
  const navigate = useNavigate();
  const { school_id } = useParams();

  const initialFormData = {
    name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zipcode: "",
    url: "",
    phone: "",
    status: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [activatingSave, setActivatingSave] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);

  const [statesList, setStatesList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);

  const toggleEdit = () => setDisabled(!isDisabled);

  const {
    name,
    address_line1,
    address_line2,
    city,
    state,
    zipcode,
    url,
    phone,
  } = formData;

  const handleStateSelect = (selectedRole) => {
    setSelectedState({
      value: selectedRole.value,
      label: selectedRole.label,
    });

    setFormData({
      ...formData,
      state: selectedRole ? selectedRole.value : null,
    });
  };

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeSchoolErrors();

    let validationRules = [
      {
        param: "name",
        msg: "The Name is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (
      formData.url &&
      !validator.isURL(formData.url, { require_protocol: true })
    ) {
      errors.push({
        param: "url",
        msg: "The URL is not valid",
      });
    }

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (formData[i] === null || formData[i] === undefined) continue;
      submitData[i] = formData[i];
    }

    if (submitData && submitData.status === 3 && activatingSave) {
      setLoading(true);
      submitData.status = 2;

      updateSchool(submitData, currentSchool._id, navigate).then((res) => {
        setLoading(false);
        setActivatingSave(false);
        toggleEdit();
        if (!res.status) return;
      });
    } else {
      setSubmitting(true);
      updateSchool(submitData, currentSchool._id, navigate).then((res) => {
        setSubmitting(false);
        toggleEdit();
        if (!res.status) return;
      });
    }
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    loadSchoolFormData(currentSchool);
    toggleEdit();
  };

  const loadSchoolFormData = (currentSchool) => {
    if (!currentSchool) return;

    const {
      name,
      address_line1,
      address_line2,
      city,
      state,
      zipcode,
      url,
      phone,
      status,
    } = currentSchool;

    setFormData((formData) => ({
      ...formData,
      name,
      address_line1,
      address_line2,
      city,
      state,
      zipcode,
      url,
      phone,
      status,
    }));
  };

  React.useEffect(() => {
    if (!school_id) return;

    resetComponentStore();

    getSchoolById(school_id);
  }, [getSchoolById]);

  React.useEffect(() => {
    if (!currentSchool) return;

    loadSchoolFormData(currentSchool);
  }, [currentSchool]);

  React.useEffect(() => {
    if (!school_id) return;
    if (!state) return;

    const filteredState = statesList.find(
      (each) => each.abbreviation === state
    );

    if (filteredState) {
      setSelectedState({
        value: filteredState.abbreviation,
        label: `${filteredState.name} (${filteredState.abbreviation})`,
      });
    }
  }, [state, school_id, statesList]);

  React.useMemo(async () => {
    const stateListData = await getStatesList();

    setStatesList(stateListData);
  }, [getStatesList]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle={!loadingSchool && currentSchool ? `Edit School` : ""}
        crumbs={[
          { name: "Schools", path: "/admin/schools" },
          {
            name:
              !loadingSchool && currentSchool ? `Edit School` : "Loading...",
          },
        ]}
      />

      {loadingSchool ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title mb-3">
                  School Information
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="name">
                    Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.name ? "invalid" : ""}
                    type="text"
                    id="name"
                    name="name"
                    maxLength="120"
                    value={name}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="name" key="name" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="address_line1">
                    Address Line 1
                  </Form.Label>
                  <Form.Control
                    className={errorList.address_line1 ? "invalid" : ""}
                    type="text"
                    id="address_line1"
                    name="address_line1"
                    maxLength="80"
                    value={address_line1}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="address_line1" key="address_line1" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="address_line2">
                    Address Line 2
                  </Form.Label>
                  <Form.Control
                    className={errorList.address_line2 ? "invalid" : ""}
                    type="text"
                    id="address_line2"
                    name="address_line2"
                    maxLength="80"
                    value={address_line2}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="address_line2" key="address_line2" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="city">City</Form.Label>
                  <Form.Control
                    className={errorList.city ? "invalid" : ""}
                    type="text"
                    id="city"
                    name="city"
                    maxLength="40"
                    value={city}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="city" key="city" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="state">State</Form.Label>
                  <Select
                    id="state"
                    name="state"
                    options={statesList.map((state) => ({
                      value: state.abbreviation,
                      label: `${state.name} (${state.abbreviation})`,
                    }))}
                    value={selectedState}
                    onChange={handleStateSelect}
                    invalid={errorList.state ? true : false}
                    isDisabled={isDisabled}
                  />
                  <Errors current_key="state" key="state" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="zipcode">Zip Code</Form.Label>
                  <Form.Control
                    className={errorList.zipcode ? "invalid" : ""}
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    maxLength="10"
                    value={zipcode}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="zipcode" key="zipcode" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="url">URL</Form.Label>
                  <Form.Control
                    className={errorList.url ? "invalid" : ""}
                    type="text"
                    id="url"
                    name="url"
                    value={url}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="url" key="url" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="phone">Phone</Form.Label>
                  <Form.Control
                    className={errorList.phone ? "invalid" : ""}
                    type="text"
                    id="phone"
                    name="phone"
                    maxLength="20"
                    value={phone}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="phone" key="phone" />
                </Form.Group>

                <br></br>

                <div className="float-end">
                  {formData.status === 3 ? (
                    <Button
                      className="ml-2"
                      type="submit"
                      variant="primary"
                      onClick={() => {
                        setActivatingSave(true);
                      }}
                      disabled={loading || isDisabled}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {` Loading... `}
                        </>
                      ) : (
                        <>Activate & Save</>
                      )}
                    </Button>
                  ) : null}

                  <Button
                    className="m-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickCancel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditSchool.propTypes = {
  getSchoolById: PropTypes.func.isRequired,
  updateSchool: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingSchool: state.school.loadingSchool,
  currentSchool: state.school.currentSchool,
});

export default connect(mapStateToProps, {
  getSchoolById,
  updateSchool,
  setErrors,
  removeSchoolErrors,
  resetComponentStore,
  getStatesList,
})(EditSchool);
