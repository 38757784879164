import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  psvOrdersList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentPsvOrder: null,
  loadingPsvOrdersList: true,
  loadingPsvOrder: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const psvOrderSlice = createSlice({
  name: "psvOrder",
  initialState: initialState,
  reducers: {
    loadingPsvOrdersList(state) {
      return {
        ...state,
        loadingPsvOrdersList: true,
      };
    },
    psvOrderSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingPsvOrdersList: false,
      };
    },
    psvOrderListUpdated(state, action) {
      return {
        ...state,
        psvOrdersList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingPsvOrdersList: false,
      };
    },
    psvOrderDetailsById(state, action) {
      return {
        ...state,
        currentPsvOrder: action.payload,
        loadingPsvOrder: false,
      };
    },
    resetPsvOrder(state) {
      return {
        ...initialState,
      };
    },

    psvOrderError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingPsvOrder: false,
        loadingPsvOrdersList: false,
      };
    },
    loadingOnPsvOrderSubmit(state) {
      return {
        ...state,
        loadingPsvOrder: true,
      };
    },
    psvOrderUpdated(state, action) {
      return {
        ...state,
        psvOrdersList: {
          ...state.psvOrdersList,
          data: state.psvOrdersList.data.map((psvOrder) =>
            psvOrder._id === action.payload._id ? action.payload : psvOrder
          ),
        },
      };
    },
    psvOrderDeleted(state, action) {
      const currentCount = state.psvOrdersList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.psvOrdersList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        psvOrdersList: {
          data: state.psvOrdersList.data.filter(
            (psvOrder) => psvOrder._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingPsvOrdersList: false,
      };
    },
  },
});
export const {
  loadingPsvOrdersList,
  psvOrderSearchParametersUpdate,
  psvOrderListUpdated,
  resetPsvOrder,
  psvOrderError,
  loadingOnPsvOrderSubmit,
  psvOrderUpdated,
  psvOrderDetailsById,
  psvOrderDeleted,
} = psvOrderSlice.actions;
export default psvOrderSlice.reducer;
