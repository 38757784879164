import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { BiPlusMedical, BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";

import PiDataTable from "views/DataTable/PiDataTable";
import CreateProviderSpecialtyModal from "./CreateProviderSpecialtyModal";

import {
  getProviderSpecialtiesList,
  resetProviderSpecialtyComponentStore,
  deleteProviderSpecialty,
} from "actions/providerSpecialtyActions";

const ProviderSpecialtiesList = ({
  providerSpecialtiesList: { data, count },
  getProviderSpecialtiesList,
  deleteProviderSpecialty,
  resetProviderSpecialtyComponentStore,
  sortingParams,
  loadingProviderSpecialtiesList,
}) => {
  const { provider_id } = useParams();

  const initialSortingParams = {
    limit: 10,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [onlyOnce, setOnce] = React.useState(true);

  const [isLoadingMore, setLoadingMore] = React.useState(false);

  const [specialtyID, setSpecialtyID] = React.useState(null);

  const [modal, setModal] = React.useState(false);

  const columns = [
    {
      name: "Specialty",
      selector: (row) => row.specialty,
      sortable: false,
      width: "15%",
    },
    {
      name: "Sub Specialty",
      selector: (row) =>
        row.sub_specialties.length
          ? row.sub_specialties.map((each) => (
              <div className="mb-1">{each}</div>
            ))
          : "-",
      sortable: false,
      width: "15%",
    },
    {
      name: "Date Originally Issued",
      cell: (row) =>
        row.date_originally_issued
          ? moment(row.date_originally_issued).format("MM/DD/YYYY")
          : "-",
      sortable: false,
      width: "15%",
    },
    {
      name: "Date Last Issued",
      cell: (row) =>
        row.date_last_issued
          ? moment(row.date_last_issued).format("MM/DD/YYYY")
          : "-",
      sortable: false,
      width: "10%",
    },
    {
      name: "Date Expires",
      cell: (row) =>
        row.date_expires ? moment(row.date_expires).format("MM/DD/YYYY") : "-",
      sortable: false,
      width: "10%",
    },
    {
      name: "Status",
      cell: (row) => (
        <React.Fragment>
          {row.verified ? "Verified" : "Not Verified"}
        </React.Fragment>
      ),
      sortable: false,
      width: "10%",
    },
    {
      name: "Date Verified",
      cell: (row) => (
        <React.Fragment>
          {row.date_verified
            ? moment(row.date_verified).format("MM/DD/YYYY")
            : "-"}
        </React.Fragment>
      ),
      sortable: false,
      width: "10%",
    },
    {
      name: "Actions",
      width: "15%",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="link"
            size="sm"
            onClick={() => onEditClickHandle(row._id)}
          >
            <FaEdit />
          </Button>

          <Button
            className="ml-1 c-red"
            size="sm"
            type="button"
            variant="link"
            onClick={(e) => {
              if (
                window.confirm(
                  `Are you sure you want to delete: ${row.specialty}?`
                )
              ) {
                deleteProviderSpecialty(provider_id, row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  const onClickHandle = () => {
    setModal(true);
  };

  const handleLoadmore = () => {
    setLoadingMore(true);
    setClientParams({
      ...clientParams,
      page: page + 1,
    });
  };

  const onEditClickHandle = (provider_specialty_id) => {
    setModal(true);
    setSpecialtyID(provider_specialty_id);
  };

  React.useEffect(() => {
    if (onlyOnce) {
      resetProviderSpecialtyComponentStore();
      setOnce(false);
    }

    getProviderSpecialtiesList(clientParams, provider_id)
      .then((res) => {
        setLoadingMore(false);
      })
      .catch((error) => {
        setLoadingMore(false);
      });
  }, [
    getProviderSpecialtiesList,
    clientParams,
    provider_id,
    resetProviderSpecialtyComponentStore,
  ]);

  return (
    <React.Fragment>
      <CreateProviderSpecialtyModal
        modal={modal}
        setModal={setModal}
        specialtyID={specialtyID}
        setSpecialtyID={setSpecialtyID}
      />

      <Card className="mt-5">
        <Card.Body>
          <div className="card-heading">
            <h4 className="header-title">Specialties</h4>

            <Button
              variant="link"
              size="sm"
              className="float-end"
              onClick={() => onClickHandle()}
            >
              <BiPlusMedical title="Click to Add" size={20} />
            </Button>
          </div>

          {data.length || loadingProviderSpecialtiesList ? (
            <PiDataTable
              columns={columns}
              data={data}
              count={count}
              params={clientParams}
              setParams={setClientParams}
              responsive
              striped={true}
              progressPending={loadingProviderSpecialtiesList && !isLoadingMore}
              highlightOnHover
              persistTableHead={true}
              paginationServer
            />
          ) : (
            <p align="center">
              There are no records to display. Click + icon to add specialty.
            </p>
          )}

          {count === data.length ? null : (
            <div className="text-center mt-2">
              <Button onClick={handleLoadmore} variant="link">
                {isLoadingMore ? "Loading..." : "Load More"}
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ProviderSpecialtiesList.propTypes = {
  getProviderSpecialtiesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  providerSpecialtiesList: state.providerSpecialty.providerSpecialtiesList,
  loadingProviderSpecialtiesList:
    state.providerSpecialty.loadingProviderSpecialtiesList,
  sortingParams: state.providerSpecialty.sortingParams,
});

export default connect(mapStateToProps, {
  getProviderSpecialtiesList,
  resetProviderSpecialtyComponentStore,
  deleteProviderSpecialty,
})(ProviderSpecialtiesList);
