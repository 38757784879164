import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Card } from "react-bootstrap";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";

import {
  createTaxonomy,
  cancelSave,
  setErrors,
  removeTaxonomyErrors,
  resetComponentStore,
} from "actions/taxonomyActions";

const CreateTaxonomy = ({
  createTaxonomy,
  errorList,
  cancelSave,
  loadingTaxonomy,
  setErrors,
  removeTaxonomyErrors,
  resetComponentStore,
}) => {
  const navigate = useNavigate();

  const initialFormData = {
    providerTaxonomyCode: "",
    providerTaxonomyDesc: "",
    medicareSpecialtyCode: "",
    medicareProviderSupplierType: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const {
    providerTaxonomyCode,
    providerTaxonomyDesc,
    medicareSpecialtyCode,
    medicareProviderSupplierType,
  } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeTaxonomyErrors();

    let validationRules = [
      {
        param: "providerTaxonomyCode",
        msg: "The Provider Taxonomy Code is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    createTaxonomy(submitData, navigate).then((res) => {
      if (!res.status) return;
    });
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();
  }, []);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Create Taxonomy"
        crumbs={[
          { name: "Taxonomies", path: "/admin/taxonomies" },
          { name: "Create Taxonomy" },
        ]}
      />

      {loadingTaxonomy ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title mb-3">Taxonomy Information</h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="providerTaxonomyCode">
                    Provider Taxonomy Code <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.providerTaxonomyCode ? "invalid" : ""}
                    type="text"
                    id="providerTaxonomyCode"
                    name="providerTaxonomyCode"
                    maxLength="50"
                    value={providerTaxonomyCode}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <Errors
                    current_key="providerTaxonomyCode"
                    key="providerTaxonomyCode"
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="providerTaxonomyDesc">
                    Provider Taxonomy Description
                  </Form.Label>

                  <Form.Control
                    className={errorList.providerTaxonomyDesc ? "invalid" : ""}
                    as="textarea"
                    rows={5}
                    id="providerTaxonomyDesc"
                    name="providerTaxonomyDesc"
                    maxLength="4000"
                    value={providerTaxonomyDesc}
                    onChange={(e) => onChange(e)}
                  />

                  <Errors
                    current_key="providerTaxonomyDesc"
                    key="providerTaxonomyDesc"
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="medicareSpecialtyCode">
                    Medicare Specialty Code
                  </Form.Label>

                  <Form.Control
                    className={errorList.medicareSpecialtyCode ? "invalid" : ""}
                    as="textarea"
                    rows={2}
                    id="medicareSpecialtyCode"
                    name="medicareSpecialtyCode"
                    maxLength="50"
                    value={medicareSpecialtyCode}
                    onChange={(e) => onChange(e)}
                  />

                  <Errors
                    current_key="medicareSpecialtyCode"
                    key="medicareSpecialtyCode"
                  />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="medicareProviderSupplierType">
                    Medicare Provider Supplier Type
                  </Form.Label>

                  <Form.Control
                    className={
                      errorList.medicareProviderSupplierType ? "invalid" : ""
                    }
                    as="textarea"
                    rows={4}
                    id="medicareProviderSupplierType"
                    name="medicareProviderSupplierType"
                    maxLength="255"
                    value={medicareProviderSupplierType}
                    onChange={(e) => onChange(e)}
                  />

                  <Errors
                    current_key="medicareProviderSupplierType"
                    key="medicareProviderSupplierType"
                  />
                </Form.Group>

                <br></br>

                <div className="float-end">
                  <>
                    <Button
                      className="m-2"
                      type="submit"
                      size="sm"
                      variant="primary"
                    >
                      Create
                    </Button>
                    <Button
                      className="ml-2"
                      type="reset"
                      size="sm"
                      variant="danger"
                      onClick={onClickHandel}
                    >
                      Cancel
                    </Button>
                  </>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

CreateTaxonomy.propTypes = {
  createTaxonomy: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingTaxonomy: state.taxonomy.loadingTaxonomy,
});

export default connect(mapStateToProps, {
  createTaxonomy,
  cancelSave,
  setErrors,
  removeTaxonomyErrors,
  resetComponentStore,
})(CreateTaxonomy);
