import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  credentialList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentCredential: null,
  loadingCredentialList: true,
  loadingCredential: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const credentialSlice = createSlice({
  name: "credential",
  initialState: initialState,
  reducers: {
    loadingCredentialsList(state) {
      return {
        ...state,
        loadingCredentialList: true,
      };
    },
    credentialSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingCredentialList: false,
      };
    },
    credentialListUpdated(state, action) {
      return {
        ...state,
        credentialList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingCredentialList: false,
      };
    },
    credentialDetailsById(state, action) {
      return {
        ...state,
        currentCredential: action.payload,
        loadingCredential: false,
      };
    },
    resetCredential(state) {
      return {
        ...initialState,
      };
    },

    credentialError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingCredential: false,
        loadingCredentialList: false,
      };
    },
    loadingOnCredentialSubmit(state) {
      return {
        ...state,
        loadingCredential: true,
      };
    },
    credentialCreated(state) {
      return {
        ...state,
        loadingCredential: false,
      };
    },
    credentialUpdated(state, action) {
      return {
        ...state,
        credentialList: {
          ...state.credentialList,
          data: state.credentialList.data.map((credential) =>
            credential._id === action.payload._id ? action.payload : credential
          ),
        },
      };
    },
    credentialDeleted(state, action) {
      const currentCount = state.credentialList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.credentialList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        credentialList: {
          data: state.credentialList.data.filter(
            (credential) => credential._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingCredentialList: false,
      };
    },
  },
});
export const {
  loadingCredentialsList,
  credentialSearchParametersUpdate,
  credentialListUpdated,
  resetCredential,
  credentialError,
  loadingOnCredentialSubmit,
  credentialCreated,
  credentialUpdated,
  credentialDetailsById,
  credentialDeleted,
} = credentialSlice.actions;
export default credentialSlice.reducer;
