import React from "react";
import { Form } from "react-bootstrap";

const PsvReadyFilters = ({
  searchDelay = 750,
  type,
  onSearch,
  filter,
  filterName,
  filterParams,
  filterType,
  onFilterChange,
}) => {
  const [value, setValue] = React.useState(90);
  const [expiredOnly, setExpiredOnly] = React.useState(false);

  let delayTimer;
  const handleSearch = (value) => {
    clearTimeout(delayTimer);

    const numberValue = parseInt(value, 10);

    setValue(numberValue);

    if (!isNaN(numberValue) && numberValue >= 1 && numberValue <= 90) {
      delayTimer = setTimeout(() => {
        onSearch("search", numberValue);
      }, searchDelay);
    } else if (!value) {
      delayTimer = setTimeout(() => {
        onSearch("search", "");
      }, searchDelay);
    }
  };

  const handleFilterChange = (e) => {
    const checked = e.target.checked;

    let newParams;

    newParams = {
      ...filterParams,
      filters: checked
        ? [...filterParams.filters, filter]
        : filterParams.filters.filter((item) => item !== filter),
    };

    newParams.query = {
      ...newParams.query,
      [filter]: {
        value: checked,
        type: filterType,
      },
    };

    newParams.page = 1;

    onFilterChange(newParams);
  };

  const handleExpired = (e) => {
    const checked = e.target.checked;
    if (checked) {
      handleSearch(value);
      setExpiredOnly(true);
    } else {
      setExpiredOnly(false);
      let newParams;

      newParams = {
        ...filterParams,
        filters: filterParams.filters.filter((item) => item !== filter),
      };

      newParams.query = {
        ...newParams.query,
        [filter]: undefined,
      };

      newParams.page = 1;

      onFilterChange(newParams);
    }
  };

  return (
    <>
      {type === "checkbox" && onSearch ? (
        <Form.Group>
          <Form.Check
            type="checkbox"
            id={`psv_ready_unverified`}
            label={filterName ? filterName : null}
            checked={filterParams.filters.includes(filter)}
            onChange={(e) => handleFilterChange(e)}
          />
        </Form.Group>
      ) : null}

      {type === "search" && onSearch ? (
        <Form.Group className="d-ruby">
          <Form.Group>
            <Form.Check
              type="checkbox"
              id={`psv_ready_expired`}
              checked={expiredOnly}
              onChange={(e) => handleExpired(e)}
            />
          </Form.Group>{" "}
          <Form.Label htmlFor="search">Credentials expiring in </Form.Label>{" "}
          <Form.Control
            className={value > 90 || value < 1 ? "invalid" : ""}
            style={{ width: "45px", padding: "5px 5px" }}
            type="number"
            min={1}
            max={90}
            onChange={(e) => handleSearch(e.target.value)}
            defaultValue={value}
          />{" "}
          <span> days</span>
        </Form.Group>
      ) : null}
    </>
  );
};

export default PsvReadyFilters;
