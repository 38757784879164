import React from "react";
import { Form, Dropdown } from "react-bootstrap";

const SpecialtyFilters = (props) => {
  const {
    searchDelay = 750,
    filter,
    filterName,
    type,
    options,
    filterParams,
    onFilterChange,
    filterType,
  } = props;

  let delayTimer;

  const [selectedOption, setSelectedOption] = React.useState("All");

  const handleSearch = (e) => {
    clearTimeout(delayTimer);
    const text = e.target.value;
    delayTimer = setTimeout(() => {
      props.onSearch("search", text);
    }, searchDelay);
  };

  const handleSelect = (option) => {
    let optionValue;

    setSelectedOption(option.label);

    if (type === "gmap-citypicker") {
      let city_name = option.name;

      optionValue = city_name;
    } else {
      optionValue = option.value;
    }

    let filters, params;

    if (optionValue === "all" || optionValue === "") {
      filters = filterParams.filters.filter((item) => item !== filter);

      const temp = {};
      params = {
        ...filterParams,
        filters,
      };
      for (var i in params.query) {
        if (i === filter) continue;
        temp[i] = params.query[i];
      }
      params.query = temp;
    } else {
      filters = filterParams.filters.includes(filter)
        ? filterParams.filters
        : [...filterParams.filters, filter];
      params = {
        ...filterParams,
        filters,
      };
      params.query = {
        ...params.query,
        [filter]: {
          value: optionValue,
          type: filterType,
        },
      };
    }

    // to bring on first page on every filter change
    params.page = 1;

    onFilterChange(params);
  };

  return (
    <>
      {props.onSearch ? (
        <Form.Group>
          <Form.Label htmlFor="search">Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search by Name"
            title="Search by Name"
            onChange={(e) => handleSearch(e)}
          />
        </Form.Group>
      ) : null}

      {type === "dropdown" ? (
        <>
          <Form.Group>
            <Form.Label>{filterName ? filterName : filter}</Form.Label>
            <Dropdown>
              <Dropdown.Toggle className="btn-light">
                {selectedOption}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {options
                  ? options.map((each, i) => (
                      <Dropdown.Item
                        onClick={(e) => handleSelect(each)}
                        key={i}
                      >
                        {each.label}
                      </Dropdown.Item>
                    ))
                  : null}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </>
      ) : null}
    </>
  );
};

export default SpecialtyFilters;
