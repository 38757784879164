import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";

import {
  getDegreeById,
  updateDegree,
  setErrors,
  removeDegreeErrors,
  resetComponentStore,
} from "actions/degreeActions";

const EditDegree = ({
  getDegreeById,
  currentDegree,
  updateDegree,
  errorList,
  loadingDegree,
  setErrors,
  removeDegreeErrors,
  resetComponentStore,
}) => {
  const navigate = useNavigate();
  const { degree_id } = useParams();

  const initialFormData = {
    degree: "",
    abbreviation: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [activatingSave, setActivatingSave] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const { degree, abbreviation } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeDegreeErrors();

    let validationRules = [
      {
        param: "degree",
        msg: "The Degree is required",
      },
      {
        param: "abbreviation",
        msg: "The Degree abbreviation is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    if (submitData && submitData.status === 3 && activatingSave) {
      setLoading(true);
      submitData.status = 2;

      updateDegree(submitData, currentDegree._id, navigate).then((res) => {
        setLoading(false);
        setActivatingSave(false);
        toggleEdit();
        if (!res.status) return;
      });
    } else {
      setSubmitting(true);
      updateDegree(submitData, currentDegree._id, navigate).then((res) => {
        setSubmitting(false);
        toggleEdit();
        if (!res.status) return;
      });
    }
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    loadDegreeFormData(currentDegree);
    toggleEdit();
  };

  const loadDegreeFormData = (currentDegree) => {
    if (!currentDegree) return;

    const { degree, abbreviation, status } = currentDegree;

    setFormData((formData) => ({
      ...formData,
      degree,
      abbreviation,
      status,
    }));
  };

  React.useEffect(() => {
    if (!degree_id) return;

    resetComponentStore();

    getDegreeById(degree_id);
  }, [getDegreeById]);

  React.useEffect(() => {
    if (!currentDegree) return;

    loadDegreeFormData(currentDegree);
  }, [currentDegree]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle={!loadingDegree && currentDegree ? `Edit Degree` : ""}
        crumbs={[
          { name: "Degrees", path: "/admin/degrees" },
          {
            name:
              !loadingDegree && currentDegree ? `Edit Degree` : "Loading...",
          },
        ]}
      />

      {loadingDegree ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title mb-3">
                  Degree Information
                  <Button
                    variant="link"
                    size="sm"
                    className="float-end"
                    onClick={toggleEdit}
                  >
                    {isDisabled ? (
                      <span>
                        <MdEdit title="Click to Edit" size={20} />
                      </span>
                    ) : (
                      <span>
                        <FaRegEye title="View mode" size={20} />
                      </span>
                    )}
                  </Button>
                </h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="degree">
                    Degree <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.degree ? "invalid" : ""}
                    type="text"
                    id="degree"
                    name="degree"
                    value={degree}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="degree" key="degree" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="abbreviation">
                    Abbreviation <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.abbreviation ? "invalid" : ""}
                    type="text"
                    id="abbreviation"
                    name="abbreviation"
                    value={abbreviation}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="abbreviation" key="abbreviation" />
                </Form.Group>

                <br></br>

                <div className="float-end">
                  {formData.status === 3 ? (
                    <Button
                      className="ml-2"
                      type="submit"
                      variant="primary"
                      onClick={() => {
                        setActivatingSave(true);
                      }}
                      disabled={loading || isDisabled}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {` Loading... `}
                        </>
                      ) : (
                        <>Activate & Save</>
                      )}
                    </Button>
                  ) : null}

                  <Button
                    className="m-2"
                    type="submit"
                    variant="primary"
                    disabled={submitting || isDisabled}
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickCancel}
                    disabled={submitting || isDisabled}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditDegree.propTypes = {
  getDegreeById: PropTypes.func.isRequired,
  updateDegree: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingDegree: state.degree.loadingDegree,
  currentDegree: state.degree.currentDegree,
});

export default connect(mapStateToProps, {
  getDegreeById,
  updateDegree,
  setErrors,
  removeDegreeErrors,
  resetComponentStore,
})(EditDegree);
