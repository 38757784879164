import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import logo from "../../../assets/images/logo-dark-new.png";
import Spinner from "../../Spinner";
import Alert from "../../../Notifications/Alert";
import Errors from "../../../Notifications/Errors";

import { resetLink, loadPage } from "../../../actions/auth";

const ForgotPassword = ({ loading, resetLink, loadPage }) => {
  const initialState = {
    email: "",
  };

  React.useEffect(() => {
    loadPage();
  }, [loadPage]);

  const [formData, setFormData] = React.useState(initialState);

  const { email } = formData;

  const onChange = (e) => {
    if (!e) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    resetLink({ email });
  };

  return loading ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <Helmet>
        <title>Forgot Password | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <div className="auth-fluid">
        <div className="auth-fluid-form-box">
          <Row className="align-items-center d-flex h-100 auth-fluid-form">
            <div className="auth-brand text-center text-lg-start">
              <Link to="/">
                <span>
                  <img src={logo} alt="" height="40" />
                </span>
              </Link>
            </div>
            <Col lg="12">
              <Card>
                <Card.Body>
                  <Card.Title className="mt-0 mb-3">Forgot Password</Card.Title>

                  <Card.Text className="text-muted mb-4">
                    Enter your email address and we'll send you an email with
                    instructions to reset your password.
                  </Card.Text>
                  <Alert />
                  <Form onSubmit={(e) => onSubmit(e)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        onChange={(e) => onChange(e)}
                        required
                      />

                      <Errors current_key="email" key="email" />
                    </Form.Group>

                    <Form.Group className="d-grid mt-4 mb-0 text-center">
                      <Button variant="primary" type="submit">
                        Reset Password
                      </Button>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
              <Row className="text-center mt-3 mb-3">
                <Col>
                  <span>Back to</span>
                  <Link to="/" className="text-muted ms-1 text-decoration">
                    <strong>Log In</strong>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

ForgotPassword.propTypes = {
  resetLink: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loadingPasswordReset,
});

export default connect(mapStateToProps, { resetLink, loadPage })(
  ForgotPassword
);
