import React from "react";
import { MdEdit } from "react-icons/md";
import { isAdmin } from "utils/helper";

const NoData = ({ loggedUser }) => {
  return (
    <div className="no-data">
      There is no data to display.{" "}
      {isAdmin(loggedUser) ? (
        <React.Fragment>
          Click to <MdEdit title="Click to Edit" size={16} /> icon to add data.
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default NoData;
