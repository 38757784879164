import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingSubSpecialtiesList,
  resetSubSpecialty,
  subSpecialtiesListUpdated,
  subSpecialtyDeleted,
  subSpecialtyError,
  subSpecialtyCreated,
  subSpecialtyUpdated,
  loadingOnSubSpecialtySubmit,
  subSpecialtyDetailsById,
} from "reducers/subSpecialtyReducer";

export const getSubSpecialtiesList =
  (subSpecialtyParams, specialty_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const query = subSpecialtyParams.query ? subSpecialtyParams.query : "";
      subSpecialtyParams.query = query;
      config.params = subSpecialtyParams;

      dispatch(loadingSubSpecialtiesList());
      const res = await axios.get(
        `/api/admin/specialties/${specialty_id}/sub-specialties/list`,
        config
      );

      dispatch(subSpecialtiesListUpdated(res.data.response[0]));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            subSpecialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getSubSpecialtyById =
  (specialty_id, sub_specialty_id) => async (dispatch) => {
    dispatch(removeErrors());
    dispatch(loadingOnSubSpecialtySubmit());
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `/api/admin/specialties/${specialty_id}/sub-specialties/${sub_specialty_id}`,
        config
      );

      dispatch(subSpecialtyDetailsById(res.data.response));
      return res.data ? res.data.response : { status: false };
    } catch (err) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            subSpecialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const createSubSpecialty =
  (formData, navigate, specialty_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const res = await axios.post(
        `/api/admin/specialties/${specialty_id}/sub-specialties`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(subSpecialtyCreated(res.data.response));

        dispatch(setAlert("Sub Specialty Created.", "success"));
        // navigate(`/admin/specialties`);

        return res.data;
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(subSpecialtyError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            subSpecialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateSubSpecialty =
  (formData, navigate, specialty_id, sub_specialty_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/admin/specialties/${specialty_id}/sub-specialties/${sub_specialty_id}/edit`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(subSpecialtyUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(subSpecialtyError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            subSpecialtyError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const deleteSubSpecialty =
  (specialty_id, sub_specialty_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.delete(
        `/api/admin/specialties/${specialty_id}/sub-specialties/${sub_specialty_id}`,
        config
      );

      dispatch(subSpecialtyDeleted(sub_specialty_id));
      dispatch(setAlert("Sub Specialty deleted", "success"));
    } catch (err) {
      err.response &&
        dispatch(
          subSpecialtyError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );
    }
  };

// reset errors
export const removeSubSpecialtyErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetSubSpecialty());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(subSpecialtyError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/specialties");
};
