import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Card } from "react-bootstrap";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";
import Select from "react-select";

import { validateForm } from "utils/validation";

import {
  getCredentialsList,
  createSource,
  cancelSave,
  setErrors,
  removeSourceErrors,
  resetComponentStore,
  getStatesList,
} from "actions/sourceActions";

const CreateSource = ({
  getCredentialsList,
  createSource,
  errorList,
  cancelSave,
  loadingSource,
  setErrors,
  removeSourceErrors,
  resetComponentStore,
  getStatesList,
}) => {
  const navigate = useNavigate();

  const [credentialsListData, setCredentialsListData] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);

  const initialFormData = {
    code: "",
    description: "",
    national: true,
    state: "",
    credential: "",
    url: "",
    phone: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const { code, description, national, state, credential, url, phone } =
    formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      case "national":
        if (e.target.value === "true") {
          setSelectedState(null);
        }
        setFormData({
          ...formData,
          [e.target.name]: e.target.value === "true" ? true : false,
          state: e.target.value === "true" ? "" : state,
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleCredentialsSelect = (selectedRole) => {
    setFormData({
      ...formData,
      credential: selectedRole ? selectedRole.value : null,
    });
  };

  const handleStateSelect = (selectedRole) => {
    setSelectedState(selectedRole);
    setFormData({
      ...formData,
      state: selectedRole ? selectedRole.value : null,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeSourceErrors();

    let validationRules = [
      {
        param: "code",
        msg: "The Code is required",
      },
      {
        param: "credential",
        msg: "The Credential ID is required",
      },
    ];

    if (!national) {
      validationRules.push({
        param: "state",
        msg: "The State is required",
      });
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    createSource(submitData, navigate).then((res) => {
      if (!res.status) return;
    });
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();
    removeSourceErrors();
  }, []);

  React.useMemo(async () => {
    const stateListData = await getStatesList();

    setStatesList(stateListData);
  }, [getStatesList]);

  React.useMemo(async () => {
    try {
      const credentialsList = await getCredentialsList();

      setCredentialsListData(credentialsList);
    } catch (error) {
      console.error(error);
    }
  }, [getCredentialsList, resetComponentStore]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Create Source"
        crumbs={[
          { name: "Sources", path: "/admin/sources" },
          { name: "Create Source" },
        ]}
      />

      {loadingSource ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title mb-3">Source Information</h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="code">
                    Code <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.code ? "invalid" : ""}
                    type="text"
                    id="code"
                    name="code"
                    maxLength="50"
                    value={code}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="code" key="code" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="credential">
                    Credential <span>*</span>
                  </Form.Label>

                  <Select
                    id="credential"
                    name="credential"
                    options={credentialsListData.map((cred) => ({
                      value: cred._id,
                      label: cred.code,
                    }))}
                    onChange={handleCredentialsSelect}
                    invalid={errorList.credential ? true : false}
                  />

                  <Errors current_key="credential" key="credential" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="description">Description</Form.Label>

                  <Form.Control
                    className={errorList.description ? "invalid" : ""}
                    as="textarea"
                    rows={3}
                    id="description"
                    name="description"
                    maxLength="255"
                    value={description}
                    onChange={(e) => onChange(e)}
                  />

                  <Errors current_key="description" key="description" />
                </Form.Group>

                <Row>
                  <Form.Label htmlFor="national">National</Form.Label>
                  <Col sm="3" md="1">
                    <Form.Group
                      controlId="national-true"
                      className="form-group"
                    >
                      <Form.Check
                        className={errorList.national ? "invalid" : ""}
                        type="radio"
                        label="Yes"
                        id="national-true"
                        name="national"
                        value="true"
                        checked={national}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm="3" md="1">
                    <Form.Group
                      controlId="national-false"
                      className="form-group"
                    >
                      <Form.Check
                        className={errorList.national ? "invalid" : ""}
                        type="radio"
                        label="No"
                        id="national-false"
                        name="national"
                        value="false"
                        checked={!national}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="state">State</Form.Label>

                  <Select
                    id="state"
                    name="state"
                    options={statesList.map((state) => ({
                      value: state.abbreviation,
                      label: state.name,
                    }))}
                    onChange={handleStateSelect}
                    invalid={errorList.state ? true : false}
                    value={selectedState}
                    isDisabled={national}
                  />

                  <Errors current_key="state" key="state" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="url">URL</Form.Label>
                  <Form.Control
                    className={errorList.url ? "invalid" : ""}
                    type="text"
                    id="url"
                    name="url"
                    maxLength="120"
                    value={url}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="url" key="url" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="phone">Phone</Form.Label>

                  <Form.Control
                    className={errorList.phone ? "invalid" : ""}
                    type="text"
                    id="phone"
                    name="phone"
                    maxLength="20"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={phone}
                    onChange={(e) => onChange(e)}
                  />

                  <Errors current_key="phone" key="phone" />
                </Form.Group>

                <br></br>

                <div className="float-end">
                  <>
                    <Button
                      className="m-2"
                      type="submit"
                      size="sm"
                      variant="primary"
                    >
                      Create
                    </Button>
                    <Button
                      className="ml-2"
                      type="reset"
                      size="sm"
                      variant="danger"
                      onClick={onClickHandel}
                    >
                      Cancel
                    </Button>
                  </>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

CreateSource.propTypes = {
  getCredentialsList: PropTypes.func.isRequired,
  createSource: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingSource: state.source.loadingSource,
  statesList: state.source.statesList,
});

export default connect(mapStateToProps, {
  getCredentialsList,
  createSource,
  cancelSave,
  setErrors,
  removeSourceErrors,
  resetComponentStore,
  getStatesList,
})(CreateSource);
