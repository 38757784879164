import axios from "axios";
import { setAlert } from "actions/alert";
import { setErrorsList } from "./errors";

import { logout } from "./auth";

export const initiatePsvOrderSFTPOutputProcessing = () => async (dispatch) => {
  // dispatch(loadingOnSettingsSubmit());
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `/api/admin/psv-output-processing/sftp/initiate-psv-output-process`,
      config
    );

    if (res.data.status === true) {
      dispatch(
        setAlert("Orders Output process initiated through SFTP", "success")
      );
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      /* err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        ); */

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};
