import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingOnProviderMedicareSubmit,
  providerMedicareError,
  providerMedicareUpdated,
  resetProviderMedicare,
} from "reducers/providerMedicareReducer";

export const getProviderMedicareById = (provider_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnProviderMedicareSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/providers/${provider_id}/medicare`,
      config
    );

    dispatch(providerMedicareUpdated(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          providerMedicareError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const saveProviderMedicare =
  (formData, provider_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `/api/admin/providers/${provider_id}/medicare/save`,
        formData,
        config
      );

      if (res.data.status === true) {
        await dispatch(providerMedicareUpdated(res.data.response));
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        dispatch(setAlert(res.data?.message, "danger"));

        if (errors.length) {
          dispatch(providerMedicareError());
          dispatch(setErrors(errors));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            providerMedicareError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

export const resetProviderMedicareComponentStore = () => async (dispatch) => {
  await dispatch(resetProviderMedicare());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(providerMedicareError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const removeProviderMedicareErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};
