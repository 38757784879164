import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Card } from "react-bootstrap";

import "react-phone-number-input/style.css";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";
import extractNumber from "utils/extractNumber";
import { isAdmin } from "utils/helper";

import {
  create,
  cancelSave,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
} from "actions/user";

const CreateUser = ({
  create,
  errorList,
  cancelSave,
  loadingUser,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
  loggedInUser,
}) => {
  const navigate = useNavigate();

  const initialFormData = {
    name: "",
    email: "",
    title: "",
    ccode: "+1",
    phone: "",
    ccode_phone: "+1",
    role: 1,
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const { name, email, title, ccode, ccode_phone, role } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onPhoneChange = (val) => {
    const phone = extractNumber(val, ccode);
    setFormData((form) => ({
      ...form,
      phone: phone,
      ccode_phone: val || ccode,
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      iso: code || "US",
      ccode: "+" + getCountryCallingCode(code || "US"),
    }));
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();

    removeUserErrors();

    let validationRules = [
      {
        param: "name",
        msg: "Name is required.",
      },
      {
        param: "title",
        msg: "Title is requuired.",
      },
      {
        param: "email",
        msg: "Email is requuired.",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    // console.log("Add User Submit data", submitData);
    create(submitData, navigate).then((res) => {});
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();
    loadPage();

    if (!loggedInUser) return;

    // Went to dashboard when user is not a admin
    if (!isAdmin(loggedInUser)) {
      navigate("/admin/dashboard");
    }
  }, [loggedInUser]);

  const onRoleChangeCheck = (e, value) => {
    if (!e.target) return;

    setFormData({
      ...formData,
      role: value,
    });
  };

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Create New User"
        crumbs={[
          { name: "Users", path: "/admin/users" },
          { name: "Create New User" },
        ]}
      />

      {loadingUser ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title">User Information</h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="name">
                    Name <span>*</span>
                  </Form.Label>

                  <Form.Control
                    className={errorList.name ? "invalid" : ""}
                    type="text"
                    id="name"
                    name="name"
                    maxLength="100"
                    value={name}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="name" key="name" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="title">
                    Title <span>*</span>
                  </Form.Label>

                  <Form.Control
                    className={errorList.title ? "invalid" : ""}
                    type="text"
                    id="title"
                    name="title"
                    maxLength="100"
                    value={title}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="title" key="title" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="email">
                    Email <span>*</span>
                  </Form.Label>

                  <Form.Control
                    className={errorList.email ? "invalid" : ""}
                    type="mail"
                    id="email"
                    name="email"
                    maxLength="100"
                    value={email}
                    onChange={(e) => onChange(e)}
                    invalid={errorList.email ? true : false}
                  />

                  <Errors current_key="email" key="email" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="phone">Phone</Form.Label>

                  <PhoneInput
                    className={errorList.phone ? "invalid" : ""}
                    id="phone"
                    name="phone"
                    international={true}
                    defaultCountry="US"
                    countryOptionsOrder={["US", "|", "..."]}
                    value={ccode_phone}
                    onChange={onPhoneChange}
                    addInternationalOption={false}
                    onCountryChange={onCountryChange}
                  />
                  <Errors current_key="phone" key="phone" />
                </Form.Group>

                <Row>
                  <Col sm="6" md="3">
                    <Form.Group controlId="isAdmin">
                      <Form.Check
                        type="checkbox"
                        id="isAdminCheckbox"
                        label="Is Administrator?"
                        onChange={(e) => onRoleChangeCheck(e, 2)}
                        checked={role === 2}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm="6" md="3">
                    <Form.Group controlId="isUser">
                      <Form.Check
                        type="checkbox"
                        id="isUser"
                        label="Is User?"
                        onChange={(e) => onRoleChangeCheck(e, 1)}
                        checked={role === 1}
                      />
                    </Form.Group>
                  </Col>

                  <Errors current_key="role" key="role" />

                  {role === 2 ? (
                    <small className="c-red">
                      Note: The Administrator can access all the client
                      workspaces.
                    </small>
                  ) : (
                    <div className="v-hidden">i</div>
                  )}
                </Row>

                <div className="float-end">
                  <Button
                    className="m-2"
                    type="submit"
                    size="sm"
                    // variant="primary"
                  >
                    Submit
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    size="sm"
                    variant="danger"
                    onClick={onClickHandel}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

CreateUser.propTypes = {
  create: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingUser: state.user.loadingUser,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  create,
  cancelSave,
  loadPage,
  setErrors,
  removeUserErrors,
  resetComponentStore,
})(CreateUser);
