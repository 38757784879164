import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { BiPlusMedical, BiTrash } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";

import PiDataTable from "views/DataTable/PiDataTable";
import SubSpecialtyModal from "./SubSpecialtyModal";

import {
  getSubSpecialtiesList,
  resetComponentStore,
  deleteSubSpecialty,
  createSubSpecialty,
} from "actions/subSpecialtiesActions";

const SubSpecialtiesList = ({
  subSpecialtiesList: { data, count },
  getSubSpecialtiesList,
  loadingSubSpecialtiesList,
  resetComponentStore,
  sortingParams,
  deleteSubSpecialty,
  data: { _id },
}) => {
  const specialty_id = _id;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [isLoadingMore, setLoadingMore] = React.useState(false);

  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);

  const [specialtyID, setSpecialtyID] = React.useState(null);
  const [subSpecialtyID, setSubSpecialtyID] = React.useState(null);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
      width: "70%",
      wrap: true,
    },
    {
      name: "Actions",
      width: "30%",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="link"
            size="sm"
            onClick={() => onClickHandle(row._id)}
          >
            <FaEdit />
          </Button>

          <Button
            className="ml-1 c-red"
            size="sm"
            type="button"
            variant="link"
            onClick={(e) => {
              if (
                window.confirm(`Are you sure you want to delete: ${row.name}?`)
              ) {
                deleteSubSpecialty(specialty_id, row._id);
              }
            }}
          >
            <BiTrash />
          </Button>
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    if (!specialty_id) return;
    if (onlyOnce) {
      setOnce(false);
      resetComponentStore();
    }

    getSubSpecialtiesList(clientParams, specialty_id)
      .then((res) => {
        setLoadingMore(false);
      })
      .catch((error) => {
        setLoadingMore(false);
      });
  }, [specialty_id, clientParams, getSubSpecialtiesList, resetComponentStore]);

  const onClickHandle = (sub_specialty_id) => {
    setModal(true);
    setSpecialtyID(specialty_id);
    setSubSpecialtyID(sub_specialty_id);
  };

  const handleLoadmore = () => {
    setLoadingMore(true);
    setClientParams({
      ...clientParams,
      page: page + 1,
    });
  };

  return (
    <React.Fragment>
      <div className="nested-datatable">
        <SubSpecialtyModal
          modal={modal}
          setModal={setModal}
          specialtyID={specialtyID}
          setSpecialtyID={setSpecialtyID}
          subSpecialtyID={subSpecialtyID}
          setSubSpecialtyID={setSubSpecialtyID}
        />

        <Card className="sub-specialty-card">
          <Card.Body>
            <div className="card-heading">
              <h4 className="header-title">Sub Specialties</h4>

              <Button variant="link" size="sm" onClick={() => onClickHandle()}>
                <BiPlusMedical title="Click to Add" size={20} />
              </Button>
            </div>

            {data.length || loadingSubSpecialtiesList ? (
              <PiDataTable
                columns={columns}
                data={data}
                count={count}
                responsive
                striped={true}
                progressPending={loadingSubSpecialtiesList && !isLoadingMore}
                highlightOnHover
                persistTableHead={true}
                noHeader={true}
                noTableHead={true}
                expandOnRowClicked
                paginationServer
                minHeight={"100px"}
              />
            ) : (
              <p align="center">
                There are no records to display. Click + icon to add sub
                specialty.
              </p>
            )}

            {count === data.length ? null : (
              <div className="text-center mt-2">
                <Button onClick={handleLoadmore} variant="link">
                  {isLoadingMore ? "Loading..." : "Load More"}
                </Button>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

SubSpecialtiesList.propTypes = {
  getSubSpecialtiesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  subSpecialtiesList: state.subSpecialty.subSpecialtiesList,
  loadingSubSpecialtiesList: state.subSpecialty.loadingSubSpecialtiesList,
  sortingParams: state.subSpecialty.sortingParams,
  errorList: state.errors,
});

export default connect(mapStateToProps, {
  getSubSpecialtiesList,
  resetComponentStore,
  deleteSubSpecialty,
  createSubSpecialty,
})(SubSpecialtiesList);
