import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Button, Card, Row, Col } from "react-bootstrap";
import Spinner from "views/Spinner";
import { isAdmin } from "utils/helper";
import moment from "moment";

import { MdEdit, MdDownload } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";

import {
  updateProvider,
  cancelSave,
  setErrors,
  removeProviderErrors,
  resetComponentStore,
  getProviderById,
  getStatesList,
} from "actions/providerActions";

import AppBreadcrumb from "../../Layout/AppBreadCrumb";
import ChangePasswordModal from "views/Admin/Profile/ChangePasswordModal";

const ProviderNav = ({ onClickHandle, user }) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between mt-2">
        <h4 className="provider-nav-name">
          {user && user.name ? user.name : "Provider Dashboard"}
        </h4>

        <Button
          className="btn btn-outline-dark mt-1 mb-1"
          onClick={() => onClickHandle()}
        >
          <RiLockPasswordLine /> Change Password
        </Button>
      </div>
    </React.Fragment>
  );
};

const ProviderDashboard = ({
  loadingProvider,
  currentProvider,
  getProviderById,
  resetComponentStore,
  user,
  providerEducationList,
  providerCredentialList,
}) => {
  const { provider_id } = useParams();

  const initialFormData = {
    type: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: "",
    phone: "",
    email: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    zipCode: "",
    medicalProvider: false,
    npiNumber: "",
    deaNumber: "",
    tin: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [isDisabled, setDisabled] = React.useState(true);

  const [modal, setModal] = React.useState(false);

  const toggleEdit = () => setDisabled(!isDisabled);

  const [displayName, setDisplayName] = React.useState("");

  const {
    dateOfBirth,
    phone,
    email,
    address_line1,
    address_line2,
    city,
    state,
    zipCode,
    medicalProvider,
    npiNumber,
    deaNumber,
    tin,
  } = formData;

  const loadProviderFormData = (currentProvider) => {
    const {
      type,
      firstName,
      middleName,
      lastName,
      orgName,
      dateOfBirth,
      phone,
      email,
      address_line1,
      address_line2,
      city,
      state,
      zipCode,
      medicalProvider,
      npiNumber,
      deaNumber,
      tin,
    } = currentProvider;

    const data = {
      type,
      firstName,
      middleName: middleName ? middleName : "",
      lastName,
      dateOfBirth,
      phone,
      email,
      address_line1,
      address_line2,
      city,
      state,
      zipCode,
      medicalProvider,
      npiNumber,
      deaNumber,
      tin,
    };

    setFormData((formData) => ({ ...formData, ...data }));

    let displayName = `${firstName} ${
      middleName ? middleName : ""
    } ${lastName}`;
    if (type === "organization") {
      displayName = orgName;
    }
    setDisplayName(displayName);
  };

  const onClickHandle = () => {
    setModal(true);
  };

  React.useEffect(() => {
    if (!currentProvider) return;

    loadProviderFormData(currentProvider);
  }, [currentProvider]);

  React.useMemo(() => {
    if (!provider_id) return;

    resetComponentStore();

    getProviderById(provider_id);
  }, []);

  return (
    <React.Fragment>
      <ChangePasswordModal modal={modal} setModal={setModal} />

      {isAdmin(user) ? (
        <AppBreadcrumb
          pageTitle={!loadingProvider && displayName ? displayName : ""}
          crumbs={[
            { name: "Providers", path: "/admin/providers" },
            {
              name: displayName ? displayName : "Provider Dashboard",
            },
          ]}
        />
      ) : !loadingProvider ? (
        <ProviderNav onClickHandle={onClickHandle} user={user} />
      ) : null}

      {loadingProvider ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" lg="12">
            <Card className={`card-body ${isAdmin(user) ? "" : "mt-2"}`}>
              <div className="card-heading mb-3">
                <h4 className="header-title mb-3">Provider Information</h4>

                <div className="float-end">
                  <Link to={`/admin/providers/${provider_id}/edit`}>
                    <Button variant="link" size="sm" onClick={toggleEdit}>
                      <MdEdit title="Click to Edit" size={20} /> Edit
                    </Button>
                  </Link>
                </div>
              </div>

              <Row>
                <Col xs="6">
                  <Row>
                    {address_line1 ? (
                      <Col xs="12">
                        <FaMapMarkerAlt /> {address_line1} {address_line2},{" "}
                        {city}, {state} {zipCode}
                      </Col>
                    ) : null}

                    {deaNumber ? (
                      <Col xs="12">DEA Number: {deaNumber}</Col>
                    ) : null}

                    {tin ? <Col xs="12">Tin: {tin}</Col> : null}

                    {medicalProvider ? (
                      <Col xs="12">
                        Medical Provider: {medicalProvider ? "Yes" : "No"}
                      </Col>
                    ) : null}

                    {npiNumber ? <Col xs="12">NPI: {npiNumber}</Col> : null}
                  </Row>
                </Col>

                <Col xs="6" className="text-end">
                  <Row>
                    {dateOfBirth ? (
                      <Col xs="12">
                        Date of Birth:{" "}
                        {moment(dateOfBirth).format("MM/DD/YYYY")}
                      </Col>
                    ) : null}
                    {phone ? <Col xs="12">Phone: {phone}</Col> : null}
                    {email ? <Col xs="12">Email: {email}</Col> : null}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

ProviderDashboard.propTypes = {
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  loadingProvider: state.provider.loadingProvider,
  currentProvider: state.provider.currentProvider,
  loadingProfile: state.auth.loadingProfile,
  providerCredentialList: state.providerCredential.providerCredentialList,
  providerEducationList: state.providerEducation.providerEducationList,
});

export default connect(mapStateToProps, {
  updateProvider,
  cancelSave,
  setErrors,
  removeProviderErrors,
  resetComponentStore,
  getProviderById,
  getStatesList,
})(ProviderDashboard);
