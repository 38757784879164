import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  psvReadyList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentPsvOrder: null,
  loadingPsvReadyList: true,
  loadingPsvOrder: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
  loadingPSVReadyOrders: false,
};

const psvReadySlice = createSlice({
  name: "psvReady",
  initialState: initialState,
  reducers: {
    loadingPsvReadyList(state) {
      return {
        ...state,
        loadingPsvReadyList: true,
      };
    },
    psvReadySearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingPsvReadyList: false,
      };
    },
    psvReadyListUpdated(state, action) {
      return {
        ...state,
        psvReadyList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingPsvReadyList: false,
      };
    },
    resetPsvReady(state) {
      return {
        ...initialState,
      };
    },
    psvReadyError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingPsvOrder: false,
        loadingPsvReadyList: false,
        loadingPSVReadyOrders: false,
      };
    },
    loadingOnPSVReadyOrdersSubmit(state) {
      return {
        ...state,
        loadingPSVReadyOrders: true,
      };
    },
    psvReadyOrdersCreated(state) {
      return {
        ...state,
        loadingPSVReadyOrders: false,
      };
    },
  },
});
export const {
  loadingPsvReadyList,
  psvReadySearchParametersUpdate,
  psvReadyListUpdated,
  resetPsvReady,
  psvReadyError,
  loadingOnPSVReadyOrdersSubmit,
  psvReadyOrdersCreated,
} = psvReadySlice.actions;
export default psvReadySlice.reducer;
