import React from "react";

const FullAddress = ({ row }) => {
  const [addText, setAddText] = React.useState("");

  React.useEffect(() => {
    let st = row.city
      ? row.city && row.state
        ? `${row.city}, `
        : row.city
      : "";

    st += ` ${row.state ? row.state : ""}`;

    st += ` ${row.zipCode ? row.zipCode : ""}`;

    setAddText(st);
  }, [row]);

  return (
    <React.Fragment>
      {row.address_line1 ? (
        <React.Fragment>
          {row.address_line1} <br></br>
        </React.Fragment>
      ) : (
        ""
      )}
      {row.address_line2 ? (
        <React.Fragment>
          {row.address_line2} <br></br>
        </React.Fragment>
      ) : (
        ""
      )}
      <React.Fragment>{addText}</React.Fragment>
    </React.Fragment>
  );
};

export default FullAddress;
