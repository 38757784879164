import React from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { validateForm } from "utils/validation";
import { connect } from "react-redux";

import Spinner from "views/Spinner";
import Errors from "Notifications/Errors";
import { useNavigate } from "react-router-dom";

import {
  createSubSpecialty,
  setErrors,
  removeSubSpecialtyErrors,
  updateSubSpecialty,
  getSubSpecialtyById,
  getSubSpecialtiesList,
} from "actions/subSpecialtiesActions";

const SubSpecialtyModal = ({
  modal,
  setModal,
  specialtyID,
  setSpecialtyID,
  subSpecialtyID,
  setSubSpecialtyID,
  createSubSpecialty,
  errorList,
  setErrors,
  removeSubSpecialtyErrors,
  updateSubSpecialty,
  getSubSpecialtyById,
  currentSubSpecialty,
  getSubSpecialtiesList,
  loadingSubSpecialty,
}) => {
  const navigate = useNavigate();

  const initialFormData = {
    name: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);

  const { name } = formData;

  const loadSubSpecialtyFormData = (currentSubSpecialty) => {
    const { name } = currentSubSpecialty;

    const data = {
      name,
    };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!currentSubSpecialty) return;
    if (!subSpecialtyID) return;

    loadSubSpecialtyFormData(currentSubSpecialty);
  }, [currentSubSpecialty, subSpecialtyID]);

  React.useEffect(() => {
    if (!specialtyID || !subSpecialtyID) return;
    getSubSpecialtyById(specialtyID, subSpecialtyID);
  }, [specialtyID, subSpecialtyID]);

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeSubSpecialtyErrors();

    let validationRules = [
      {
        param: "name",
        msg: "Name is required.",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);

    if (subSpecialtyID) {
      updateSubSpecialty(
        submitData,
        navigate,
        specialtyID,
        subSpecialtyID
      ).then((res) => {
        if (!res.status) return;
        getSubSpecialtiesList(specialtyID);
        reset();
      });
    } else {
      createSubSpecialty(submitData, navigate, specialtyID).then((res) => {
        if (!res.status) return;
        getSubSpecialtiesList(specialtyID);
        reset();
      });
    }
  };

  const reset = () => {
    setModal(false);
    setSpecialtyID(null);
    setSubSpecialtyID(null);
    setSubmitting(false);
    setFormData(initialFormData);
    removeSubSpecialtyErrors();
  };

  return (
    <Modal show={modal} size="md" onHide={reset}>
      {loadingSubSpecialty ? (
        <Spinner />
      ) : (
        <>
          <Modal.Header closeButton>
            <h4 className="d-inline">
              {subSpecialtyID ? "Edit" : "Add"} Sub Specialty
            </h4>
          </Modal.Header>
          <Modal.Body>
            {false ? (
              <Spinner />
            ) : (
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="name">
                    Name <span>*</span>
                  </Form.Label>

                  <Form.Control
                    className={errorList.name ? "invalid" : ""}
                    type="text"
                    id="name"
                    name="name"
                    maxLength="100"
                    value={name}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="name" key="name" />
                </Form.Group>
              </Form>
            )}

            <div className="float-end">
              <Button
                className="m-2"
                type="submit"
                variant="primary"
                onClick={onSubmit}
                disabled={submitting}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  currentSubSpecialty: state.subSpecialty.currentSubSpecialty,
  loadingSubSpecialty: state.subSpecialty.loadingSubSpecialty,
});

export default connect(mapStateToProps, {
  createSubSpecialty,
  setErrors,
  removeSubSpecialtyErrors,
  updateSubSpecialty,
  getSubSpecialtyById,
  getSubSpecialtiesList,
})(SubSpecialtyModal);
