import React from "react";
import { Button } from "react-bootstrap";
import Papa from "papaparse";

const ProvidersSampleCSV = () => {
  const headers = [
    "type",
    "first_name",
    "middle_name",
    "last_name",
    "organization_name",
    "organization_type",
    "associated_organization",
    "date_of_birth",
    "phone",
    "email",
    "address_line1",
    "address_line2",
    "city",
    "state",
    "zip_code",
    "medical_provider",
    "npi_number",
    "dea_number",
    "tin",
  ];

  const sampleData = [
    {
      type: "individual",
      first_name: "John",
      middle_name: "R",
      last_name: "Doe",
      organization_name: "",
      organization_type: "",
      associated_organization: "Infotech",
      date_of_birth: "09/24/1989",
      phone: "3025678904",
      email: "john.doe@yopmail.com",
      address_line1: "13 NW 3rd St.",
      address_line2: "New Lane",
      city: "Alaska",
      state: "AL",
      zip_code: "78765",
      medical_provider: "true",
      npi_number: "545435",
      dea_number: "3454",
      tin: "4534",
    },
    {
      type: "organization",
      first_name: "",
      middle_name: "",
      last_name: "",
      organization_name: "Personal & Corporate Design",
      organization_type: "Single Specialty Group",
      associated_organization: "",
      date_of_birth: "",
      phone: "3022736082",
      email: "personal.corporate@yopmail.com",
      address_line1: "3488 5th st ne",
      address_line2: "",
      city: "Phoenix",
      state: "CA",
      zip_code: "35442",
      medical_provider: "true",
      npi_number: "1234567890",
      dea_number: "",
      tin: "",
    },
  ];

  const onClickSampleCsvHandler = () => {
    const csv = Papa.unparse({
      fields: headers,
      data: sampleData,
    });

    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "providers_sample.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <Button
      variant="link"
      size="sm"
      className="mt-2"
      onClick={() => onClickSampleCsvHandler()}
    >
      Download Sample CSV
    </Button>
  );
};

export default ProvidersSampleCSV;
