import React from "react";
import { Form, Modal, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { validateForm } from "utils/validation";

import { createPSVReadyOrders } from "actions/psvReadyActions";

const PsvReadyOrderModal = ({
  setModal,
  modal,
  selectedItems,
  setSelectedItems,
  createPSVReadyOrders,
  reloadItems,
}) => {
  const initialData = [];

  const [formData, setFormData] = React.useState(initialData);
  const [selectedItemsWithCounts, setSelectedItemsWithCounts] = React.useState(
    []
  );
  const [submitting, setSubmitting] = React.useState(false);

  const reset = () => {
    setModal(false);
    setFormData(initialData);
    setSubmitting(false);
  };

  React.useEffect(() => {
    setFormData(selectedItems);

    const selectedItemsWithCounts = selectedItems.map((provider) => {
      let totalCount = 0;
      Object.values(provider).forEach((key) => {
        if (Array.isArray(key)) {
          totalCount += key.length;
        }
      });
      return { ProviderID: provider.ProviderID, count: totalCount };
    });
    setSelectedItemsWithCounts(selectedItemsWithCounts);
  }, [selectedItems]);

  const submitOrder = (e) => {
    e.preventDefault();

    let validationRules = [];
    const errors = validateForm(formData, validationRules);
    if (errors.length) {
      // setErrors(errors);
      return;
    }

    if (!formData.length) return;

    setSubmitting(true);
    createPSVReadyOrders(formData)
      .then((res) => {
        if (!res.status) return;
        setSelectedItems([]);
        reloadItems();
        reset();
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        reset();
      });
  };

  return (
    <Modal show={modal} onHide={reset}>
      <Modal.Header closeButton className="modal-header">
        <h4>Create PSV Order confirmation!</h4>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => submitOrder(e)}>
          <Row>
            <Col>
              {selectedItems.length} new order(s) will be created. Please
              confirm.
            </Col>
          </Row>

          <div className="float-end">
            <Button
              className="m-2"
              type="submit"
              variant="primary"
              disabled={submitting}
            >
              {submitting ? (
                <>
                  <span className="spinner-border spinner-border-sm"></span>
                  {` Creating Orders... `}
                </>
              ) : (
                <>Confirm</>
              )}
            </Button>
            <Button
              className="ml-2"
              type="reset"
              variant="danger"
              onClick={reset}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
});

export default connect(mapStateToProps, { createPSVReadyOrders })(
  PsvReadyOrderModal
);
