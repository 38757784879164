import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  providerCredentialList: {
    page: 1,
    data: [],
    count: 0,
  },
  statesList: [],
  currentProviderCredential: null,
  loadingProviderCredentialList: true,
  loadingProviderCredential: false,
  error: {},
  sortingParams: {
    limit: 10,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const providerCredentialSlice = createSlice({
  name: "providerCredential",
  initialState: initialState,
  reducers: {
    loadingProviderCredentialsList(state) {
      return {
        ...state,
        loadingProviderCredentialList: true,
      };
    },
    resetProviderCredential(state) {
      return {
        ...initialState,
      };
    },
    providerCredentialSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingProviderCredentialList: false,
      };
    },
    loadingOnProviderCredentialSubmit(state) {
      return {
        ...state,
        loadingProviderCredential: true,
      };
    },

    providerCredentialError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProviderCredential: false,
        loadingProviderCredentialList: false,
      };
    },

    providerCredentialListUpdated(state, action) {
      return {
        ...state,
        providerCredentialList: {
          data:
            state.providerCredentialList && state.providerCredentialList.data
              ? [...state.providerCredentialList.data, ...action.payload.data]
              : action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingProviderCredentialList: false,
      };
    },

    providerCredentialDetailsById(state, action) {
      return {
        ...state,
        currentProviderCredential: action.payload,
        loadingProviderCredential: false,
      };
    },

    providerCredentialCreated(state) {
      return {
        ...state,
        loadingProviderCredential: false,
      };
    },
    providerCredentialStateList(state, action) {
      return {
        ...state,
        statesList: action.payload,
        loadingProviderCredential: false,
      };
    },

    providerCredentialUpdated(state, action) {
      return {
        ...state,
        providerCredentialList: {
          ...state.providerCredentialList,
          data: state.providerCredentialList.data.map((providerCredential) =>
            providerCredential._id === action.payload._id
              ? action.payload
              : providerCredential
          ),
        },
      };
    },

    providerCredentialDeleted(state, action) {
      const currentCount = state.providerCredentialList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.providerCredentialList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        providerCredentialList: {
          data: state.providerCredentialList.data.filter(
            (providerCredential) => providerCredential._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingProviderCredentialList: false,
      };
    },
  },
});
export const {
  loadingProviderCredentialsList,
  providerCredentialSearchParametersUpdate,
  resetProviderCredential,
  providerCredentialListUpdated,
  loadingOnProviderCredentialSubmit,
  providerCredentialCreated,
  providerCredentialStateList,
  providerCredentialDetailsById,
  providerCredentialDeleted,
  providerCredentialError,
  providerCredentialUpdated,
} = providerCredentialSlice.actions;
export default providerCredentialSlice.reducer;
