import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  providerEducationList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentProviderEducation: null,
  loadingProviderEducationList: true,
  loadingProviderEducation: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const providerEducationSlice = createSlice({
  name: "providerEducation",
  initialState: initialState,
  reducers: {
    loadingProviderEducationsList(state) {
      return {
        ...state,
        loadingProviderEducationList: true,
      };
    },
    providerEducationSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingProviderEducationList: false,
      };
    },
    providerEducationError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProviderEducation: false,
        loadingProviderEducationList: false,
      };
    },
    loadingOnProviderEducationSubmit(state) {
      return {
        ...state,
        loadingProviderEducation: true,
      };
    },

    providerEducationListUpdated(state, action) {
      return {
        ...state,
        providerEducationList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingProviderEducationList: false,
      };
    },
    providerEducationDetailsById(state, action) {
      return {
        ...state,
        currentEducationProvider: action.payload,
        loadingProviderEducation: false,
      };
    },
    providerEducationCreated(state) {
      return {
        ...state,
        loadingProviderEducation: false,
      };
    },

    providerEducationUpdated(state, action) {
      return {
        ...state,
        providerEducationList: {
          ...state.providerEducationList,
          data: state.providerEducationList.data.map((providerEducation) =>
            providerEducation._id === action.payload._id
              ? action.payload
              : providerEducation
          ),
        },
      };
    },

    providerEducationDeleted(state, action) {
      const currentCount = state.providerEducationList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.providerEducationList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        providerEducationList: {
          data: state.providerEducationList.data.filter(
            (providerEducation) => providerEducation._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingProviderEducationList: false,
      };
    },

    providerSchoolsList(state, action) {
      return {
        ...state,
        schoolsList: action.payload,
        loadingProviderEducation: false,
      };
    },

    providerDegreesList(state, action) {
      return {
        ...state,
        degreesList: action.payload,
        loadingProvider: false,
      };
    },

    resetProviderEducation(state) {
      return {
        ...initialState,
      };
    },
  },
});
export const {
  loadingProviderEducationsList,
  providerEducationSearchParametersUpdate,
  providerEducationError,
  providerEducationListUpdated,
  loadingOnProviderEducationSubmit,
  providerEducationDetailsById,
  providerEducationCreated,
  providerEducationUpdated,
  providerEducationDeleted,
  providerSchoolsList,
  providerDegreesList,
  resetProviderEducation,
} = providerEducationSlice.actions;
export default providerEducationSlice.reducer;
