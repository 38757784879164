import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import Errors from "Notifications/Errors";
import { validateForm } from "utils/validation";
import { useNavigate, useParams } from "react-router-dom";
import { isAdmin } from "utils/helper";

import {
  createProviderEducation,
  getSchoolsList,
  getDegreesList,
  getProviderEducationList,
  getProviderEducationById,
  removeProviderEducationErrors,
  setErrors,
  updateProviderEducation,
} from "actions/providerEducationActions";

import Spinner from "views/Spinner";

const CreateProviderEducationModal = ({
  modal,
  setModal,
  setErrors,
  removeProviderEducationErrors,
  getSchoolsList,
  getDegreesList,
  errorList,
  loadingProviderEducation,
  createProviderEducation,
  getProviderEducationList,
  currentEducationProvider,
  getProviderEducationById,
  updateProviderEducation,
  educationID,
  setEducationID,
  user,
}) => {
  const navigate = useNavigate();
  const { provider_id } = useParams();

  const initialFormData = {
    school: "",
    school_name: "",
    degree: "",
    newDegree: "",
    specialization: "",
    year_awarded: "",
    verified: false,
    date_verified: "",
  };

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [clientParams, setClientParams] = React.useState(initialSortingParams);

  const [submitting, setSubmitting] = React.useState(false);

  const [schoolsList, setSchoolsList] = React.useState([]);
  const [selectedSchool, setSelectedSchool] = React.useState(null);

  const [degreesList, setDegreesList] = React.useState([]);
  const [selectedDegree, setSelectedDegree] = React.useState(null);

  const [years, setYears] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState(null);

  const {
    school,
    school_name,
    degree,
    newDegree,
    specialization,
    year_awarded,
    verified,
    date_verified,
  } = formData;

  const loadProviderEducationFormData = (currentEducationProvider) => {
    const {
      school,
      degree,
      specialization,
      year_awarded,
      verified,
      date_verified,
    } = currentEducationProvider;

    const data = {
      school,
      degree,
      specialization,
      year_awarded,
      verified,
      date_verified: date_verified ? date_verified : "",
    };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearOptions = [];
    for (let year = 1900; year <= currentYear; year++) {
      yearOptions.push(year);
    }
    setYears(yearOptions.reverse());
  }, []);

  React.useEffect(() => {
    if (!educationID) return;
    if (!year_awarded) return;

    const awardedYear = parseInt(year_awarded);

    const filteredYear = years.find((each) => each === awardedYear);

    if (filteredYear) {
      setSelectedYear({
        value: filteredYear,
        label: filteredYear.toString(),
      });
    }
  }, [year_awarded, educationID, years]);

  React.useEffect(() => {
    if (!educationID) return;
    if (!school) return;

    const filteredSchool = schoolsList.find((each) => each._id === school);

    if (filteredSchool) {
      setSelectedSchool({
        value: filteredSchool._id,
        label: filteredSchool.name,
      });
    }
  }, [school, educationID, schoolsList]);

  React.useEffect(() => {
    if (!educationID) return;
    if (!degree) return;

    const filteredDegree = degreesList.find((each) => each._id === degree);

    if (filteredDegree) {
      setSelectedDegree({
        value: filteredDegree._id,
        label: `${filteredDegree.degree} (${filteredDegree.abbreviation})`,
      });
    }
  }, [degree, educationID, degreesList]);

  React.useEffect(() => {
    if (!currentEducationProvider) return;
    if (!educationID) return;

    loadProviderEducationFormData(currentEducationProvider);
  }, [currentEducationProvider, educationID]);

  React.useEffect(() => {
    if (!educationID) return;
    getProviderEducationById(provider_id, educationID);
  }, [educationID, getProviderEducationById]);

  React.useMemo(async () => {
    const schoolListData = await getSchoolsList();
    const degreeListData = await getDegreesList();

    setSchoolsList(schoolListData);
    setDegreesList(degreeListData);
  }, [getDegreesList, getSchoolsList, modal]);

  const onChange = (e) => {
    if (!e.target) {
      return;
    }
    switch (e.target.name) {
      case "verified":
        const isVerified = e.target.checked;
        setFormData({
          ...formData,
          [e.target.name]: isVerified,
          date_verified: isVerified ? date_verified : "",
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSchoolSelect = (selectedRole) => {
    if (!selectedRole) {
      setSelectedSchool(null);
      return;
    }

    setSelectedSchool({
      value: selectedRole.value,
      label: selectedRole.label,
    });

    const schoolObject = selectedRole.__isNew__
      ? { school_name: selectedRole }
      : { school: selectedRole.value };

    setFormData({
      ...formData,
      ...schoolObject,
    });
  };

  const handleDegreesSelect = (selectedRole) => {
    if (!selectedRole) {
      setSelectedDegree(null);
      return;
    }

    setSelectedDegree({
      value: selectedRole.value,
      label: selectedRole.label,
    });

    const degreeObject = selectedRole.__isNew__
      ? { newDegree: selectedRole }
      : { degree: selectedRole.value };

    setFormData({
      ...formData,
      ...degreeObject,
    });
  };

  const handleYearSelect = (selectedRole) => {
    setSelectedYear({
      value: selectedRole.value,
      label: selectedRole.label,
    });

    setFormData({
      ...formData,
      year_awarded: selectedRole ? selectedRole.value : null,
    });
  };

  const reset = () => {
    setModal(false);
    setFormData(initialFormData);
    setEducationID(null);
    setSelectedSchool(null);
    setSelectedDegree(null);
    setSelectedYear(null);
    removeProviderEducationErrors();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    removeProviderEducationErrors();

    let validationRules = [
      {
        param: "specialization",
        msg: "The Speciliazation is required",
      },
      {
        param: "year_awarded",
        msg: "The Year Awarded is required",
      },
    ];
    if (!school_name && !school) {
      validationRules.push({
        param: "school",
        msg: "The School is required",
      });
    }

    if (!degree && !newDegree) {
      validationRules.push({
        param: "degree",
        msg: "The Degree is required",
      });
    }

    if (verified) {
      validationRules.push({
        param: "date_verified",
        msg: "The Date Verified is required",
      });
    }

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (formData[i] === null || formData[i] === undefined) continue;
      submitData[i] = formData[i];
    }

    setSubmitting(true);
    if (educationID) {
      updateProviderEducation(
        submitData,
        navigate,
        provider_id,
        educationID
      ).then((res) => {
        setSubmitting(false);

        if (!res.status) return;
        reset();
        getProviderEducationList(clientParams, provider_id);
      });
    } else {
      setSubmitting(false);
      createProviderEducation(submitData, navigate, provider_id).then((res) => {
        if (!res.status) return;
        reset();
        getProviderEducationList(clientParams, provider_id);
      });
    }
  };

  return (
    <Modal show={modal} onHide={reset}>
      <Modal.Header closeButton>
        {educationID ? <h4> Edit Education </h4> : <h4> Add Education </h4>}
      </Modal.Header>
      <Modal.Body>
        {loadingProviderEducation ? (
          <Spinner />
        ) : (
          <Form onSubmit={(e) => onSubmit(e)}>
            <Form.Group className="form-group">
              <Form.Label htmlFor="school">
                School <span>*</span>
              </Form.Label>

              <CreatableSelect
                isClearable
                className={errorList.school ? "invalid" : ""}
                id="school"
                name="school"
                options={schoolsList.map((school) => ({
                  value: school._id,
                  label: school.name,
                }))}
                components={{
                  Input: (props) => (
                    <components.Input {...props} maxLength={120} />
                  ),
                }}
                value={selectedSchool}
                onChange={(e) => handleSchoolSelect(e)}
              />
              <Errors current_key="school" key="school" />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label htmlFor="degree">
                Degree <span>*</span>
              </Form.Label>

              <CreatableSelect
                isClearable
                className={errorList.degree ? "invalid" : ""}
                id="degree"
                name="degree"
                options={degreesList.map((degree) => ({
                  value: degree._id,
                  label: `${degree.degree} (${degree.abbreviation})`,
                }))}
                components={{
                  Input: (props) => (
                    <components.Input {...props} maxLength={60} />
                  ),
                }}
                value={selectedDegree}
                onChange={handleDegreesSelect}
                invalid={errorList.degree ? true : false}
              />

              <Errors current_key="degree" key="degree" />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label htmlFor="specialization">
                Specialization <span>*</span>
              </Form.Label>
              <Form.Control
                className={errorList.specialization ? "invalid" : ""}
                as="textarea"
                rows={3}
                id="specialization"
                name="specialization"
                maxLength="250"
                value={specialization}
                onChange={(e) => onChange(e)}
              />
              <Errors current_key="specialization" key="specialization" />
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Label htmlFor="year_awarded">
                Year Awarded <span>*</span>
              </Form.Label>
              <Select
                className={errorList.year_awarded ? "invalid" : ""}
                id="year_awarded"
                name="year_awarded"
                maxLength="4"
                options={years.map((years) => ({
                  value: years,
                  label: years,
                }))}
                value={selectedYear}
                onChange={handleYearSelect}
                invalid={errorList.year_awarded ? true : false}
              />
              <Errors current_key="year_awarded" key="year_awarded" />
            </Form.Group>

            {isAdmin(user) ? (
              <>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="verified">Verified</Form.Label>
                  <Form.Check
                    className={errorList.verified ? "invalid" : ""}
                    type="switch"
                    id="verified"
                    name="verified"
                    checked={verified}
                    onChange={(e) => onChange(e)}
                    label={verified === true ? "Yes" : "No"}
                  />
                  <Errors current_key="verified" key="verified" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="date_verified">Date Verified</Form.Label>
                  <Form.Control
                    className={errorList.date_verified ? "invalid" : ""}
                    type="date"
                    id="date_verified"
                    name="date_verified"
                    value={date_verified}
                    onChange={(e) => onChange(e)}
                    disabled={!verified}
                  />
                  <Errors current_key="date_verified" key="date_verified" />
                </Form.Group>
              </>
            ) : null}

            <div className="float-end">
              <Button
                className="m-2"
                type="submit"
                variant="primary"
                disabled={submitting}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm"></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={reset}
                disabled={submitting}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  loadingProviderEducation: state.providerEducation.loadingProviderEducation,
  currentEducationProvider: state.providerEducation.currentEducationProvider,
});

export default connect(mapStateToProps, {
  getSchoolsList,
  getDegreesList,
  setErrors,
  removeProviderEducationErrors,
  createProviderEducation,
  getProviderEducationById,
  getProviderEducationList,
  updateProviderEducation,
})(CreateProviderEducationModal);
