import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  specialtiesList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentSpecialty: null,
  loadingSpecialtiesList: true,
  loadingSpecialty: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const specialtySlice = createSlice({
  name: "specialty",
  initialState: initialState,
  reducers: {
    loadingSpecialtiesList(state) {
      return {
        ...state,
        loadingSpecialtiesList: true,
      };
    },
    specialtySearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingSpecialtiesList: false,
      };
    },
    specialtiesListUpdated(state, action) {
      return {
        ...state,
        specialtiesList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingSpecialtiesList: false,
      };
    },
    specialtyDetailsById(state, action) {
      return {
        ...state,
        currentSpecialty: action.payload,
        loadingSpecialty: false,
      };
    },
    resetSpecialty(state) {
      return {
        ...initialState,
      };
    },

    specialtyError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingSpecialty: false,
        loadingSpecialtiesList: false,
      };
    },
    loadingOnSpecialtySubmit(state) {
      return {
        ...state,
        loadingSpecialty: true,
      };
    },
    specialtyCreated(state) {
      return {
        ...state,
        loadingSpecialty: false,
      };
    },
    specialtyUpdated(state, action) {
      return {
        ...state,
        specialtiesList: {
          ...state.specialtiesList,
          data: state.specialtiesList.data.map((specialty) =>
            specialty._id === action.payload._id ? action.payload : specialty
          ),
        },
      };
    },
    specialtyDeleted(state, action) {
      const currentCount = state.specialtiesList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.specialtiesList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        specialtiesList: {
          data: state.specialtiesList.data.filter(
            (specialty) => specialty._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingSpecialtiesList: false,
      };
    },
  },
});
export const {
  loadingSpecialtiesList,
  specialtySearchParametersUpdate,
  specialtiesListUpdated,
  resetSpecialty,
  specialtyError,
  loadingOnSpecialtySubmit,
  specialtyCreated,
  specialtyUpdated,
  specialtyDetailsById,
  specialtyDeleted,
} = specialtySlice.actions;
export default specialtySlice.reducer;
