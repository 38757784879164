import React from "react";
import { Row, Col } from "react-bootstrap";

import NpiView from "./NpiView";
import CredentialItems from "./CredentialItems";
import EducationItems from "./EducationItems";
import SamExclusionItems from "./SamExclusionItems";
import DeaLicenseItems from "./DeaLicenseItems";
import OigExclusionItems from "./OigExclusionItems";
import MedicareItems from "./MedicareItems";

const PsvItems = ({
  provider,
  selectedItems,
  setSelectedItems,
  selectedProviders,
  setSelectedProviders,
}) => {
  const [credItems, setCredItems] = React.useState([]);
  const [eduItems, setEduItems] = React.useState([]);
  const [specialtyItems, setSpecialtyItems] = React.useState([]);
  const [mcooItems, setMcooItems] = React.useState([]);
  const [oigItems, setOigItems] = React.useState([]);
  const [deaItems, setDeaItems] = React.useState([]);
  const [samItems, setSamItems] = React.useState([]);
  const [npiData, setNpiData] = React.useState([]);

  React.useEffect(() => {
    const creds = provider["Credential"].map((cred) => ({
      type: "Credential",
      ...cred,
    }));

    setCredItems(creds);

    const edus = provider["Education"].map((edu) => ({
      type: "Education",
      ...edu,
    }));

    setEduItems(edus);

    const specialty = provider["Specialty"].map((spec) => ({
      type: "Specialty",
      ...spec,
    }));

    setSpecialtyItems(specialty);

    const mcoos = provider["Medicare"].map((mcoo) => ({
      type: "Medicare",
      ...mcoo,
    }));

    setMcooItems(mcoos);

    const oigs = provider["OigExclusion"].map((oig) => ({
      type: "OigExclusion",
      ...oig,
    }));

    setOigItems(oigs);

    const deas = provider["DeaLicense"].map((dea) => ({
      type: "DeaLicense",
      ...dea,
    }));

    setDeaItems(deas);

    const sams = provider["SamExclusion"].map((sam) => ({
      type: "SamExclusion",
      ...sam,
    }));

    setSamItems(sams);

    const npis = provider["ProviderNpi"].map((npi) => ({
      type: "ProviderNpi",
      ...npi,
    }));

    setNpiData(npis);
  }, [provider]);

  const [isProviderSelected, setIsProviderSelected] = React.useState(false);

  React.useEffect(() => {
    // Check if the provider is in the selectedProviders array
    setIsProviderSelected(
      selectedProviders.some(
        (selectedProvider) => selectedProvider._id === provider._id
      )
    );
  }, [selectedProviders, provider]);

  React.useEffect(() => {
    const itemsToBeUpdated = [...selectedItems];

    const providerIndex = itemsToBeUpdated.findIndex(
      (selectedItem) => selectedItem["ProviderID"] === provider._id
    );

    // If provider is unchecked and just in case
    // the provider doesn't exists in selectedItems Array
    if (!isProviderSelected && providerIndex === -1) return;

    // If provider is unchecked, remove the provider from selectedItems Array
    if (!isProviderSelected) {
      itemsToBeUpdated.splice(providerIndex, 1);
      setSelectedItems(itemsToBeUpdated);
      return;
    }

    // Update eduItems and credItems here
    const updatedEduItems = eduItems
      .filter((item) => !item.submitted_to_verify)
      .map((item) => item._id);
    const updatedCredItems = credItems
      .filter((item) => !item.submitted_to_verify)
      .map((item) => item._id);

    const updatedMcooItems = mcooItems
      .filter((item) => !item.submitted_to_verify)
      .map((item) => item._id);
    const updatedOigItems = oigItems
      .filter((item) => !item.submitted_to_verify)
      .map((item) => item._id);
    const updatedDeaItems = deaItems
      .filter((item) => !item.submitted_to_verify)
      .map((item) => item._id);
    const updatedSamItems = samItems
      .filter((item) => !item.submitted_to_verify)
      .map((item) => item._id);
    const updatedNpiData = npiData
      .filter((item) => !item.submitted_to_verify)
      .map((item) => item._id);

    if (
      !updatedEduItems.length &&
      !updatedCredItems.length &&
      !updatedMcooItems.length &&
      !updatedOigItems.length &&
      !updatedDeaItems.length &&
      !updatedSamItems.length &&
      !updatedNpiData.length
    ) {
      return;
    }

    if (providerIndex === -1) {
      itemsToBeUpdated.push({
        ProviderID: provider._id,
        Credentials: updatedCredItems,
        Educations: updatedEduItems,
        Medicares: updatedMcooItems,
        OigExclusions: updatedOigItems,
        DeaLicenses: updatedDeaItems,
        SamExclusions: updatedSamItems,
        ProviderNpi: updatedNpiData,
      });
    } else {
      itemsToBeUpdated[providerIndex]["Credentials"] = updatedCredItems;
      itemsToBeUpdated[providerIndex]["Educations"] = updatedEduItems;
      itemsToBeUpdated[providerIndex]["Medicares"] = updatedMcooItems;
      itemsToBeUpdated[providerIndex]["OigExclusions"] = updatedOigItems;
      itemsToBeUpdated[providerIndex]["DeaLicenses"] = updatedDeaItems;
      itemsToBeUpdated[providerIndex]["SamExclusions"] = updatedSamItems;
      itemsToBeUpdated[providerIndex]["ProviderNpi"] = updatedNpiData;
    }

    setSelectedItems(itemsToBeUpdated);
  }, [isProviderSelected]);

  const onChangeHandler = (e, type, item) => {
    const checked = e.target.checked;

    setSelectedItems((prevSelectedItems) => {
      const itemsToBeUpdated = [...prevSelectedItems];
      const providerIndex = itemsToBeUpdated.findIndex(
        (selectedItem) => selectedItem["ProviderID"] === provider._id
      );

      // If checkbox unchecked
      if (!checked && providerIndex !== -1) {
        const indexToBeRemoved =
          itemsToBeUpdated[providerIndex] &&
          itemsToBeUpdated[providerIndex][type]
            ? itemsToBeUpdated[providerIndex][type].indexOf(item._id)
            : -1;
        if (indexToBeRemoved !== -1) {
          itemsToBeUpdated[providerIndex][type].splice(indexToBeRemoved, 1);
        }

        // Remove provider object if no items selected
        if (
          !(
            itemsToBeUpdated[providerIndex]["Educations"] &&
            itemsToBeUpdated[providerIndex]["Educations"].length
          ) &&
          !(
            itemsToBeUpdated[providerIndex]["Credentials"] &&
            itemsToBeUpdated[providerIndex]["Credentials"].length
          ) &&
          !(
            itemsToBeUpdated[providerIndex]["Medicares"] &&
            itemsToBeUpdated[providerIndex]["Medicares"].length
          ) &&
          !(
            itemsToBeUpdated[providerIndex]["OigExclusions"] &&
            itemsToBeUpdated[providerIndex]["OigExclusions"].length
          ) &&
          !(
            itemsToBeUpdated[providerIndex]["DeaLicenses"] &&
            itemsToBeUpdated[providerIndex]["DeaLicenses"].length
          ) &&
          !(
            itemsToBeUpdated[providerIndex]["SamExclusions"] &&
            itemsToBeUpdated[providerIndex]["SamExclusions"].length
          ) &&
          !(
            itemsToBeUpdated[providerIndex]["ProviderNpi"] &&
            itemsToBeUpdated[providerIndex]["ProviderNpi"].length
          )
        ) {
          itemsToBeUpdated.splice(providerIndex, 1);

          const selectedProvidersCopy = [...selectedProviders];
          const selectedProviderIndex = selectedProviders.findIndex(
            (item) => item["_id"].toString() === provider["_id"].toString()
          );
          selectedProvidersCopy.splice(selectedProviderIndex, 1);
          setSelectedProviders(selectedProvidersCopy);
        }
      }

      // If checkbox checked
      if (checked && itemsToBeUpdated) {
        // Check if the item is already present
        let isItemAlreadyPresent = false;
        if (
          itemsToBeUpdated[providerIndex] &&
          itemsToBeUpdated[providerIndex][type]
        ) {
          isItemAlreadyPresent =
            providerIndex !== -1 &&
            itemsToBeUpdated[providerIndex][type].includes(item._id);
        }

        if (!isItemAlreadyPresent) {
          if (providerIndex === -1) {
            itemsToBeUpdated.push({
              ProviderID: provider._id,
              [type]: [item._id],
            });
          } else {
            itemsToBeUpdated[providerIndex][type]
              ? itemsToBeUpdated[providerIndex][type].push(item._id)
              : (itemsToBeUpdated[providerIndex][type] = [item._id]);
          }
        } else {
          if (providerIndex !== -1 && type !== "ProviderID") {
            const rowItems =
              itemsToBeUpdated[providerIndex] &&
              itemsToBeUpdated[providerIndex][type]
                ? itemsToBeUpdated[providerIndex][type]
                : null;
            if (!rowItems) return;
            const rowItemIDs = rowItems.map((rowItem) => rowItem._id);
            const rowItemsToAdd = item[type].filter(
              (rowItem) => !rowItemIDs.includes(rowItem._id)
            );
            itemsToBeUpdated[providerIndex][type].push(
              ...rowItemsToAdd.map((rowItem) => rowItem._id)
            );
          }
        }
      }

      return itemsToBeUpdated;
    });
  };

  return (
    <div className="dt-item-area">
      <Row>
        {credItems.length ? (
          <Col className="mb-2 cred-items">
            <CredentialItems
              credItems={credItems}
              onChangeHandler={onChangeHandler}
              selectedItems={selectedItems}
            />
          </Col>
        ) : null}

        {/* {eduItems.length ? (
          <Col className="mb-2 educ-items">
            <EducationItems
              eduItems={eduItems}
              onChangeHandler={onChangeHandler}
              selectedItems={selectedItems}
            />
          </Col>
        ) : null} */}

        {mcooItems.length ||
        oigItems.length ||
        deaItems.length ||
        samItems.length ||
        npiData.length ? (
          <Col className="mb-2 other-items">
            <div className="mb-1">
              <strong>Other Information</strong>
            </div>
            <MedicareItems
              mcooItems={mcooItems}
              onChangeHandler={onChangeHandler}
              selectedItems={selectedItems}
            />

            <OigExclusionItems
              oigItems={oigItems}
              onChangeHandler={onChangeHandler}
              selectedItems={selectedItems}
            />

            <DeaLicenseItems
              deaItems={deaItems}
              onChangeHandler={onChangeHandler}
              selectedItems={selectedItems}
            />

            <SamExclusionItems
              samItems={samItems}
              onChangeHandler={onChangeHandler}
              selectedItems={selectedItems}
            />

            <NpiView
              npiData={npiData}
              onChangeHandler={onChangeHandler}
              selectedItems={selectedItems}
            />
          </Col>
        ) : null}
      </Row>

      {/* {specialtyItems.length ? (
        <div className="dt-each mb-2">
          <div className="mb-1">
            <strong>Specialties</strong>
          </div>

          {specialtyItems.map((item, i) => (
            <Row key={i}>
              <Col>
                <input type="checkbox" />
                <span className="ml-1">{item.specialty.name}</span>
              </Col>
            </Row>
          ))}
        </div>
      ) : null} */}
    </div>
  );
};

export default PsvItems;
