import React from "react";
import { connect } from "react-redux";

import { Link, useParams } from "react-router-dom";
import {
  AiFillDashboard,
  AiOutlineAppstore,
  AiOutlineLayout,
} from "react-icons/ai";
import { IoMdCopy } from "react-icons/io";
import { BsClipboard } from "react-icons/bs";
import { TbUsers } from "react-icons/tb";
import { RiOrganizationChart, RiServiceFill } from "react-icons/ri";
import { MdToken, MdSource } from "react-icons/md";
import { FaSchool, FaHandHoldingMedical } from "react-icons/fa";
import { GiEcology } from "react-icons/gi";

import { isAdmin } from "utils/helper";

const DefaultTopNav = ({ user }) => {
  const params = useParams();

  const [currentPage, setCurrentPage] = React.useState("");
  React.useEffect(() => {
    const paramsArray = params["*"].split("/");
    if (!paramsArray.length) return;

    const currentPage = paramsArray[0];
    setCurrentPage(currentPage);
  }, [params]);

  return (
    <div className="topnav shadow-sm top-sidebar">
      <div className="container-fluid">
        {/* <NavLink href="index.html" className="logo text-center logo-light">
        <span className="logo-lg">
          <img src="./assets/images/logo.png" alt="" height="16" />
        </span>
      </NavLink> */}

        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
          <div
            className="collapse navbar-collapse active"
            id="topnav-menu-content"
          >
            <ul className="navbar-nav">
              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "dashboard" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/dashboard"
                    id="topnav-dashboards"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <AiFillDashboard className="sidebar-icon ml-1" />
                    Dashboard
                    <div className="arrow-down"></div>
                  </Link>
                </li>
              ) : null}

              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "users" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/users"
                    id="topnav-apps"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <TbUsers className="sidebar-icon" />
                    Users <div className="arrow-down"></div>
                  </Link>
                </li>
              ) : null}

              {/* <li
                className={`nav-item dropdown ${
                  currentPage === "clients" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/admin/clients"
                  id="topnav-apps"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <RiOrganizationChart className="sidebar-icon" />
                  Clients <div className="arrow-down"></div>
                </Link>
              </li> */}

              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "credentials" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/credentials"
                    id="topnav-apps"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <MdToken className="sidebar-icon" />
                    Credentials <div className="arrow-down"></div>
                  </Link>
                </li>
              ) : null}

              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "schools" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/schools"
                    id="topnav-apps"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FaSchool className="sidebar-icon" />
                    Schools <div className="arrow-down"></div>
                  </Link>
                  <div className="dropdown-menu" aria-labelledby="topnav-apps">
                    <div className="dropdown">
                      <Link
                        className={`dropdown-item ${
                          currentPage === "degrees" ? "active" : ""
                        }`}
                        to="/admin/degrees"
                        id="topnav-apps"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Degrees
                      </Link>
                    </div>
                  </div>
                </li>
              ) : null}

              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "taxonomies" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/taxonomies"
                    id="topnav-apps"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <GiEcology className="sidebar-icon" />
                    Taxonomies <div className="arrow-down"></div>
                  </Link>
                </li>
              ) : null}

              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "sources" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/sources"
                    id="topnav-apps"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <MdSource className="sidebar-icon" />
                    Sources <div className="arrow-down"></div>
                  </Link>
                </li>
              ) : null}

              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "specialties" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/specialties"
                    id="topnav-apps"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <FaHandHoldingMedical className="sidebar-icon" />
                    Specialties <div className="arrow-down"></div>
                  </Link>
                </li>
              ) : null}

              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "providers" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/providers"
                    id="topnav-apps"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <RiServiceFill className="sidebar-icon" />
                    Providers <div className="arrow-down"></div>
                  </Link>
                </li>
              ) : null}

              {isAdmin(user) ? (
                <li
                  className={`nav-item dropdown ${
                    currentPage === "psv-ready" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/admin/psv-ready"
                    id="topnav-apps"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <RiServiceFill className="sidebar-icon" />
                    PSV Ordering<div className="arrow-down"></div>
                  </Link>
                </li>
              ) : null}

              {/* <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="/admin/users"
                  id="topnav-apps"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <AiOutlineAppstore className="sidebar-icon" />
                  Apps <div className="arrow-down"></div>
                </Link>
                <div className="dropdown-menu" aria-labelledby="topnav-apps">
                  <Link href="apps-calendar.html" className="dropdown-item">
                    Calendar
                  </Link>
                  <Link href="apps-chat.html" className="dropdown-item">
                    Chat
                  </Link>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-ecommerce"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Ecommerce <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-ecommerce"
                    >
                      <Link
                        href="apps-ecommerce-products.html"
                        className="dropdown-item"
                      >
                        Products
                      </Link>
                      <Link
                        href="apps-ecommerce-products-details.html"
                        className="dropdown-item"
                      >
                        Products Details
                      </Link>
                      <Link
                        href="apps-ecommerce-orders.html"
                        className="dropdown-item"
                      >
                        Orders
                      </Link>
                      <Link
                        href="apps-ecommerce-orders-details.html"
                        className="dropdown-item"
                      >
                        Order Details
                      </Link>
                      <Link
                        href="apps-ecommerce-customers.html"
                        className="dropdown-item"
                      >
                        Customers
                      </Link>
                      <Link
                        href="apps-ecommerce-shopping-cart.html"
                        className="dropdown-item"
                      >
                        Shopping Cart
                      </Link>
                      <Link
                        href="apps-ecommerce-checkout.html"
                        className="dropdown-item"
                      >
                        Checkout
                      </Link>
                      <Link
                        href="apps-ecommerce-sellers.html"
                        className="dropdown-item"
                      >
                        Sellers
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-email"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Email <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-email"
                    >
                      <Link
                        href="apps-email-inbox.html"
                        className="dropdown-item"
                      >
                        Inbox
                      </Link>
                      <Link
                        href="apps-email-read.html"
                        className="dropdown-item"
                      >
                        Read Email
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-project"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Projects <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-project"
                    >
                      <Link
                        href="apps-projects-list.html"
                        className="dropdown-item"
                      >
                        List
                      </Link>
                      <Link
                        href="apps-projects-details.html"
                        className="dropdown-item"
                      >
                        Details
                      </Link>
                      <Link
                        href="apps-projects-gantt.html"
                        className="dropdown-item"
                      >
                        Gantt
                      </Link>
                      <Link
                        href="apps-projects-add.html"
                        className="dropdown-item"
                      >
                        Create Project
                      </Link>
                    </div>
                  </div>
                  <Link href="apps-social-feed.html" className="dropdown-item">
                    Social Feed
                  </Link>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-tasks"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Tasks <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-tasks"
                    >
                      <Link href="apps-tasks.html" className="dropdown-item">
                        List
                      </Link>
                      <Link
                        href="apps-tasks-details.html"
                        className="dropdown-item"
                      >
                        Details
                      </Link>
                      <Link href="apps-kanban.html" className="dropdown-item">
                        Kanban Board
                      </Link>
                    </div>
                  </div>
                  <Link href="apps-file-manager.html" className="dropdown-item">
                    File Manager
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  href="#"
                  id="topnav-pages"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <IoMdCopy className="sidebar-icon" /> Pages
                  <div className="arrow-down"></div>
                </Link>
                <div className="dropdown-menu" aria-labelledby="topnav-pages">
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-auth"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Authenitication <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-auth"
                    >
                      <Link href="pages-login.html" className="dropdown-item">
                        Login
                      </Link>
                      <Link href="pages-login-2.html" className="dropdown-item">
                        Login 2
                      </Link>
                      <Link
                        href="pages-register.html"
                        className="dropdown-item"
                      >
                        Register
                      </Link>
                      <Link
                        href="pages-register-2.html"
                        className="dropdown-item"
                      >
                        Register 2
                      </Link>
                      <Link href="pages-logout.html" className="dropdown-item">
                        Logout
                      </Link>
                      <Link
                        href="pages-logout-2.html"
                        className="dropdown-item"
                      >
                        Logout 2
                      </Link>
                      <Link
                        href="pages-recoverpw.html"
                        className="dropdown-item"
                      >
                        Recover Password
                      </Link>
                      <Link
                        href="pages-recoverpw-2.html"
                        className="dropdown-item"
                      >
                        Recover Password 2
                      </Link>
                      <Link
                        href="pages-lock-screen.html"
                        className="dropdown-item"
                      >
                        Lock Screen
                      </Link>
                      <Link
                        href="pages-lock-screen-2.html"
                        className="dropdown-item"
                      >
                        Lock Screen 2
                      </Link>
                      <Link
                        href="pages-confirm-mail.html"
                        className="dropdown-item"
                      >
                        Confirm Mail
                      </Link>
                      <Link
                        href="pages-confirm-mail-2.html"
                        className="dropdown-item"
                      >
                        Confirm Mail 2
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-error"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Error <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-error"
                    >
                      <Link href="pages-404.html" className="dropdown-item">
                        Error 404
                      </Link>
                      <Link href="pages-404-alt.html" className="dropdown-item">
                        Error 404-alt
                      </Link>
                      <Link href="pages-500.html" className="dropdown-item">
                        Error 500
                      </Link>
                    </div>
                  </div>
                  <Link href="pages-starter.html" className="dropdown-item">
                    Starter Page
                  </Link>
                  <Link href="pages-preloader.html" className="dropdown-item">
                    With Preloader
                  </Link>
                  <Link href="pages-profile.html" className="dropdown-item">
                    Profile
                  </Link>
                  <Link href="pages-profile-2.html" className="dropdown-item">
                    Profile 2
                  </Link>
                  <Link href="pages-invoice.html" className="dropdown-item">
                    Invoice
                  </Link>
                  <Link href="pages-faq.html" className="dropdown-item">
                    FAQ
                  </Link>
                  <Link href="pages-pricing.html" className="dropdown-item">
                    Pricing
                  </Link>
                  <Link href="pages-maintenance.html" className="dropdown-item">
                    Maintenance
                  </Link>
                  <Link href="pages-timeline.html" className="dropdown-item">
                    Timeline
                  </Link>
                  <Link href="landing.html" className="dropdown-item">
                    Landing
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  href="#"
                  id="topnav-components"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <BsClipboard className="sidebar-icon" />
                  Components
                  <div className="arrow-down"></div>
                </Link>
                <div
                  className="dropdown-menu"
                  aria-labelledby="topnav-components"
                >
                  <Link href="widgets.html" className="dropdown-item">
                    Widgets
                  </Link>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-ui-kit"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Base UI 1 <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-ui-kit"
                    >
                      <Link href="ui-accordions.html" className="dropdown-item">
                        Accordions
                      </Link>
                      <Link href="ui-alerts.html" className="dropdown-item">
                        Alerts
                      </Link>
                      <Link href="ui-avatars.html" className="dropdown-item">
                        Avatars
                      </Link>
                      <Link href="ui-badges.html" className="dropdown-item">
                        Badges
                      </Link>
                      <Link href="ui-breadcrumb.html" className="dropdown-item">
                        Breadcrumb
                      </Link>
                      <Link href="ui-buttons.html" className="dropdown-item">
                        Buttons
                      </Link>
                      <Link href="ui-cards.html" className="dropdown-item">
                        Cards
                      </Link>
                      <Link href="ui-carousel.html" className="dropdown-item">
                        Carousel
                      </Link>
                      <Link href="ui-dropdowns.html" className="dropdown-item">
                        Dropdowns
                      </Link>
                      <Link
                        href="ui-embed-video.html"
                        className="dropdown-item"
                      >
                        Embed Video
                      </Link>
                      <Link href="ui-grid.html" className="dropdown-item">
                        Grid
                      </Link>
                      <Link href="ui-list-group.html" className="dropdown-item">
                        List Group
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-ui-kit2"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Base UI 2 <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-ui-kit2"
                    >
                      <Link href="ui-modals.html" className="dropdown-item">
                        Modals
                      </Link>
                      <Link
                        href="ui-notifications.html"
                        className="dropdown-item"
                      >
                        Notifications
                      </Link>
                      <Link href="ui-offcanvas.html" className="dropdown-item">
                        Offcanvas
                      </Link>
                      <Link
                        href="ui-placeholders.html"
                        className="dropdown-item"
                      >
                        Placeholders
                      </Link>
                      <Link href="ui-pagination.html" className="dropdown-item">
                        Pagination
                      </Link>
                      <Link href="ui-popovers.html" className="dropdown-item">
                        Popovers
                      </Link>
                      <Link href="ui-progress.html" className="dropdown-item">
                        Progress
                      </Link>
                      <Link href="ui-ribbons.html" className="dropdown-item">
                        Ribbons
                      </Link>
                      <Link href="ui-spinners.html" className="dropdown-item">
                        Spinners
                      </Link>
                      <Link href="ui-tabs.html" className="dropdown-item">
                        Tabs
                      </Link>
                      <Link href="ui-tooltips.html" className="dropdown-item">
                        Tooltips
                      </Link>
                      <Link href="ui-typography.html" className="dropdown-item">
                        Typography
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-extended-ui"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Extended UI <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-extended-ui"
                    >
                      <Link
                        href="extended-dragula.html"
                        className="dropdown-item"
                      >
                        Dragula
                      </Link>
                      <Link
                        href="extended-range-slider.html"
                        className="dropdown-item"
                      >
                        Range Slider
                      </Link>
                      <Link
                        href="extended-ratings.html"
                        className="dropdown-item"
                      >
                        Ratings
                      </Link>
                      <Link
                        href="extended-scrollbar.html"
                        className="dropdown-item"
                      >
                        Scrollbar
                      </Link>
                      <Link
                        href="extended-scrollspy.html"
                        className="dropdown-item"
                      >
                        Scrollspy
                      </Link>
                      <Link
                        href="extended-treeview.html"
                        className="dropdown-item"
                      >
                        Treeview
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-charts"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Charts <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-charts"
                    >
                      <div className="dropdown">
                        <Link
                          className="dropdown-item dropdown-toggle arrow-none"
                          href="#"
                          id="topnav-apex-charts"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Apex Charts <div className="arrow-down"></div>
                        </Link>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="topnav-apex-charts"
                        >
                          <Link
                            href="charts-apex-area.html"
                            className="dropdown-item"
                          >
                            Area
                          </Link>
                          <Link
                            href="charts-apex-bar.html"
                            className="dropdown-item"
                          >
                            Bar
                          </Link>
                          <Link
                            href="charts-apex-bubble.html"
                            className="dropdown-item"
                          >
                            Bubble
                          </Link>
                          <Link
                            href="charts-apex-candlestick.html"
                            className="dropdown-item"
                          >
                            Candlestick
                          </Link>
                          <Link
                            href="charts-apex-column.html"
                            className="dropdown-item"
                          >
                            Column
                          </Link>
                          <Link
                            href="charts-apex-heatmap.html"
                            className="dropdown-item"
                          >
                            Heatmap
                          </Link>
                          <Link
                            href="charts-apex-line.html"
                            className="dropdown-item"
                          >
                            Line
                          </Link>
                          <Link
                            href="charts-apex-mixed.html"
                            className="dropdown-item"
                          >
                            Mixed
                          </Link>
                          <Link
                            href="charts-apex-pie.html"
                            className="dropdown-item"
                          >
                            Pie
                          </Link>
                          <Link
                            href="charts-apex-radar.html"
                            className="dropdown-item"
                          >
                            Radar
                          </Link>
                          <Link
                            href="charts-apex-radialbar.html"
                            className="dropdown-item"
                          >
                            RadialBar
                          </Link>
                          <Link
                            href="charts-apex-scatter.html"
                            className="dropdown-item"
                          >
                            Scatter
                          </Link>
                          <Link
                            href="charts-apex-sparklines.html"
                            className="dropdown-item"
                          >
                            Sparklines
                          </Link>
                        </div>
                      </div>
                      <Link
                        href="charts-chartjs.html"
                        className="dropdown-item"
                      >
                        Chartjs
                      </Link>
                      <Link href="charts-brite.html" className="dropdown-item">
                        Britecharts
                      </Link>
                      <Link
                        href="charts-sparkline.html"
                        className="dropdown-item"
                      >
                        Sparklines
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-forms"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Forms <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-forms"
                    >
                      <Link href="form-elements.html" className="dropdown-item">
                        Basic Elements
                      </Link>
                      <Link href="form-advanced.html" className="dropdown-item">
                        Form Advanced
                      </Link>
                      <Link
                        href="form-validation.html"
                        className="dropdown-item"
                      >
                        Validation
                      </Link>
                      <Link href="form-wizard.html" className="dropdown-item">
                        Wizard
                      </Link>
                      <Link
                        href="form-fileuploads.html"
                        className="dropdown-item"
                      >
                        File Uploads
                      </Link>
                      <Link href="form-editors.html" className="dropdown-item">
                        Editors
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-tables"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Tables <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-tables"
                    >
                      <Link href="tables-basic.html" className="dropdown-item">
                        Basic Tables
                      </Link>
                      <Link
                        href="tables-datatable.html"
                        className="dropdown-item"
                      >
                        Data Tables
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-icons"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Icons <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-icons"
                    >
                      <Link
                        href="icons-dripicons.html"
                        className="dropdown-item"
                      >
                        Dripicons
                      </Link>
                      <Link href="icons-mdi.html" className="dropdown-item">
                        Material Design
                      </Link>
                      <Link href="icons-unicons.html" className="dropdown-item">
                        Unicons
                      </Link>
                    </div>
                  </div>
                  <div className="dropdown">
                    <Link
                      className="dropdown-item dropdown-toggle arrow-none"
                      href="#"
                      id="topnav-maps"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Maps <div className="arrow-down"></div>
                    </Link>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-maps"
                    >
                      <Link href="maps-google.html" className="dropdown-item">
                        Google Maps
                      </Link>
                      <Link href="maps-vector.html" className="dropdown-item">
                        Vector Maps
                      </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  href="#"
                  id="topnav-layouts"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <AiOutlineLayout className="sidebar-icon" />
                  Layouts
                  <div className="arrow-down"></div>
                </Link>
                <div className="dropdown-menu" aria-labelledby="topnav-layouts">
                  <Link href="layouts-vertical.html" className="dropdown-item">
                    Vertical
                  </Link>
                  <Link href="layouts-detached.html" className="dropdown-item">
                    Detached
                  </Link>
                </div>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(DefaultTopNav);
