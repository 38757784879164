import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Form, Row, Card, Alert } from "react-bootstrap";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";

import {
  createDegree,
  cancelSave,
  setErrors,
  removeDegreeErrors,
  resetComponentStore,
} from "actions/degreeActions";

import { isAdmin } from "utils/helper";

const CreateDegree = ({
  createDegree,
  errorList,
  cancelSave,
  loadingAppToken,
  setErrors,
  removeDegreeErrors,
  resetComponentStore,

  loggedInUser,
}) => {
  const navigate = useNavigate();

  const [isCreated, setIsCreated] = React.useState(false);

  const initialFormData = {
    degree: "",
    abbreviation: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const { degree, abbreviation } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeDegreeErrors();

    let validationRules = [
      {
        param: "degree",
        msg: "The Degree is required",
      },
      {
        param: "abbreviation",
        msg: "The Degree abbreviation is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    createDegree(submitData, navigate).then((res) => {
      if (!res.status) return;
      setIsCreated(true);
    });
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();

    if (!loggedInUser) return;

    if (!isAdmin(loggedInUser)) {
      navigate("/admin/dashboard");
    }
  }, [loggedInUser]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Create New Degree"
        crumbs={[
          { name: "Degrees", path: "/admin/degrees" },
          { name: "Create New Degree" },
        ]}
      />

      {loadingAppToken ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title mb-3">Degree Information</h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="degree">
                    Degree <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.degree ? "invalid" : ""}
                    type="text"
                    id="degree"
                    name="degree"
                    value={degree}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="degree" key="degree" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="abbreviation">
                    Abbreviation <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.abbreviation ? "invalid" : ""}
                    type="text"
                    id="abbreviation"
                    name="abbreviation"
                    value={abbreviation}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="abbreviation" key="abbreviation" />
                </Form.Group>

                {isCreated && degree ? (
                  <Form.Group className="form-group mt-5 mb-3">
                    <Alert show={true} variant="success">
                      Degree "{degree}" created.
                    </Alert>
                  </Form.Group>
                ) : undefined}

                <br></br>

                <div className="float-end">
                  {!isCreated ? (
                    <>
                      <Button
                        className="m-2"
                        type="submit"
                        size="sm"
                        variant="primary"
                      >
                        Create
                      </Button>
                      <Button
                        className="ml-2"
                        type="reset"
                        size="sm"
                        variant="danger"
                        onClick={onClickHandel}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Link to="/admin/degrees">
                      <Button color="primary" size="sm">
                        View All Degrees
                      </Button>
                    </Link>
                  )}
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

CreateDegree.propTypes = {
  createDegree: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingDegree: state.degree.loadingDegree,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  createDegree,
  cancelSave,
  setErrors,
  removeDegreeErrors,
  resetComponentStore,
})(CreateDegree);
