import React from "react";
import Papa from "papaparse";
import { Form, Modal, Button, Alert, Spinner, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { bulkUpload } from "actions/providerActions";
import ProvidersSampleCSV from "./ProvidersSampleCSV";

const ProvidersCsvBulkUpload = ({
  bulkUpload,
  modal,
  setModal,
  isDisabled,
  setDisabled,
  onUploadSuccess,
}) => {
  const fileInputRef = React.useRef(null);
  const navigate = useNavigate();
  const [uploading, setUploading] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [csvData, setCsvData] = React.useState([]);
  const [error, setError] = React.useState(null);

  const fileUploadHandler = (selectedFile) => {
    setError(null);

    // Check if the file is a CSV
    if (selectedFile && selectedFile.type === "text/csv") {
      setFileName(selectedFile.name);

      Papa.parse(selectedFile, {
        header: true, // Set to true if the CSV has a header row
        skipEmptyLines: true, // Skip empty lines in the file
        complete: async (result) => {
          const parsedData = result.data;
          if (!parsedData || !parsedData.length) {
            setError("No valid data to upload.");
            return;
          } else {
            setCsvData(parsedData);
          }

          setUploading(false);
        },
        error: (err) => {
          setError("Failed to parse CSV file.");
          setUploading(false);
        },
      });
    } else {
      setError("Please upload a valid CSV file.");
      setUploading(false);
    }
  };

  const fileChangeHandler = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      fileUploadHandler(selectedFile);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!csvData.length) {
      setError("Please upload a valid CSV file first.");
      return;
    }

    setUploading(true);

    bulkUpload(csvData, navigate)
      .then((response) => {
        onUploadSuccess();
        reset();
      })
      .catch((err) => {
        console.error("Upload failed", err);
        setError("Upload failed. Please try again.");
        setUploading(false);
        fileInputRef.current.value = "";
        setFileName("");
      });
  };

  const reset = () => {
    setModal(false);
    setDisabled(!isDisabled);
    setFileName("");
    setError(null);
    setUploading(false);
    fileInputRef.current.value = "";
  };

  return (
    <React.Fragment>
      <Modal show={modal} onHide={reset} size="lg">
        <Modal.Header closeButton>
          <h4>Providers Bulk Upload</h4>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => submitHandler(e)}>
            <Row>
              <Col md="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="OptOutDate_psv">
                    Select CSV File
                  </Form.Label>
                  <Form.Control
                    type="file"
                    id="formFile"
                    onChange={fileChangeHandler}
                    accept=".csv,.xlsx,.xls"
                    ref={fileInputRef}
                    disabled={uploading}
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Label></Form.Label>
                <div>
                  <ProvidersSampleCSV />
                </div>
              </Col>
            </Row>

            {!isDisabled ? (
              <React.Fragment>
                {" "}
                {error && (
                  <Alert variant="danger" className="p-1 ml-1 csv-errors">
                    {error}
                  </Alert>
                )}
                <div>
                  <Button
                    className="m-2"
                    type="button"
                    variant="primary"
                    size="sm"
                    id="button-addon2"
                    onClick={(e) => submitHandler(e)}
                    disabled={uploading || !fileName}
                  >
                    <span>
                      {uploading ? (
                        <>
                          <Spinner animation="border" size="sm" />
                          {" Uploading..."}
                        </>
                      ) : (
                        "Upload"
                      )}
                    </span>
                  </Button>
                </div>
              </React.Fragment>
            ) : null}
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

ProvidersCsvBulkUpload.propTypes = {
  bulkUpload: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  bulkUpload,
})(ProvidersCsvBulkUpload);
