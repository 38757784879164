import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  providerList: {
    page: 1,
    data: [],
    count: 0,
  },
  statesList: [],
  currentProvider: null,
  loadingProviderList: true,
  loadingProvider: true,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
  organizationsList: [],
  loadingOrganizationList: true,
};

const providerSlice = createSlice({
  name: "provider",
  initialState: initialState,
  reducers: {
    loadingProvidersList(state) {
      return {
        ...state,
        loadingProviderList: true,
      };
    },
    providerSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingProviderList: false,
      };
    },
    providerListUpdated(state, action) {
      return {
        ...state,
        providerList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingProviderList: false,
      };
    },
    providerDetailsById(state, action) {
      return {
        ...state,
        currentProvider: action.payload,
        loadingProvider: false,
      };
    },
    resetProvider(state) {
      return {
        ...initialState,
      };
    },

    providerError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingProvider: false,
        loadingProviderList: false,
      };
    },
    loadingOnProviderSubmit(state) {
      return {
        ...state,
        loadingProvider: true,
      };
    },
    providerUpdated(state, action) {
      return {
        ...state,
        providerList: {
          ...state.providerList,
          data: state.providerList.data.map((provider) =>
            provider._id === action.payload._id ? action.payload : provider
          ),
        },
      };
    },
    providerDeleted(state, action) {
      const currentCount = state.providerList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.providerList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        providerList: {
          data: state.providerList.data.filter(
            (provider) => provider._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingProviderList: false,
      };
    },

    providerStateList(state, action) {
      return {
        ...state,
        statesList: action.payload,
        loadingProvider: false,
      };
    },

    loadingOrganizationsList(state) {
      return {
        ...state,
        loadingOrganizationList: true,
      };
    },
    organizationsListUpdated(state, action) {
      return {
        ...state,
        organizationsList: action.payload,
        loadingOrganizationList: false,
      };
    },
  },
});
export const {
  loadingProvidersList,
  providerSearchParametersUpdate,
  providerListUpdated,
  resetProvider,
  providerError,
  loadingOnProviderSubmit,
  providerUpdated,
  providerDetailsById,
  providerDeleted,
  providerStateList,
  loadingOrganizationsList,
  organizationsListUpdated,
  organizationsList,
} = providerSlice.actions;
export default providerSlice.reducer;
