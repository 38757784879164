import React from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { isAdmin } from "utils/helper";
import { MdEdit } from "react-icons/md";
import Spinner from "views/Spinner";

import {
  setErrors,
  removeProviderDeaLicenseErrors,
  getProviderDeaLicenseById,
  saveProviderDeaLicense,
  resetProviderDeaLicenseComponentStore,
} from "actions/providerDeaLicenseActions";
import EditProviderDeaLicenseModal from "./EditProviderDeaLicenseModal";
import NoData from "./NoData";

const ProviderDeaLicense = ({
  user,
  setErrors,
  errorList,
  removeProviderDeaLicenseErrors,
  getProviderDeaLicenseById,
  saveProviderDeaLicense,
  currentProviderDeaLicense,
  loadingProviderDeaLicense,
  resetProviderDeaLicenseComponentStore,
}) => {
  const { provider_id } = useParams();
  const [modal, setModal] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);

  const initialFormData = {
    DEA_LicenseStatus: "",
    DEA_LicenseStatusDate: "",
    DEA_LicenseSchedules: "",
    DEA_Verified: false,
    DEA_DateVerified: "",
  };

  const [formData, setFormData] = React.useState(initialFormData);

  const {
    DEA_LicenseStatus,
    DEA_LicenseStatusDate,
    DEA_LicenseSchedules,
    DEA_Verified,
    DEA_DateVerified,
  } = formData;

  const loadProviderDeaLicenseFormData = (currentProviderDeaLicense) => {
    const {
      DEA_LicenseStatus,
      DEA_LicenseStatusDate,
      DEA_LicenseSchedules,
      DEA_Verified,
      DEA_DateVerified,
    } = currentProviderDeaLicense;

    const data = {
      DEA_LicenseStatus,
      DEA_LicenseStatusDate: DEA_LicenseStatusDate
        ? moment(DEA_LicenseStatusDate).format("YYYY-MM-DD")
        : "",
      DEA_LicenseSchedules,
      DEA_Verified,
      DEA_DateVerified: DEA_DateVerified
        ? moment(DEA_DateVerified).format("YYYY-MM-DD")
        : "",
    };

    setFormData((formData) => ({ ...formData, ...data }));
  };

  React.useEffect(() => {
    if (!provider_id) return;
    resetProviderDeaLicenseComponentStore();
    getProviderDeaLicenseById(provider_id);
  }, [provider_id, getProviderDeaLicenseById]);

  React.useEffect(() => {
    if (!currentProviderDeaLicense) {
      setFormData(initialFormData);
      return;
    }
    loadProviderDeaLicenseFormData(currentProviderDeaLicense);
  }, [currentProviderDeaLicense]);

  const onEditClickHandler = () => {
    setModal(true);
    setDisabled(!isDisabled);
  };

  return (
    <React.Fragment>
      <EditProviderDeaLicenseModal
        provider_id={provider_id}
        modal={modal}
        setModal={setModal}
        isDisabled={isDisabled}
        setDisabled={setDisabled}
        formData={formData}
        setFormData={setFormData}
        errorList={errorList}
        setErrors={setErrors}
        currentProviderDeaLicense={currentProviderDeaLicense}
        loadProviderDeaLicenseFormData={loadProviderDeaLicenseFormData}
        getProviderDeaLicenseById={getProviderDeaLicenseById}
        saveProviderDeaLicense={saveProviderDeaLicense}
        removeProviderDeaLicenseErrors={removeProviderDeaLicenseErrors}
      />
      <Col xs="12" sm="6" lg="6">
        <Card className="mt-5">
          <Card.Body className="misc-info-card">
            {" "}
            <div className="card-heading mb-3">
              {" "}
              <h4 className="header-title">DEA License</h4>
              {isAdmin(user) ? (
                <Button
                  variant="link"
                  size="sm"
                  className="float-end"
                  onClick={() => onEditClickHandler()}
                >
                  <MdEdit title="Click to Edit" size={20} />
                </Button>
              ) : null}
            </div>
            {loadingProviderDeaLicense ? (
              <Spinner />
            ) : (
              <React.Fragment>
                {DEA_LicenseStatus ? (
                  <Row>
                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>DEA License Status?</div>
                      <div className="fw-bold">
                        {DEA_LicenseStatus ? "Yes" : "No"}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>DEA Status Date</div>
                      <div className="fw-bold">
                        {moment(DEA_LicenseStatusDate).format("MM/DD/YYYY")}
                      </div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>DEA License Schedules</div>
                      <div className="fw-bold">{DEA_LicenseSchedules}</div>
                    </Col>

                    <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                      <div>Status</div>
                      <div className="fw-bold">
                        {DEA_Verified ? "Verified" : "Not Verified"}
                      </div>
                    </Col>

                    {DEA_DateVerified ? (
                      <Col className="mb-4" lg={4} md={4} sm={6} xs={6}>
                        <div>Date Verified</div>
                        <div className="fw-bold">
                          {moment(DEA_DateVerified).format("MM/DD/YYYY")}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                ) : (
                  <NoData loggedUser={user} />
                )}
              </React.Fragment>
            )}
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  );
};

ProviderDeaLicense.propTypes = {
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errorList: state.errors,
  currentProviderDeaLicense: state.providerDeaLicense.currentProviderDeaLicense,
  loadingProviderDeaLicense: state.providerDeaLicense.loadingProviderDeaLicense,
});

export default connect(mapStateToProps, {
  setErrors,
  removeProviderDeaLicenseErrors,
  getProviderDeaLicenseById,
  saveProviderDeaLicense,
  resetProviderDeaLicenseComponentStore,
})(ProviderDeaLicense);
