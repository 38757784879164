import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Card } from "react-bootstrap";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";

import {
  cancelSave,
  setErrors,
  resetComponentStore,
  createSpecialty,
  removeSpecialtyErrors,
} from "actions/specialtyActions";

const CreateSpecialty = ({
  createSpecialty,
  errorList,
  cancelSave,
  loadingSpecialty,
  setErrors,
  removeSpecialtyErrors,
  resetComponentStore,
}) => {
  const navigate = useNavigate();

  const initialFormData = {
    name: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const { name } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeSpecialtyErrors();

    let validationRules = [
      {
        param: "name",
        msg: "The Name is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    createSpecialty(submitData, navigate).then((res) => {
      if (!res.status) return;
    });
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();
  }, []);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Create Specialty"
        crumbs={[
          { name: "Specialties", path: "/admin/specialties" },
          { name: "Create Specialty" },
        ]}
      />

      {loadingSpecialty ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title mb-3">Specialty Information</h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="name">
                    Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.name ? "invalid" : ""}
                    type="text"
                    id="name"
                    name="name"
                    maxLength="100"
                    value={name}
                    onChange={(e) => onChange(e)}
                    required
                  />
                  <Errors current_key="name" key="name" />
                </Form.Group>

                <br></br>

                <div className="float-end">
                  <>
                    <Button
                      className="m-2"
                      type="submit"
                      size="sm"
                      variant="primary"
                    >
                      Create
                    </Button>
                    <Button
                      className="ml-2"
                      type="reset"
                      size="sm"
                      variant="danger"
                      onClick={onClickHandel}
                    >
                      Cancel
                    </Button>
                  </>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

CreateSpecialty.propTypes = {
  createSpecialty: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingSpecialty: state.specialty.loadingSpecialty,
});

export default connect(mapStateToProps, {
  createSpecialty,
  cancelSave,
  setErrors,
  removeSpecialtyErrors,
  resetComponentStore,
})(CreateSpecialty);
